import { FontSizes, FontWeights, Label } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { RepoSourcedFileContent } from '../../../models/customization';
import { useHorizontalStackStyles, useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import Separator from '../../common/form/separator';
import { CPUMetadata, DiskSizeMetadata, MemoryMetadata } from '../../dev-box/dev-box-metadata/dev-box-metadata';
import { AddDevBoxFormProjectViewModel, CustomizationData, PoolViewModel } from '../models';
import { AddDevBoxSummaryCustomizationsSection } from './add-dev-box-summary-customizations-section';

interface AddDevBoxSummaryProps {
    isSubmitting: boolean;
    name: string;
    project?: AddDevBoxFormProjectViewModel;
    pool?: PoolViewModel;
    fileCustomizations?: CustomizationData;
    repoCustomizations?: CustomizationData;
}

/**
 * Styles
 */

const useLabelStyles = makeStyles({
    root: {
        fontWeight: FontWeights.bold,
    },
});

const useNameStyles = makeStyles({
    root: {
        fontWeight: FontWeights.bold,
        fontSize: FontSizes.size16,
        paddingBottom: '6px',
    },
});

const useProjectStyles = makeStyles({
    root: {
        fontWeight: FontWeights.bold,
        fontSize: FontSizes.size14,
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '18px',
    },
});

const useItemStyles = makeStyles({
    root: {
        gap: '2px',
    },
});

const useMetadataStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * End Styles
 */

export const AddDevBoxSummary: React.FC<AddDevBoxSummaryProps> = (props) => {
    const { project, pool, name, fileCustomizations, repoCustomizations } = props;

    // Style hooks
    const labelStyles = useLabelStyles();
    const nameStyles = useNameStyles();
    const projectStyles = useProjectStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const containerStyles = useContainerStyles();
    const itemStyles = useItemStyles();
    const metadataStyles = useMetadataStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    const poolValues = React.useMemo(
        () => ({
            poolName: pool?.name,
            poolRegion: pool?.region,
        }),
        [pool]
    );

    const repoFiles: RepoSourcedFileContent[] = React.useMemo(
        () =>
            repoCustomizations?.contents
                ? repoCustomizations.contents.map((content, index) => {
                      return { name: content, content: repoCustomizations.repoFiles?.[index] ?? '' };
                  })
                : [],
        [repoCustomizations]
    );

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.root}>
                <div className={mergeClasses(stackStyles.item, nameStyles.root)}>{name}</div>
                <div className={mergeClasses(stackStyles.item, projectStyles.root)}>{project?.name}</div>
                <div className={stackStyles.item}>
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="AddDevBoxSummary_PoolNameAndRegion_Title"
                            defaultMessage="{poolName} ({poolRegion})"
                            description="Text for the add dev box summary pool name and region. Do not localize {poolName} or {poolRegion}"
                            values={poolValues}
                        />
                    </div>
                    <div className={stackStyles.item}>
                        <div className={mergeClasses(horizontalStackStyles.root, metadataStyles.root)}>
                            <div className={horizontalStackStyles.item}>
                                <CPUMetadata cpuCount={pool?.cpuCount} />
                            </div>
                            <div className={horizontalStackStyles.item}>
                                <MemoryMetadata memoryInGb={pool?.memoryInGb} />
                            </div>
                            <div className={horizontalStackStyles.item}>
                                <DiskSizeMetadata diskSizeInGb={pool?.diskSizeInGb} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={stackStyles.item}>
                <Separator />
            </div>
            {(fileCustomizations?.files || repoFiles.length > 0) && (
                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label styles={labelStyles}>
                                <FormattedMessage
                                    id="AddDevBoxSummary_Customizations_Title"
                                    defaultMessage="Customizations and Tasks"
                                    description="Text for the add dev box summary customizations and tasks title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>
                            <AddDevBoxSummaryCustomizationsSection
                                customizationFiles={fileCustomizations?.files}
                                repoFiles={repoFiles}
                            />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AddDevBoxSummary;
