import { DefaultButton, Label, Link, Panel, PanelType, Shimmer } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { SupportSectionLinks } from '../../../constants/support-section-links';
import { Metric, Property } from '../../../constants/telemetry';
import { useDevBoxSupportPanelContext } from '../../../hooks/context/panels';
import { getTokensFromPoolDataPlaneUri } from '../../../ids/pool';
import { createProjectDataPlaneUri } from '../../../ids/project';
import { Status } from '../../../models/common';
import { isStatusInProgress } from '../../../redux/selector/common';
import { getStatusForDiscoverPoolsForDevBoxes, getStatusesForListPools } from '../../../redux/selector/pool-selectors';
import { getIsUserSignedIntoMicrosoftTenant } from '../../../redux/selector/tenant-selector';
import { useDetailsPanelStyles } from '../../../themes/styles/details-panel-styles';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { SerializableMap } from '../../../types/serializable-map';
import { get } from '../../../utilities/serializable-map';
import { getSessionID, trackMetric } from '../../../utilities/telemetry/channel';
import Separator from '../../common/form/separator';

export interface DevBoxSupportPanelProps {
    isOpen: boolean;
    poolDisplayName?: string;
    poolName: string;
    devBoxUniqueID: string;
    devBoxName: string;
    statusForDiscoverPoolsForDevBoxes: Status;
    statusesForListPools: SerializableMap<Status>;
    poolId?: string;
    onDismiss: () => void;
}

const devBoxSupportPanelMessages = defineMessages({
    devBoxSupportPanelCloseButtonLabel: {
        id: 'DevBoxSupport_Panel_Close_Label',
        defaultMessage: 'Close dev box support panel',
        description: 'Aria label for the dev box support panel close button label',
    },
    devBoxSupportPanelHeaderText: {
        id: 'DevBoxSupport_Panel_Header_Text',
        defaultMessage: 'Dev box support',
        description: 'Text for the dev box support panel',
    },
    copyButton: {
        id: 'DevBoxSupportPanel_CopyButton_Text',
        defaultMessage: 'Copy support details',
        description: 'Text for the "Copy" button in the "Dev Box Support" panel',
    },
    copyButtonAriaLabel: {
        id: 'DevBoxSupportPanel_CopyButton_AriaLabel',
        defaultMessage: 'Copy support details',
        description: 'Aria label for the "Copy" button in the "Dev Box Support" panel',
    },
    issueIDCopyText: {
        id: 'DevBoxSupportPanel_IssueID_CopyText',
        defaultMessage: 'Issue ID: ',
        description: 'Text for the Issue ID to be copied',
    },
    poolInfoCopyText: {
        id: 'DevBoxSupportPanel_Pool_CopyText',
        defaultMessage: 'Dev box pool: ',
        description: 'Text for the Dev box pool to be copied',
    },
    devBoxCopyText: {
        id: 'DevBoxSupportPanel_DevBox_CopyText',
        defaultMessage: 'Dev box Id: ',
        description: 'Text for the Dev box info to be copied',
    },
});

/**
 * Style Section
 */

const useContentStyles = makeStyles({
    root: {
        gap: '31px',
        fontSize: '14px',
    },
});

const useItemStyles = makeStyles({
    root: {
        gap: '11px',
    },
});

/**
 * End
 */

const handleInternalSupportLinkClick = () => {
    trackMetric(Metric.SupportPanelInternalSupportLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.InternalSupportLink,
        },
    });
};

const handleExternalSupportLinkClick = () => {
    trackMetric(Metric.SupportPanelExternalSupportLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.ExternalSupportLink,
        },
    });
};

const handleTroubleshootLinkClick = () => {
    trackMetric(Metric.SupportPanelTroubleShootLinkClicked, 1, {
        properties: {
            [Property.Link]: SupportSectionLinks.TroubleshootAndRepair,
        },
    });
};

const getFormattedMessageValues = (href: string, onClick: () => void, target = '_blank') => {
    const values = {
        Link: (chunks: string) => (
            <Link href={href} target={target} onClick={onClick}>
                {chunks}
            </Link>
        ),
    };
    return values;
};

const internalSupportLinkValues = getFormattedMessageValues(
    SupportSectionLinks.InternalSupportLink,
    handleInternalSupportLinkClick
);
const externalSupportLinkValues = getFormattedMessageValues(
    SupportSectionLinks.ExternalSupportLink,
    handleExternalSupportLinkClick
);
const troubleshootLinkValues = getFormattedMessageValues(
    SupportSectionLinks.TroubleshootAndRepair,
    handleTroubleshootLinkClick
);

const DevBoxSupportPanelComponent: React.FC<DevBoxSupportPanelProps> = (props: DevBoxSupportPanelProps) => {
    const {
        isOpen,
        onDismiss,
        poolDisplayName,
        poolName,
        devBoxName,
        devBoxUniqueID,
        statusForDiscoverPoolsForDevBoxes,
        statusesForListPools,
        poolId,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const panelStyles = useDetailsPanelStyles();
    const stackStyles = useStackStyles();
    const itemStyles = useItemStyles();
    const contentStyles = useContentStyles();

    // We do this within the FC because session ID isn't stable / set when the scripts first load.
    const sessionID = React.useMemo(() => getSessionID(), []);

    // Selector hooks
    const isInMicrosoftTenant = useSelector(getIsUserSignedIntoMicrosoftTenant);

    const devBoxIDGUIDValue = React.useMemo(
        () => ({
            GUID: devBoxUniqueID,
        }),
        [devBoxUniqueID]
    );

    const devBoxPoolNameValue = React.useMemo(
        () => ({
            name: poolName,
        }),
        [poolName]
    );

    const { devCenter, projectName } = React.useMemo(() => getTokensFromPoolDataPlaneUri(poolId ?? ''), [poolId]);

    const projectId = React.useMemo(
        () => createProjectDataPlaneUri({ devCenter, projectName }),
        [devCenter, projectName]
    );

    const isPoolLoading = React.useMemo(
        () =>
            isStatusInProgress(get(statusesForListPools, projectId)) &&
            isStatusInProgress(statusForDiscoverPoolsForDevBoxes),
        [statusForDiscoverPoolsForDevBoxes, statusesForListPools, projectId]
    );

    const formattedSessionID = formatMessage(devBoxSupportPanelMessages.issueIDCopyText);

    const formattedPoolInfo = formatMessage(devBoxSupportPanelMessages.poolInfoCopyText);

    const formattedDevBoxInfo = formatMessage(devBoxSupportPanelMessages.devBoxCopyText);

    const textToCopy = React.useMemo(
        () =>
            `${formattedSessionID} ${sessionID}\n${formattedPoolInfo} ${poolDisplayName} (${poolName})\n${formattedDevBoxInfo} ${devBoxName} (${devBoxUniqueID})`,
        [
            poolName,
            poolDisplayName,
            devBoxName,
            devBoxUniqueID,
            formattedSessionID,
            sessionID,
            formattedPoolInfo,
            formattedDevBoxInfo,
        ]
    );

    // Callback hooks
    const onCopyClickedHandler = React.useCallback(() => {
        navigator.clipboard.writeText(textToCopy);
    }, [textToCopy]);

    return (
        <Panel
            customWidth={'369px'}
            type={PanelType.custom}
            isOpen={isOpen}
            headerText={formatMessage(devBoxSupportPanelMessages.devBoxSupportPanelHeaderText)}
            closeButtonAriaLabel={formatMessage(devBoxSupportPanelMessages.devBoxSupportPanelCloseButtonLabel)}
            onDismiss={onDismiss}
            isLightDismiss
            styles={panelStyles}
        >
            <div className={mergeClasses(stackStyles.root, contentStyles.root)}>
                <div className={stackStyles.item}>
                    <FormattedMessage
                        id="DevBoxSupport_Panel_Heading_Text"
                        defaultMessage="If you’re having technical issues, please <Link>troubleshoot your dev box</Link>. If that does not resolve the problem, provide the following information to your admin:"
                        description="Text for the dev box support panel dev box pool heading. <Link> and </Link> should not be localized, but text between them should."
                        values={troubleshootLinkValues}
                    />
                </div>
                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label>
                                <FormattedMessage
                                    id="DevBoxSupport_Panel_IssueID_Title"
                                    defaultMessage="Issue ID"
                                    description="Text for the dev box support panel issueID title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>{sessionID}</div>
                    </div>
                </div>

                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label>
                                <FormattedMessage
                                    id="DevBoxSupport_Panel_Pool_Title"
                                    defaultMessage="Dev box pool"
                                    description="Text for the dev box support panel dev box pool title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>{isPoolLoading ? <Shimmer /> : poolDisplayName}</div>

                        <div className={stackStyles.item}>
                            {isPoolLoading ? (
                                <>
                                    <span>
                                        <FormattedMessage
                                            id="DevBoxSupport_Panel_Pool_Name_Title_NotReady"
                                            defaultMessage="Resource name: "
                                            description="Text for the dev box support panel dev box pool title when pool info not ready"
                                        />
                                    </span>
                                    <Shimmer />
                                </>
                            ) : (
                                <FormattedMessage
                                    id="DevBoxSupport_Panel_Pool_Name_Title"
                                    defaultMessage="Resource name: {name}"
                                    description="Text for the dev box support panel dev box pool title"
                                    values={devBoxPoolNameValue}
                                />
                            )}
                        </div>
                    </div>
                </div>

                <div className={stackStyles.item}>
                    <div className={mergeClasses(stackStyles.root, itemStyles.root)}>
                        <div className={stackStyles.item}>
                            <Label>
                                <FormattedMessage
                                    id="DevBoxSupport_Panel_DevBoxID_Title"
                                    defaultMessage="Dev box ID"
                                    description="Text for the dev box support panel dev box id title"
                                />
                            </Label>
                        </div>
                        <div className={stackStyles.item}>{devBoxName}</div>
                        <div className={stackStyles.item}>
                            <FormattedMessage
                                id="DevBoxSupport_Panel_DevBoxID_GUID"
                                defaultMessage="Resource GUID: {GUID}"
                                description="Text for the dev box support panel dev box id title"
                                values={devBoxIDGUIDValue}
                            />
                        </div>
                    </div>
                </div>

                <div className={stackStyles.item}>
                    <DefaultButton
                        ariaLabel={formatMessage(devBoxSupportPanelMessages.copyButtonAriaLabel)}
                        onClick={onCopyClickedHandler}
                        text={formatMessage(devBoxSupportPanelMessages.copyButton)}
                    />
                </div>
                <div className={stackStyles.item}>
                    <Separator />
                </div>
                {isInMicrosoftTenant ? (
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="SupportPanel_InternalSupportLink"
                            defaultMessage="If the issue persists and you cannot create or connect to a dev box, <Link>get support on TechWeb</Link>"
                            description="Link to get support for internal customers. <Link> and </Link> should not be localized, but text between them should."
                            values={internalSupportLinkValues}
                        />
                    </div>
                ) : (
                    <div className={stackStyles.item}>
                        <FormattedMessage
                            id="SupportPanel_ExternalSupportLink"
                            defaultMessage="If the issue persists and you cannot create or connect to a dev box,<Link>contact Azure help + support</Link>"
                            description="Link to get support for external customers. <Link> and </Link> should not be localized, but text between them should."
                            values={externalSupportLinkValues}
                        />
                    </div>
                )}
            </div>
        </Panel>
    );
};

const DevBoxSupportPanelContainer: React.FC = () => {
    // Context hooks
    const { closeSurface: closePanel, isOpen, properties } = useDevBoxSupportPanelContext();

    const statusForDiscoverPoolsForDevBoxes = useSelector(getStatusForDiscoverPoolsForDevBoxes);

    const statusesForListPools = useSelector(getStatusesForListPools);

    const onDismiss = React.useCallback(() => closePanel(), [closePanel]);

    return (
        <DevBoxSupportPanelComponent
            {...properties}
            isOpen={isOpen}
            onDismiss={onDismiss}
            statusForDiscoverPoolsForDevBoxes={statusForDiscoverPoolsForDevBoxes}
            statusesForListPools={statusesForListPools}
        />
    );
};

export const DevBoxSupportPanelContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useDevBoxSupportPanelContext();

    if (!isOpen) {
        return <></>;
    }

    return <DevBoxSupportPanelContainer />;
};

export default DevBoxSupportPanelContextWrapper;
