import { TextField } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../../../hooks/action-creator';
import { useDebounceErrorMessage } from '../../../../hooks/use-debounce-error-message';
import { AzureDevOpsRepo } from '../../../../models/azure-dev-ops';
import { Status } from '../../../../models/common';
import { getAzureDevOpsRepoByCloneUri } from '../../../../redux/actions/azure-dev-ops/azure-dev-ops-action-creators';
import {
    getAzureDevOpsRepos,
    getStatusesForGetAzureDevOpsRepos,
} from '../../../../redux/selector/azure-dev-ops-selectors';
import { isStatusInProgress, isStatusSuccessful } from '../../../../redux/selector/common';
import { useStackWithFullWidthItemStyles } from '../../../../themes/styles/flexbox-styles';
import { SerializableMap } from '../../../../types/serializable-map';
import { UnionMap } from '../../../../types/union-map';
import { isAdoRepoCloneUrl } from '../../../../utilities/azure-dev-ops';
import { get } from '../../../../utilities/serializable-map';
import { isUrl } from '../../../../utilities/url';
import FailureMessage from '../../../common/failure-message/failure-message';
import { FailureMessageSeverity } from '../../../common/failure-message/models';
import { ShimmeredInput } from '../../../common/form/shimmered-field';
import { CustomizationData } from '../../models';
import AddDevBoxFormAzureDevOpsFilePicker from './add-dev-box-form-azure-dev-ops-file-picker';

interface AddDevBoxFormRepoFileControlProps {
    onChange: (value: CustomizationData | undefined) => void;
    onBlur: () => void;
    onFocus: () => void;
    disabled: boolean;
    repoCustomizations: CustomizationData | undefined;
    isActive?: boolean;
}

interface AddDevBoxFormRepoFileComponentProps extends AddDevBoxFormRepoFileControlProps {
    statusesForGetAdoRepos: SerializableMap<Status>;
    adoRepos: SerializableMap<AzureDevOpsRepo>;
    getAdoRepoForCloneUrl: (cloneUrl: string) => void;
}

const messages = defineMessages({
    repoUriTextFieldText: {
        id: 'AddDevBoxFormRepoFileControl_TextField_Text',
        defaultMessage: 'Azure DevOps repository URL',
        description: 'Text for the repo uri text field in the add dev box panel label',
    },
    repoUriTextFieldAriaLabel: {
        id: 'AddDevBoxFormRepoFileControl_TextField_AriaLabel',
        defaultMessage: 'Azure DevOps repository URL for where to source customization files from',
        description: 'Aria label for the repo uri text field in the add dev box panel',
    },
    repoUriTextFieldPlaceholder: {
        id: 'AddDevBoxFormRepoFileControl_TextField_Placeholder',
        defaultMessage: 'https://',
        description: 'Placeholder for the repo uri text field in the add dev box panel',
    },
    branchFieldLabel: {
        id: 'AddDevBoxFormRepoFileControl_Branch_Label',
        defaultMessage: 'Branch',
        description: 'Branch text field label',
    },
    invalidCloneUrlError: {
        id: 'AddDevBoxFormRepoFileControl_InvalidCloneUrl',
        defaultMessage: 'Invalid URL',
        description: 'Error message for invalid URL input',
    },
});

type BranchFieldInputState = 'Hidden' | 'Loading' | 'TextField' | 'AdoControl' | 'Warning';
const BranchFieldInputState: UnionMap<BranchFieldInputState> = {
    Hidden: 'Hidden',
    Loading: 'Loading',
    TextField: 'TextField',
    AdoControl: 'AdoControl',
    Warning: 'Warning',
};

const useContainerStyles = makeStyles({
    root: {
        gap: '16px',
    },
});

export const AddDevBoxFormRepoFileControlComponent: React.FC<AddDevBoxFormRepoFileComponentProps> = (
    props: AddDevBoxFormRepoFileComponentProps
) => {
    const {
        disabled,
        getAdoRepoForCloneUrl,
        statusesForGetAdoRepos,
        adoRepos,
        onChange,
        onBlur,
        onFocus,
        isActive,
        repoCustomizations,
    } = props;

    const { cloneUrl } = repoCustomizations ?? {};

    const [isPristine, setIsPristine] = React.useState<boolean>(true);

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const containerStyles = useContainerStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    // Memoized data
    const isValidUrl = React.useMemo(() => (cloneUrl ? isUrl(cloneUrl) : false), [cloneUrl]);
    const isAdoCloneUrl = React.useMemo(() => isAdoRepoCloneUrl(cloneUrl), [cloneUrl]);

    const statusForGetAdoRepo = React.useMemo(
        () => get(statusesForGetAdoRepos, cloneUrl),
        [statusesForGetAdoRepos, cloneUrl]
    );

    const isAdoRepoLoading = React.useMemo(() => isStatusInProgress(statusForGetAdoRepo), [statusForGetAdoRepo]);
    const adoRepo = React.useMemo(() => get(adoRepos, cloneUrl), [adoRepos, cloneUrl]);

    const cloneUrlError = React.useMemo(
        () => (cloneUrl && !isValidUrl ? formatMessage(messages.invalidCloneUrlError) : undefined),
        [formatMessage, cloneUrl, isValidUrl]
    );
    const debouncedCloneUrlError = useDebounceErrorMessage(cloneUrlError);

    const branchInputState: BranchFieldInputState = React.useMemo(() => {
        if (!cloneUrl || !isValidUrl || !isPristine) {
            return BranchFieldInputState.Hidden;
        }

        if (isAdoCloneUrl && isAdoRepoLoading) {
            return BranchFieldInputState.Loading;
        }

        if (isAdoCloneUrl && adoRepo) {
            return BranchFieldInputState.AdoControl;
        }

        // Either the clone URL isn't ADO clone URL, or the ADO repo was not found
        return BranchFieldInputState.Warning;
    }, [cloneUrl, isAdoCloneUrl, isAdoRepoLoading, adoRepo, isValidUrl, isActive, isPristine]);

    // Callback hooks
    const onCloneUrlChange = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, value: string | undefined) => {
            setIsPristine(false);

            if (value && isAdoRepoCloneUrl(value)) {
                getAdoRepoForCloneUrl(value);
            }

            onChange({ cloneUrl: value, branch: undefined, contents: undefined, repoFiles: undefined });
        },
        [getAdoRepoForCloneUrl, onChange]
    );

    const onAdoRepoSelectionChange = React.useCallback(
        (customizationData: CustomizationData) => {
            onChange(customizationData);
        },
        [onChange]
    );

    const onCloneUrlBlur = React.useCallback(() => {
        setIsPristine(true);
        onBlur();
    }, [onBlur]);

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.item}>
                <TextField
                    label={formatMessage(messages.repoUriTextFieldText)}
                    placeholder={formatMessage(messages.repoUriTextFieldPlaceholder)}
                    ariaLabel={formatMessage(messages.repoUriTextFieldAriaLabel)}
                    value={cloneUrl}
                    onChange={onCloneUrlChange}
                    onFocus={onFocus}
                    onBlur={onCloneUrlBlur}
                    disabled={disabled}
                    errorMessage={!isActive ? debouncedCloneUrlError : undefined}
                />
            </div>
            {cloneUrl && (
                <>
                    {branchInputState === BranchFieldInputState.AdoControl && (
                        <div className={stackStyles.item}>
                            <AddDevBoxFormAzureDevOpsFilePicker
                                repo={adoRepo}
                                value={repoCustomizations}
                                onChange={onAdoRepoSelectionChange}
                                onBlur={onBlur}
                                disabled={disabled}
                            />
                        </div>
                    )}
                    {branchInputState === BranchFieldInputState.Warning && (
                        <div className={stackStyles.item}>
                            <FailureMessage severity={FailureMessageSeverity.Warning}>
                                <FormattedMessage
                                    id="AddDevBoxFormRepoFileControl_RepositoryNotFound_Text"
                                    defaultMessage="Repository not found."
                                    description="Message informing users that the repository was not found."
                                />
                            </FailureMessage>
                            <FormattedMessage
                                id="AddDevBoxFormRepoFileControl_OnlyAdoSupport_Text"
                                defaultMessage="We only support repositories from Azure DevOps. Please check the URL or permissions and retry."
                                description="Message informing users that we only support ado repos"
                            />
                        </div>
                    )}
                    {branchInputState === BranchFieldInputState.Loading && (
                        <ShimmeredInput label={formatMessage(messages.branchFieldLabel)} />
                    )}
                </>
            )}
        </div>
    );
};

const AddDevBoxFormRepoFileControl: React.FC<AddDevBoxFormRepoFileControlProps> = (
    props: AddDevBoxFormRepoFileControlProps
) => {
    // Application state hooks
    const statusesForGetAdoRepos = useSelector(getStatusesForGetAzureDevOpsRepos);
    const adoRepos = useSelector(getAzureDevOpsRepos);

    // Action creator hooks
    const getAzureDevOpsRepo = useActionCreator(getAzureDevOpsRepoByCloneUri);

    // Callback hooks
    const getAdoRepoForCloneUrl = React.useCallback(
        (cloneUrl: string) => {
            if (!isStatusSuccessful(get(statusesForGetAdoRepos, cloneUrl))) {
                getAzureDevOpsRepo({ cloneUrl });
            }
        },
        [getAzureDevOpsRepo, statusesForGetAdoRepos]
    );

    return (
        <AddDevBoxFormRepoFileControlComponent
            {...props}
            getAdoRepoForCloneUrl={getAdoRepoForCloneUrl}
            statusesForGetAdoRepos={statusesForGetAdoRepos}
            adoRepos={adoRepos}
        />
    );
};

export default AddDevBoxFormRepoFileControl;
