import { createReducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { Entity } from '../../models/common';
import {
    listDevBoxRegionRecommendations,
    listDevBoxRegionRecommendationsError,
    listDevBoxRegionRecommendationsFailed,
    listDevBoxRegionRecommendationsSuccess,
    loadDevBoxRegionRecommendations,
    loadDevBoxRegionRecommendationsError,
    loadDevBoxRegionRecommendationsFailed,
    loadDevBoxRegionRecommendationsSuccess,
} from '../actions/dev-box-region-recommendation/dev-box-region-recommendation-action-creators';
import { devBoxRegionRecommendationAdapter } from '../adapters/dev-box-region-recommendation-adapters';
import {
    DevBoxRegionRecommendationDataStore,
    DevBoxRegionRecommendationStatusStore,
    DevBoxRegionRecommendationStore,
} from '../store/dev-box-region-recommendation-store';
import { getPayload } from '../utilities/payload-action';
import { createStatusReducer } from './status-reducer';

export const devBoxRegionRecommendationReducer = combineReducers<DevBoxRegionRecommendationStore>({
    data: createReducer(DevBoxRegionRecommendationDataStore(), (builder) => {
        builder.addCase(listDevBoxRegionRecommendationsSuccess, (store, action) => {
            const { result } = getPayload(action);

            devBoxRegionRecommendationAdapter.setMany(
                store.regionRecommendations,
                result.map((data) => Entity(data.destinationRegion, data))
            );
        });
    }),

    status: combineReducers<DevBoxRegionRecommendationStatusStore>({
        listDevBoxRegionRecommendations: createStatusReducer({
            inProgress: listDevBoxRegionRecommendations,
            error: listDevBoxRegionRecommendationsError,
            failed: listDevBoxRegionRecommendationsFailed,
            success: listDevBoxRegionRecommendationsSuccess,
        }),

        loadDevBoxRegionRecommendations: createStatusReducer({
            inProgress: loadDevBoxRegionRecommendations,
            error: loadDevBoxRegionRecommendationsError,
            failed: loadDevBoxRegionRecommendationsFailed,
            success: loadDevBoxRegionRecommendationsSuccess,
        }),
    }),
});
