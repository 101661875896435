import { UnionMap } from '../../../types/union-map';

export interface MetadataItemViewModel {
    description: string;
    icon: string;
    key: string;
    primary?: boolean;
    value: React.ReactNode | (() => React.ReactNode);
}

export type ShimmeredMetadataItemWidth = 'XSmall' | 'Small' | 'Medium' | 'Large';

export const ShimmeredMetadataItemWidth: UnionMap<ShimmeredMetadataItemWidth> = {
    XSmall: 'XSmall',
    Small: 'Small',
    Medium: 'Medium',
    Large: 'Large',
};

export interface ShimmeredMetadataItemViewModel {
    key: string;
    width?: ShimmeredMetadataItemWidth;
}
