import { SagaIterator } from 'redux-saga';
import { all, fork } from 'redux-saga/effects';
import { getClosestRDGatewayRegionListenerSaga } from './get-closest-rd-gateway-region';
import { listDevBoxRegionRecommendationsListenerSaga } from './list-region-recommendations';
import { loadDevBoxRegionRecommendationsListenerSaga } from './load-region-recommendations';

export function* rootSaga(): SagaIterator {
    yield all([
        fork(getClosestRDGatewayRegionListenerSaga),
        fork(listDevBoxRegionRecommendationsListenerSaga),
        fork(loadDevBoxRegionRecommendationsListenerSaga),
    ]);
}
