import { AccountInfo } from '@azure/msal-browser';
import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { EventName, Property } from '../../../constants/telemetry';
import {
    attemptSilentSingleSignOn,
    getActiveAccount,
    initialize,
    isAuthenticated,
} from '../../../data/services/identity';
import { ClientError, DataResponse, FailureOperation, isSuccessResponse } from '../../../models/common';
import { trackEvent, trackTrace } from '../../../utilities/telemetry/channel';
import {
    initializeAuthenticationState,
    initializeAuthenticationStateError,
    initializeAuthenticationStateSuccess,
    setActiveAccount,
    signInError,
    signInSuccess,
} from '../../actions/identity/identity-action-creators';
import { InitializeAuthenticationStateAction } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { getPayload } from '../../utilities/payload-action';

export function* initializeAuthenticationStateSaga(action: InitializeAuthenticationStateAction): SagaIterator {
    try {
        const { useSilentSso } = getPayload(action);

        // Initialize the authentication module and update app state if the user is already signed in
        yield call(initialize);

        // Check whether the user is already authenticated; if so, mark sign-in as already successful
        const isSignedIn = yield call(isAuthenticated);

        if (isSignedIn) {
            const activeAccount: AccountInfo = yield call(getActiveAccount);
            // Recording TenantId and HomeTenantId
            trackEvent(EventName.RecordTenantIdAndHomeTenantId, {
                properties: {
                    [Property.TenantId]: activeAccount.tenantId,
                    [Property.HomeTenantId]: activeAccount.homeAccountId.split('.')[1], // HomeAccountId = 'User Id.HomeTenantId'
                },
            });
            yield put(signInSuccess());
            yield put(setActiveAccount({ account: activeAccount }));
            yield put(initializeAuthenticationStateSuccess());
            yield resolveAction(action, { succeeded: true });
            return;
        }

        // See if we can automatically sign in via SSO (if requested by action)
        if (useSilentSso) {
            trackTrace('Attempting to sign in via silent SSO.');
            const response: DataResponse = yield call(attemptSilentSingleSignOn);

            if (isSuccessResponse(response)) {
                const activeAccount: AccountInfo = yield call(getActiveAccount);
                yield put(signInSuccess());
                yield put(setActiveAccount({ account: activeAccount }));
                yield put(initializeAuthenticationStateSuccess());
                yield resolveAction(action, { succeeded: true });
                return;
            }

            const { code, message } = response;
            trackTrace(`Silent SSO failed. ${code}: ${message}`);
        }

        yield put(initializeAuthenticationStateSuccess());
        yield resolveAction(action, { succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.InitializeAuthenticationState);
        yield put(signInError({ error }));
        yield put(initializeAuthenticationStateError({ error }));
        yield rejectAction(action, error);
    }
}

export function* initializeAuthenticationStateListenerSaga(): SagaIterator {
    yield takeLeading(initializeAuthenticationState, initializeAuthenticationStateSaga);
}
