import { makeStyles } from '@fluentui/react';
import { mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { DataPlaneErrorCode } from '../../../constants/data-plane';
import { Failure } from '../../../models/common';
import { DevBoxRepairOperationResult } from '../../../models/dev-box';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { RequiredProperty } from '../../../types/required-property';
import { RepairOperationResultOutcome } from '../../dev-box/dev-box-card/models';
import { getMessageAndCodeFromDetails } from './selectors';

interface FailureCodeAndMessageProps {
    failure: Failure | undefined;
    repairOperationResult?: DevBoxRepairOperationResult;
}

/**
 * Styles
 */

const useMessageStyles = makeStyles({
    root: {
        whiteSpace: 'pre-wrap',
        overflowWrap: 'break-word',
    },
});

/**
 * END Styles
 */

export const FailureCodeAndMessage: React.FC<FailureCodeAndMessageProps> = (props: FailureCodeAndMessageProps) => {
    const { failure, repairOperationResult } = props;

    // Style hooks
    const messageStyles = useMessageStyles();
    const stackStyles = useStackStyles();

    const { message, code } = React.useMemo(() => {
        let code = failure?.code;
        let message: React.ReactNode | undefined = failure?.message;

        // on ValidationError, the useful info about the validation error is contained in details
        if (failure?.code === DataPlaneErrorCode.ValidationError && !!failure.details) {
            const { code: detailsCode, message: detailsMessage } = getMessageAndCodeFromDetails(
                failure as RequiredProperty<Failure, 'details'>
            );

            code = detailsCode ?? code;
            message = detailsMessage ?? message;
        }

        return { message, code };
    }, [failure]);

    if (repairOperationResult?.repairOutcome === RepairOperationResultOutcome.IssuesDetected) {
        return (
            <>
                {repairOperationResult.message && (
                    <div className={mergeClasses(stackStyles.item, messageStyles.root)}>
                        {repairOperationResult.message}
                    </div>
                )}
            </>
        );
    }

    return (
        <>
            {!!code && <div className={stackStyles.item}>{code}</div>}
            {!!message && <div className={mergeClasses(stackStyles.item, messageStyles.root)}>{message}</div>}
        </>
    );
};

export default FailureCodeAndMessage;
