import { IntrinsicTask, IntrinsicTaskName } from '../constants/customization';
import { PutCustomizationTask } from '../models/customization';
import { UnionValueMap } from '../types/union-map';

export const getIntrinsicTasks = (): IntrinsicTask[] => {
    const intrinsicTaskList = Object.values(IntrinsicTask);
    return intrinsicTaskList;
};

export const IntrinsicTaskBody: UnionValueMap<IntrinsicTask, PutCustomizationTask> = {
    UpdatePcName: {
        name: IntrinsicTaskName.UpdatePcName,
        parameters: {
            appendDevBoxName: 'true',
        },
    },
};
