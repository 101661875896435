import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import EnvironmentTypeDropdown from '../../common/form/dropdown/data-controls/environment-type-dropdown';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import { EnvironmentTypeViewModel } from '../models';

interface AddEnvironmentFormEnvironmentTypeControlProps {
    isSubmitting: boolean;
    environmentTypes: EnvironmentTypeViewModel[];
    selectedEnvironmentType: EnvironmentTypeViewModel | undefined;
    errorMessage: string;
    onChange: (event: EnvironmentTypeViewModel | undefined) => void;
}

const messages = defineMessages({
    addEnvironmentFormEnvironmentTypeFieldDropdownText: {
        id: 'AddEnvironmentFormEnvironmentTypeControl_Dropdown_Text',
        defaultMessage: 'Type',
        description: 'Text for the environmentType dropdown in the add environment panel label',
    },
    addEnvironmentFormEnvironmentTypeFieldDropdownAriaLabel: {
        id: 'AddEnvironmentFormEnvironmentTypeControl_Dropdown_AriaLabel',
        defaultMessage: 'Type for your environment',
        description: 'Aria label for the environmentType dropdown in the add environment panel',
    },
    addEnvironmentFormEnvironmentTypeFieldDropdownPlaceholder: {
        id: 'AddEnvironmentFormEnvironmentTypeControl_Dropdown_Placeholder',
        defaultMessage: 'Select an environment type',
        description: 'Placeholder text for the select environment type field on the environment create form.',
    },
});

/**
 * Styles
 */

const useEnvironmentTypeSelectionContainerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * End Styles
 */

export const AddEnvironmentFormEnvironmentTypeControl: React.FC<AddEnvironmentFormEnvironmentTypeControlProps> = (
    props: AddEnvironmentFormEnvironmentTypeControlProps
) => {
    const { isSubmitting, environmentTypes, selectedEnvironmentType, errorMessage, onChange } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const environmentTypeSelectionContainerStyles = useEnvironmentTypeSelectionContainerStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    return (
        <div className={mergeClasses(stackStyles.root, environmentTypeSelectionContainerStyles.root)}>
            <div className={stackStyles.item}>
                <EnvironmentTypeDropdown
                    value={selectedEnvironmentType}
                    label={formatMessage(messages.addEnvironmentFormEnvironmentTypeFieldDropdownText)}
                    ariaLabel={formatMessage(messages.addEnvironmentFormEnvironmentTypeFieldDropdownAriaLabel)}
                    options={environmentTypes}
                    onChange={onChange}
                    disabled={isSubmitting}
                    errorMessage={errorMessage}
                    placeholder={formatMessage(messages.addEnvironmentFormEnvironmentTypeFieldDropdownPlaceholder)}
                    autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                    required
                />
            </div>
        </div>
    );
};

export default AddEnvironmentFormEnvironmentTypeControl;
