import { SagaIterator } from 'redux-saga';
import { call, put } from 'redux-saga/effects';
import { PerformanceMetric } from '../../../constants/telemetry';
import { ListRegionRecommendationsResponse } from '../../../data/services/discovery-service/region-recommendation';
import {
    ClientError,
    DataResponse,
    FailureOperation,
    isFailureResponse,
    isSuccessResponse,
} from '../../../models/common';
import { trackTimedPerformanceMetric } from '../../../utilities/telemetry/channel';
import {
    getClosestRDGatewayRegion,
    listDevBoxRegionRecommendations,
    loadDevBoxRegionRecommendations,
    loadDevBoxRegionRecommendationsError,
    loadDevBoxRegionRecommendationsFailed,
    loadDevBoxRegionRecommendationsSuccess,
} from '../../actions/dev-box-region-recommendation/dev-box-region-recommendation-action-creators';
import { LoadDevBoxRegionRecommendationsAction } from '../../actions/dev-box-region-recommendation/dev-box-region-recommendation-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { AsyncOutcome } from '../../store/common-state';

function* loadDevBoxRegionRecommendationsSaga(action: LoadDevBoxRegionRecommendationsAction) {
    const startTime = new Date();

    try {
        const rdGatewayRegionResponse: DataResponse<string> = yield putAndAwait(getClosestRDGatewayRegion());

        if (isFailureResponse(rdGatewayRegionResponse)) {
            yield resolveAction(action, rdGatewayRegionResponse);
            yield put(loadDevBoxRegionRecommendationsFailed({ failure: rdGatewayRegionResponse }));
            return;
        }

        const { data: region } = rdGatewayRegionResponse;

        const response: ListRegionRecommendationsResponse = yield putAndAwait(
            listDevBoxRegionRecommendations({ region })
        );

        if (isFailureResponse(response)) {
            yield put(loadDevBoxRegionRecommendationsFailed({ failure: response }));
        } else {
            yield put(loadDevBoxRegionRecommendationsSuccess());
        }

        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadRegionRecommendations,
            startTime,
            isFailureResponse(response) ? AsyncOutcome.Failed : AsyncOutcome.Success,
            isSuccessResponse(response) ? undefined : { errorCodes: [response.code] }
        );
        yield resolveAction(action, response);
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.LoadDevBoxRegionRecommendations);
        yield put(loadDevBoxRegionRecommendationsError({ error }));
        yield call(
            trackTimedPerformanceMetric,
            PerformanceMetric.LoadRegionRecommendations,
            startTime,
            AsyncOutcome.Error,
            {
                errorCodes: [error.code],
            }
        );
        yield rejectAction(action, error);
    }
}

export function* loadDevBoxRegionRecommendationsListenerSaga(): SagaIterator {
    yield takeLeading(loadDevBoxRegionRecommendations, loadDevBoxRegionRecommendationsSaga);
}
