import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { DefaultCpuCount, DefaultDiskSizeInGb, DefaultMemoryInGb, OSType } from '../../../constants/dev-box';
import { removeSpaces } from '../../../utilities/string';
import { isDayAfterNextDay, isNextDay } from '../../../utilities/time';
import { ShimmeredMetadataItemViewModel, ShimmeredMetadataItemWidth } from '../../common/metadata/models';
import { getScheduleMetadataMessageValues } from './selectors';

interface CPUMetadataProps {
    cpuCount: number | undefined;
}

interface DiskSizeMetadataProps {
    diskSizeInGb: number | undefined;
}

interface MemoryMetadataProps {
    memoryInGb: number | undefined;
}

interface OSTypeMetadataProps {
    osType: OSType | undefined;
}

interface ScheduleMetadataProps {
    scheduledTime: Date;
    hasHibernateSupport: boolean;
    locale: string;
}

export const CPUMetadata: React.FC<CPUMetadataProps> = React.memo((props: CPUMetadataProps) => {
    const { cpuCount } = props;

    // Intl hooks
    const { formatNumber } = useIntl();

    // Memoized data
    //TODO 1716945: Change the formatting to use FormattedMessage rather than the intl functions.
    const values = React.useMemo(
        () => ({ cpuCount: formatNumber(cpuCount ?? DefaultCpuCount) }),
        [cpuCount, formatNumber]
    );

    return (
        <FormattedMessage
            id="CPUMetadata_CpuCount_Text"
            defaultMessage="{cpuCount} vCPU"
            description="Text informing user of how many cores are assigned to a dev box. {cpuCount} should not be localized."
            values={values}
        />
    );
});

export const DiskSizeMetadata: React.FC<DiskSizeMetadataProps> = (props: DiskSizeMetadataProps) => {
    const { diskSizeInGb } = props;

    // Intl hooks
    const { formatNumber } = useIntl();

    // Memoized data
    //TODO 1716945: Change the formatting to use FormattedMessage rather than the intl functions.
    const values = React.useMemo(
        () => ({
            // Note: using removeSpaces to convert "__ GB" -> "__GB"
            diskSizeInGb: removeSpaces(
                formatNumber(diskSizeInGb ?? DefaultDiskSizeInGb, {
                    style: 'unit',
                    unit: 'gigabyte',
                })
            ),
        }),
        [diskSizeInGb, formatNumber]
    );

    // TODO: could drive type change?
    return (
        <FormattedMessage
            id="DiskSizeMetadata_DiskSizeInGb_Text"
            defaultMessage="{diskSizeInGb} SSD"
            description="Text informing user of how much memory a dev box has. {diskSizeInGb} should not be localized."
            values={values}
        />
    );
};

export const MemoryMetadata: React.FC<MemoryMetadataProps> = (props: MemoryMetadataProps) => {
    const { memoryInGb } = props;

    // Intl hooks
    const { formatNumber } = useIntl();

    // Memoized data
    //TODO 1716945: Change the formatting to use FormattedMessage rather than the intl functions.
    const values = React.useMemo(
        () => ({
            // Note: using removeSpaces to convert "__ GB" -> "__GB"
            memoryInGb: removeSpaces(
                formatNumber(memoryInGb ?? DefaultMemoryInGb, {
                    style: 'unit',
                    unit: 'gigabyte',
                })
            ),
        }),
        [formatNumber, memoryInGb]
    );

    return (
        <FormattedMessage
            id="MemoryMetadata_MemoryInGb_Text"
            defaultMessage="{memoryInGb} RAM"
            description="Text informing user of how much memory a dev box has. {memoryInGb} should not be localized."
            values={values}
        />
    );
};

export const OSTypeMetadata: React.FC<OSTypeMetadataProps> = () => {
    // Note: currently we only show "Windows," but if more options are added, handle them as a switch/case here.
    return (
        <FormattedMessage
            id="OSTypeMetadata_WindowsName_Text"
            defaultMessage="Windows"
            description="Name of the virtual machine image used by this dev box. Note: 'Windows' refers to the operating system, not glass windows."
        />
    );
};

const messages = defineMessages({
    hibernateDayOfWeekMessageText: {
        id: 'ScheduleMetadata_HibernateDayOfWeekMessage_Text',
        defaultMessage: 'Hibernates at {displayTime} {dayOfTheWeek}',
        description: 'Information indicating when this dev box will hibernate. Do not localize {displayTime}.',
    },
    hibernateTomorrowMessageText: {
        id: 'ScheduleMetadata_HibernateTomorrowMessage_Text',
        defaultMessage: 'Hibernates at {displayTime} tomorrow',
        description: 'Information indicating when this dev box will hibernate. Do not localize {displayTime}.',
    },
    hibernateMessageText: {
        id: 'ScheduleMetadata_HibernateMessage_Text',
        defaultMessage: 'Hibernates at {displayTime}',
        description: 'Information indicating when this dev box will hibernate. Do not localize {displayTime}.',
    },
    shutDownDayOfWeekMessageText: {
        id: 'ScheduleMetadata_ShutDownDayOfWeekMessage_Text',
        defaultMessage: 'Shuts down at {displayTime} {dayOfTheWeek}',
        description: 'Information indicating when this dev box will shutdown. Do not localize {displayTime}.',
    },
    shutDownTomorrowMessageText: {
        id: 'ScheduleMetadata_ShutdownTomorrowMessage_Text',
        defaultMessage: 'Shuts down at {displayTime} tomorrow',
        description: 'Information indicating when this dev box will shutdown. Do not localize {displayTime}.',
    },
    shutDownMessageText: {
        id: 'ScheduleMetadata_ShutDownMessage_Text',
        defaultMessage: 'Shuts down at {displayTime}',
        description: 'Information indicating when this dev box will shutdown. Do not localize {displayTime}.',
    },
});

export const ScheduleMetadata: React.FC<ScheduleMetadataProps> = React.memo((props: ScheduleMetadataProps) => {
    const { scheduledTime, locale, hasHibernateSupport } = props;

    const currentTime = new Date();

    const isTomorrow = isNextDay(currentTime, scheduledTime);

    const isDayAfterNext = isDayAfterNextDay(currentTime, scheduledTime);

    // Memoized data
    const values = React.useMemo(
        () => getScheduleMetadataMessageValues(scheduledTime, locale),
        [scheduledTime, locale]
    );

    if (isDayAfterNext) {
        return hasHibernateSupport ? (
            <FormattedMessage {...messages.hibernateDayOfWeekMessageText} values={values} />
        ) : (
            <FormattedMessage {...messages.shutDownDayOfWeekMessageText} values={values} />
        );
    }

    if (isTomorrow) {
        return hasHibernateSupport ? (
            <FormattedMessage {...messages.hibernateTomorrowMessageText} values={values} />
        ) : (
            <FormattedMessage {...messages.shutDownTomorrowMessageText} values={values} />
        );
    }

    return hasHibernateSupport ? (
        <FormattedMessage {...messages.hibernateMessageText} values={values} />
    ) : (
        <FormattedMessage {...messages.shutDownMessageText} values={values} />
    );
});

export const shimmeredDevBoxMetadata: ShimmeredMetadataItemViewModel[] = [
    { key: 'devbox-ostype-shimmer', width: ShimmeredMetadataItemWidth.Small },
    { key: 'devbox-schedule-shimmer', width: ShimmeredMetadataItemWidth.Medium },
    { key: 'devbox-state-shimmer', width: ShimmeredMetadataItemWidth.XSmall },
];
