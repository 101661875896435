import { ActionButton, IIconProps, TooltipHost } from '@fluentui/react';
import { makeStyles } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../../themes/styles/flexbox-styles';
import { FluentIconNames } from '../../../common/fluent-icon-names';
import { TimeZoneDropdown } from '../../../common/form/dropdown/data-controls/timezone-dropdown';

interface AddOrUpdateExpirationFormTimeZoneControlProps {
    label?: string;
    value?: string;
    onChange: (value: string | undefined) => void;
    isSubmitting: boolean;
}

const worldClockIcon: IIconProps = { iconName: FluentIconNames.WorldClock };

/**
 * Styles
 */

const useDefaultTooltipHostStyles = makeStyles({
    root: {
        display: 'inline-block',
    },
});

/**
 * END Styles
 */

const messages = defineMessages({
    selectTimezone: {
        id: 'AddOrUpdateExpirationFormTimezoneControl_Label',
        defaultMessage: 'Time zone',
        description: 'Label for the timezone picker',
    },
});

export const AddOrUpdateExpirationFormTimeZoneControl: React.FC<AddOrUpdateExpirationFormTimeZoneControlProps> = (
    props: AddOrUpdateExpirationFormTimeZoneControlProps
) => {
    const { value, onChange, isSubmitting } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const tooltipHostStyles = useDefaultTooltipHostStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    const [isTimeZoneClicked, setIsTimeZoneClicked] = React.useState<boolean>(false);

    // Intl hooks

    const onButtonClick = React.useCallback(
        (_ev: unknown): void => {
            setIsTimeZoneClicked(!isTimeZoneClicked);
        },
        [isTimeZoneClicked]
    );

    return (
        <div className={stackStyles.root}>
            {!!isTimeZoneClicked && (
                <div className={stackStyles.item}>
                    <TimeZoneDropdown
                        label={formatMessage(messages.selectTimezone)}
                        disabled={isSubmitting}
                        value={value}
                        onChange={onChange}
                    />
                </div>
            )}
            <div className={stackStyles.item}>
                <TooltipHost content={formatMessage(messages.selectTimezone)} styles={tooltipHostStyles}>
                    <ActionButton
                        iconProps={worldClockIcon}
                        allowDisabledFocus
                        toggle
                        checked={isTimeZoneClicked}
                        onClick={onButtonClick}
                        ariaLabel={formatMessage(messages.selectTimezone)}
                    />
                </TooltipHost>
            </div>
        </div>
    );
};

export default AddOrUpdateExpirationFormTimeZoneControl;
