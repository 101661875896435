import { EntityState } from '@reduxjs/toolkit';
import { Entity, Status } from '../../models/common';
import { RegionRecommendation } from '../../models/region-recommendation';
import { createInitializer } from '../../utilities/initializer';
import { devBoxRegionRecommendationAdapter } from '../adapters/dev-box-region-recommendation-adapters';
import { StatusStore } from './status-store';

type StatusName = 'listDevBoxRegionRecommendations' | 'loadDevBoxRegionRecommendations';

export interface DevBoxRegionRecommendationDataStore {
    regionRecommendations: EntityState<Entity<RegionRecommendation>>;
}

export const DevBoxRegionRecommendationDataStore = createInitializer<DevBoxRegionRecommendationDataStore>({
    regionRecommendations: devBoxRegionRecommendationAdapter.getInitialState(),
});

export type DevBoxRegionRecommendationStatusStore = StatusStore<StatusName>;

export const DevBoxRegionRecommendationStatusStore = createInitializer<DevBoxRegionRecommendationStatusStore>({
    listDevBoxRegionRecommendations: Status(),
    loadDevBoxRegionRecommendations: Status(),
});

export interface DevBoxRegionRecommendationStore {
    data: DevBoxRegionRecommendationDataStore;
    status: DevBoxRegionRecommendationStatusStore;
}

export const DevBoxRegionRecommendationStore = createInitializer<DevBoxRegionRecommendationStore>({
    data: DevBoxRegionRecommendationDataStore(),
    status: DevBoxRegionRecommendationStatusStore(),
});
