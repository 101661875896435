import {
    FontWeights,
    GroupHeader,
    IGroupHeaderProps,
    Icon,
    Spinner,
    makeStyles as legacyMakeStyles,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDynamicMakeStyles } from '../../../../hooks/styling';
import { Status } from '../../../../models/common';
import {
    CustomizationTaskListValidationError,
    CustomizationTaskListValidationStatus,
} from '../../../../models/customization';
import { isStatusUnsuccessful } from '../../../../redux/selector/common';
import {
    getStatusForValidateCustomizationTasks,
    getValidateCustomizationTasksResult,
} from '../../../../redux/selector/customization-selectors';
import { useHorizontalStackStyles } from '../../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../../utilities/styles';
import { FluentIconNames } from '../../../common/fluent-icon-names';
import { ValidationResultSection } from '../models';

interface CustomizationValidationSectionDetailsContainerProps {
    groupHeaderProps?: IGroupHeaderProps;
    customizationsValidation?: ValidationResultSection;
}

interface CustomizationValidationSectionDetailsProps extends CustomizationValidationSectionDetailsContainerProps {
    errors?: CustomizationTaskListValidationError[];
    validationStatus?: CustomizationTaskListValidationStatus;
    statusForValidateCustomizations: Status;
}

interface ValidationSectionIconProps {
    validationStatus?: CustomizationTaskListValidationStatus;
    statusForValidateCustomizations: Status;
}

interface CustomizationValidationResultTitleProps {
    validationStatus?: CustomizationTaskListValidationStatus;
    statusForValidateCustomizations: Status;
    groupHeaderProps?: IGroupHeaderProps;
}

const messages = defineMessages({
    customizationValidationSectionDetailsSpinnerAriaLabel: {
        id: 'CustomizationValidationSectionDetails_Spinner_AriaLabel',
        defaultMessage: 'Validating customization tasks',
        description: 'Aria label for validating customization tasks spinner',
    },
});

/**
 * Styles
 */

const groupHeaderStylesFactory = (isCollapsed: boolean, hasErrors: boolean) =>
    legacyMakeStyles((theme) => ({
        expand: {
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
            display: hasErrors ? '' : 'none',
        },
        expandIsCollapsed: {
            color: isCollapsed ? getSemanticColor(theme, 'expandIcon') : getSemanticColor(theme, 'activeExpandIcon'),
        },
        groupHeaderContainer: {
            backgroundColor: isCollapsed
                ? getSemanticColor(theme, 'collapsedCustomizationTaskBackground')
                : getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
            margin: isCollapsed ? '6px 0' : '6px 0 0 0',
            fontWeight: isCollapsed ? FontWeights.regular : FontWeights.bold,
            selectors: hasErrors
                ? {
                      ':hover': {
                          backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                      },
                  }
                : undefined,
            padding: hasErrors ? '0' : '0 0 0 14px',
        },
    }));

const useCustomizationTaskValidationNameStyles = makeStyles({
    collapsed: {
        fontWeight: FontWeights.regular,
    },
    expanded: {
        fontWeight: FontWeights.bold,
    },
});

const useSuccessIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'successMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useErrorIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'errorMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useWarningIconStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'warningMessageIcon'),
        fontWeight: FontWeights.semibold,
    },
}));

const useCustomizationTaskStyles = makeStyles({
    root: {
        gap: '8px',
        alignItems: 'center',
    },
});

/**
 * End Styles
 */

export const ValidationSectionIcon: React.FC<ValidationSectionIconProps> = React.memo(
    (props: ValidationSectionIconProps) => {
        const { validationStatus, statusForValidateCustomizations } = props;

        // Intl hooks
        const { formatMessage } = useIntl();

        const successIconStyles = useSuccessIconStyles();
        const errorIconStyles = useErrorIconStyles();
        const warningIconStyles = useWarningIconStyles();

        if (isStatusUnsuccessful(statusForValidateCustomizations)) {
            return <Icon iconName={FluentIconNames.Warning} styles={warningIconStyles} />;
        }

        switch (validationStatus) {
            case CustomizationTaskListValidationStatus.Succeeded:
                return <Icon iconName={FluentIconNames.Accept} styles={successIconStyles} />;
            case CustomizationTaskListValidationStatus.Failed:
                return <Icon iconName={FluentIconNames.Cancel} styles={errorIconStyles} />;
            default:
                return (
                    <Spinner
                        ariaLabel={formatMessage(messages.customizationValidationSectionDetailsSpinnerAriaLabel)}
                    />
                );
        }
    }
);

export const CustomizationValidationResultTitle: React.FC<CustomizationValidationResultTitleProps> = React.memo(
    (props: CustomizationValidationResultTitleProps) => {
        const { groupHeaderProps, validationStatus, statusForValidateCustomizations } = props;
        const { group } = groupHeaderProps ?? {};

        const isCollapsed = group?.isCollapsed ?? true;

        // Style hooks
        const taskValidationNameStyles = useCustomizationTaskValidationNameStyles();
        const horizontalStackStyles = useHorizontalStackStyles();
        const customizationTaskStyles = useCustomizationTaskStyles();

        return (
            <div className={mergeClasses(horizontalStackStyles.root, customizationTaskStyles.root)}>
                <div className={horizontalStackStyles.item}>
                    <ValidationSectionIcon
                        validationStatus={validationStatus}
                        statusForValidateCustomizations={statusForValidateCustomizations}
                    />
                </div>
                <div
                    className={mergeClasses(
                        horizontalStackStyles.item,
                        isCollapsed ? taskValidationNameStyles.collapsed : taskValidationNameStyles.expanded
                    )}
                >
                    {group?.name}
                </div>
            </div>
        );
    }
);

export const CustomizationValidationSectionDetails: React.FC<CustomizationValidationSectionDetailsProps> = React.memo(
    (props: CustomizationValidationSectionDetailsProps) => {
        const { groupHeaderProps, validationStatus, errors, statusForValidateCustomizations } = props;
        const { group } = groupHeaderProps ?? {};

        const hasErrors = React.useMemo(() => {
            if (errors) {
                return errors.length > 0;
            }

            return false;
        }, [errors]);

        // Style hooks
        const useGroupHeaderStyles = useDynamicMakeStyles(
            groupHeaderStylesFactory,
            group?.isCollapsed ?? true,
            hasErrors
        );
        const groupHeaderStyles = useGroupHeaderStyles();

        const onRenderName = React.useCallback(
            (props?: IGroupHeaderProps): JSX.Element => {
                return (
                    <CustomizationValidationResultTitle
                        groupHeaderProps={props}
                        validationStatus={validationStatus}
                        statusForValidateCustomizations={statusForValidateCustomizations}
                    />
                );
            },
            [status]
        );

        return <GroupHeader onRenderTitle={onRenderName} styles={groupHeaderStyles} {...groupHeaderProps} />;
    }
);

export const CustomizationValidationSectionDetailsContainer: React.FC<
    CustomizationValidationSectionDetailsContainerProps
> = (props: CustomizationValidationSectionDetailsContainerProps) => {
    const { groupHeaderProps, customizationsValidation } = props;

    const validateCustomizationsResult = useSelector(getValidateCustomizationTasksResult);
    const statusForValidateCustomizationTasks = useSelector(getStatusForValidateCustomizationTasks);

    const { validationResult: validationStatus, errors } = validateCustomizationsResult ?? {};

    return (
        <CustomizationValidationSectionDetails
            validationStatus={validationStatus}
            statusForValidateCustomizations={statusForValidateCustomizationTasks}
            errors={errors}
            customizationsValidation={customizationsValidation}
            groupHeaderProps={groupHeaderProps}
        />
    );
};

export default CustomizationValidationSectionDetailsContainer;
