import { FontSizes } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { stringify } from 'yaml';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { parseWingetYaml } from './selectors';

interface WingetConfigurationDetailsProps {
    style?: string;
    content: string;
}

/**
 * Styles
 */

const usePrettyYamlStyle = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        wordBreak: 'break-all',
        whiteSpace: 'pre-wrap',
    },
});

/**
 * End Styles
 */

export const WingetConfigurationDetails: React.FC<WingetConfigurationDetailsProps> = (
    props: WingetConfigurationDetailsProps
) => {
    const { style, content } = props;

    // Style hooks
    const prettyYamlStyle = usePrettyYamlStyle();
    const stackStyles = useStackStyles();

    const prettyWingetConfig = React.useMemo(() => stringify(parseWingetYaml(content)), [content]);

    return (
        <div className={mergeClasses(stackStyles.root, prettyYamlStyle.root)}>
            <div className={mergeClasses(stackStyles.item, style)}>{prettyWingetConfig}</div>
        </div>
    );
};

export default WingetConfigurationDetails;
