import { FontWeights, GroupHeader, IGroupHeaderProps, makeStyles as legacyMakeStyles } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../utilities/styles';

interface AddDevBoxSummaryCustomizationSectionDetailsProps {
    groupHeaderProps?: IGroupHeaderProps;
}

interface AddDevBoxSummaryCustomizationFileTitleProps {
    groupHeaderProps?: IGroupHeaderProps;
}

/**
 * Styles
 */

const groupHeaderStylesFactory = (isCollapsed: boolean) =>
    legacyMakeStyles((theme) => ({
        expand: {
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
        },
        expandIsCollapsed: {
            color: isCollapsed ? getSemanticColor(theme, 'expandIcon') : getSemanticColor(theme, 'activeExpandIcon'),
        },
        groupHeaderContainer: {
            backgroundColor: isCollapsed
                ? getSemanticColor(theme, 'collapsedCustomizationTaskBackground')
                : getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
            margin: isCollapsed ? '6px 0' : '6px 0 0 0',
            fontWeight: isCollapsed ? FontWeights.regular : FontWeights.bold,
            selectors: {
                ':hover': {
                    backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
                },
            },
        },
    }));

const useCustomizationFileNameStyles = makeStyles({
    collapsed: {
        fontWeight: FontWeights.regular,
    },
    expanded: {
        fontWeight: FontWeights.bold,
    },
});

const useCustomizationFileStyles = makeStyles({
    root: {
        gap: '8px',
        alignItems: 'center',
    },
});

/**
 * End Styles
 */

const onRenderName = (props?: IGroupHeaderProps): JSX.Element => {
    return <AddDevBoxSummaryCustomizationFileTitle groupHeaderProps={props} />;
};

export const AddDevBoxSummaryCustomizationFileTitle: React.FC<AddDevBoxSummaryCustomizationFileTitleProps> = React.memo(
    (props: AddDevBoxSummaryCustomizationFileTitleProps) => {
        const { groupHeaderProps } = props;
        const { group } = groupHeaderProps ?? {};

        const isCollapsed = group?.isCollapsed ?? true;

        // Style hooks
        const fileNameStyles = useCustomizationFileNameStyles();
        const horizontalStackStyles = useHorizontalStackStyles();
        const customizationFileStyles = useCustomizationFileStyles();

        return (
            <div className={mergeClasses(horizontalStackStyles.root, customizationFileStyles.root)}>
                <div
                    className={mergeClasses(
                        horizontalStackStyles.item,
                        isCollapsed ? fileNameStyles.collapsed : fileNameStyles.expanded
                    )}
                >
                    {group?.name}
                </div>
            </div>
        );
    }
);

export const AddDevBoxSummaryCustomizationSectionDetails: React.FC<AddDevBoxSummaryCustomizationSectionDetailsProps> =
    React.memo((props: AddDevBoxSummaryCustomizationSectionDetailsProps) => {
        const { groupHeaderProps } = props;
        const { group } = groupHeaderProps ?? {};

        // Style hooks
        const useGroupHeaderStyles = useDynamicMakeStyles(groupHeaderStylesFactory, group?.isCollapsed ?? true);
        const groupHeaderStyles = useGroupHeaderStyles();

        return <GroupHeader onRenderTitle={onRenderName} styles={groupHeaderStyles} {...groupHeaderProps} />;
    });

export default AddDevBoxSummaryCustomizationSectionDetails;
