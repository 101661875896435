import {
    Checkbox,
    DefaultButton,
    Dialog,
    DialogFooter,
    IDialogContentProps,
    IModalProps,
    PrimaryButton,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { DismissableContentName } from '../../../constants/app';
import { useActionCreator } from '../../../hooks/action-creator';
import { useHibernatePreviewDialogContext } from '../../../hooks/context/dialogs';
import { useOnChangeCallbackForCheckbox } from '../../../hooks/forms';
import { dismissContent as dismissContentActionCreator } from '../../../redux/actions/application/application-action-creators';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';
import { PreviewTag } from '../../common/tag/preview-tag';

export interface HibernatePreviewDialogProps {
    onDismiss: () => void;
    devBoxName: string;
    onHibernateSubmitted: () => void;
}

const messages = defineMessages({
    dontShowAgainCheckboxLabel: {
        id: 'HibernateDialog_DontShowAgain',
        defaultMessage: "Don't show this again",
        description:
            'Label for a checkbox on the hibernate preview dialog - when checked, submitting this form will stop us from showing this user the hibernate preview dialog again.',
    },
    hibernateButtonLabel: {
        id: 'HibernateDialog_HibernateButton_Label',
        defaultMessage: 'Hibernate {devBoxName}',
        description: 'Label for the submit button on the hibernate dialog. Do not localize {devBoxName}.',
    },
    cancelButtonLabel: {
        id: 'HibernateDialog_CancelButton_Label',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button on the hibernate dialog.',
    },
});

const HibernateDialogTitle: React.FC = () => {
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <div className={horizontalStackStyles.root}>
            <FormattedMessage
                id="HibernateDialog_Title_Hibernate"
                defaultMessage="Hibernate"
                description="The title for the dialog warning users that hibernate is a preview feature."
            />
            <PreviewTag />
        </div>
    );
};

/**
 * Styles
 */

const dialogWidth = '490px';
const useModalStyles = makeStyles({
    main: {
        width: dialogWidth,
    },
});

const useDialogStyles = makeStyles({
    inner: {
        padding: '26px 24px 24px 24px',
    },
});

const useDialogStackStyles = makeStyles({
    root: {
        gap: '18px',
        padding: '0px 0px 30px 0px',
    },
});

/**
 * END Styles
 */

const HibernatePreviewDialogComponent: React.FC<HibernatePreviewDialogProps> = (props: HibernatePreviewDialogProps) => {
    const { onDismiss, devBoxName, onHibernateSubmitted } = props;

    // Action creator hooks
    const dismissContent = useActionCreator(dismissContentActionCreator);

    // State hooks
    const [dontShowAgainChecked, setDontShowAgainChecked] = React.useState(false);

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const dialogStyles = useDialogStyles();
    const modalStyles = useModalStyles();
    const stackStyles = useStackStyles();
    const dialogStackStyles = useDialogStackStyles();

    // Callback hooks
    const onCheckboxChange = useOnChangeCallbackForCheckbox(setDontShowAgainChecked);

    const onSubmit = React.useCallback(() => {
        if (dontShowAgainChecked) {
            dismissContent({ content: DismissableContentName.HibernatePreviewDialog });
        }

        onHibernateSubmitted();
        onDismiss();
    }, [dontShowAgainChecked, onHibernateSubmitted, onDismiss, dismissContent]);

    // Memoized data
    const dialogContentProps = React.useMemo(
        (): IDialogContentProps => ({
            title: <HibernateDialogTitle />,
            styles: dialogStyles,
        }),
        [dialogStyles]
    );

    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalStyles,
        }),
        [modalStyles]
    );

    const hibernateButtonTextValues = React.useMemo(() => ({ devBoxName }), [devBoxName]);

    return (
        <Dialog
            onDismiss={onDismiss}
            dialogContentProps={dialogContentProps}
            hidden={false}
            modalProps={modalProps}
            maxWidth={dialogWidth}
        >
            <div className={mergeClasses(stackStyles.root, dialogStackStyles.root)}>
                <div className={stackStyles.item}>
                    <FormattedMessage
                        id="HibernateDialog_Description"
                        defaultMessage="The hibernate feature is in preview, and in rare cases resuming hibernated machines might not work as expected. Should issues occur, they can be resolved by restarting the dev box, but any unsaved work will be lost."
                        description="The description for the hibernate preview dialog"
                    />
                </div>
                <div className={stackStyles.item}>
                    <Checkbox
                        label={formatMessage(messages.dontShowAgainCheckboxLabel)}
                        onChange={onCheckboxChange}
                        checked={dontShowAgainChecked}
                    />
                </div>
            </div>
            <DialogFooter>
                <PrimaryButton
                    text={formatMessage(messages.hibernateButtonLabel, hibernateButtonTextValues)}
                    onClick={onSubmit}
                />
                <DefaultButton text={formatMessage(messages.cancelButtonLabel)} onClick={onDismiss} />
            </DialogFooter>
        </Dialog>
    );
};

const HibernatePreviewDialog: React.FC = () => {
    // Context hooks
    const { closeSurface: closeDialog, properties } = useHibernatePreviewDialogContext();

    return <HibernatePreviewDialogComponent {...properties} onDismiss={closeDialog} />;
};

export const HibernatePreviewDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useHibernatePreviewDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <HibernatePreviewDialog />;
};
