import { makeStyles } from '@fluentui/react-components';

export type RootStyle = 'root';

export const useStackStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        alignItems: 'flex-start',
    },
    item: {
        height: 'auto',
        width: 'auto',
        flexShrink: 1,
    },
});

export const useStackWithFullWidthItemStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        alignItems: 'flex-start',
    },
    item: {
        height: 'auto',
        width: '100%',
        flexShrink: 1,
    },
});

export const useHorizontalStackStyles = makeStyles({
    root: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        width: 'auto',
        height: 'auto',
        alignItems: 'flex-start',
    },
    item: {
        height: 'auto',
        width: 'auto',
        flexShrink: 1,
    },
});
