import { Checkbox, Link } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { Field } from 'react-final-form';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { SupportSectionLinks } from '../../../../constants/support-section-links';
import { Status } from '../../../../models/common';
import {
    CustomizationTaskListValidationError,
    CustomizationTaskListValidationStatus,
    PutCustomizationTask,
} from '../../../../models/customization';
import { useStackWithFullWidthItemStyles } from '../../../../themes/styles/flexbox-styles';
import Separator from '../../../common/form/separator';
import { AddDevBoxFormField, CustomizationData } from '../../models';
import { getTaskListFromCustomizationFiles } from '../../selectors';
import AddDevBoxFormCustomizationFilePicker from './add-dev-box-form-customization-file-picker';
import AddDevBoxFormCustomizationValidationSection from './add-dev-box-form-customizations-validation-section';
import AddDevBoxFormRepoFileControl from './add-dev-box-form-repo-file-control';

interface CustomizationFileFormFieldGroupProps {
    isSubmitting: boolean;
    statusForValidateCustomizationTasks: Status;
    validationResult: CustomizationTaskListValidationStatus | undefined;
    errors: CustomizationTaskListValidationError[] | undefined;
    fileCustomizationsOnChange: (value: CustomizationData | undefined) => void;
    repoCustomizationsOnChange: (value: CustomizationData | undefined) => void;
    uploadFileEnabledOnChange: (value: boolean) => void;
    fileFromRepoEnabledOnChange: (value: boolean) => void;
    fileCustomizations: CustomizationData | undefined;
    repoCustomizations: CustomizationData | undefined;
    fileFromRepoEnabled: boolean;
    uploadFileEnabled: boolean;
}

const messages = defineMessages({
    customizationFileFormFieldGroupChooseFromRepoCheckboxLabel: {
        id: 'CustomizationFileFormFieldGroup_ChooseFromRepoCheckbox_Label',
        defaultMessage: 'Choose a customization file from a repository',
        description: 'Checkbox label for choose a customization file from a repository',
    },
    customizationFileFormFieldGroupChooseFromRepoCheckboxAriaLabel: {
        id: 'CustomizationFileFormFieldGroup_ChooseFromRepoCheckbox_AriaLabel',
        defaultMessage: 'Choose a customization file from a repository',
        description: 'Aria label for choose a customization file from a repository',
    },
    customizationFileFormFieldGroupUploadCheckboxLabel: {
        id: 'CustomizationFileFormFieldGroup_UploadCheckbox_Label',
        defaultMessage: 'Upload a customization file(s)',
        description: 'Checkbox label for uploading a customization file',
    },
    customizationFileFormFieldGroupUploadCheckboxAriaLabel: {
        id: 'CustomizationFileFormFieldGroup_UploadCheckbox_AriaLabel',
        defaultMessage: 'Upload a customization file(s)',
        description: 'Aria label for uploading a customization file',
    },
});

const customizationsLearnMoreMessageValues = {
    Link: (chunks: string) => (
        <Link href={SupportSectionLinks.CustomizationsLink} target="_blank">
            {chunks}
        </Link>
    ),
};

/**
 * Styles
 */

const useSpacingStyles = makeStyles({
    root: {
        paddingBottom: '15px',
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '18px',
    },
});

/**
 * End Styles
 */

const getTaskList = (uploadedCustomizationFiles: string[] | undefined): PutCustomizationTask[] | undefined => {
    const customizationFiles = uploadedCustomizationFiles ?? [];

    return getTaskListFromCustomizationFiles(customizationFiles);
};

export const CustomizationFileFormFieldGroup: React.FC<CustomizationFileFormFieldGroupProps> = (props) => {
    const {
        isSubmitting,
        statusForValidateCustomizationTasks,
        validationResult,
        errors,
        fileCustomizationsOnChange,
        repoCustomizationsOnChange,
        fileFromRepoEnabledOnChange,
        uploadFileEnabledOnChange,
        uploadFileEnabled,
        fileFromRepoEnabled,
        fileCustomizations,
        repoCustomizations,
    } = props;

    const { formatMessage } = useIntl();

    // Style hooks
    const spacingStyles = useSpacingStyles();
    const containerStyles = useContainerStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    // Callback hooks
    const onCloneTaskRepoSelectionChange = React.useCallback(
        (value: CustomizationData | undefined) => {
            const { contents, cloneUrl, branch, repoFiles } = value ?? {};

            repoCustomizationsOnChange({
                taskList: getTaskList(repoFiles),
                contents,
                cloneUrl,
                branch,
                repoFiles,
            });
        },
        [repoCustomizationsOnChange]
    );

    const onUploadedCustomizationFilesChange = React.useCallback(
        (value: CustomizationData | undefined) => {
            const { contents, files } = value ?? {};

            fileCustomizationsOnChange({
                taskList: getTaskList(contents),
                files,
            });
        },
        [fileCustomizationsOnChange]
    );

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.item}>
                <FormattedMessage
                    id="AddDevBoxPanel_CustomizationsDescription_Text"
                    defaultMessage="Customization file(s) will allow you to create your ideal ready-to-code environment. <Link>Learn more</Link>"
                    description="Text for add dev box panel customizations description. <Link></Link> tags are not to be localized."
                    values={customizationsLearnMoreMessageValues}
                />
            </div>
            <div className={stackStyles.item}>
                <Field<boolean> name={AddDevBoxFormField.FileFromRepoEnabled}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { value } = input;

                        // Callback hooks
                        const onRepoCheckboxChange = React.useCallback(
                            (_event: unknown, checked?: boolean) => {
                                fileFromRepoEnabledOnChange(checked ?? false);
                            },
                            [fileFromRepoEnabledOnChange]
                        );

                        return (
                            <Checkbox
                                checked={value}
                                onChange={onRepoCheckboxChange}
                                label={formatMessage(
                                    messages.customizationFileFormFieldGroupChooseFromRepoCheckboxLabel
                                )}
                                ariaLabel={formatMessage(
                                    messages.customizationFileFormFieldGroupChooseFromRepoCheckboxAriaLabel
                                )}
                            />
                        );
                    }}
                </Field>
            </div>
            {fileFromRepoEnabled && (
                <>
                    <div className={mergeClasses(stackStyles.item, spacingStyles.root)}>
                        <Field<CustomizationData> name={AddDevBoxFormField.RepoCustomizations}>
                            {(fieldProps) => {
                                const { input, meta } = fieldProps;
                                const { onBlur, onFocus } = input;
                                const { active } = meta;

                                return (
                                    <AddDevBoxFormRepoFileControl
                                        onChange={onCloneTaskRepoSelectionChange}
                                        onBlur={onBlur}
                                        onFocus={onFocus}
                                        disabled={isSubmitting}
                                        isActive={active}
                                        repoCustomizations={repoCustomizations}
                                    />
                                );
                            }}
                        </Field>
                    </div>
                    <div className={stackStyles.item}>
                        <Separator />
                    </div>
                </>
            )}

            <div className={stackStyles.item}>
                <Field<boolean> name={AddDevBoxFormField.UploadFileEnabled}>
                    {(fieldProps) => {
                        const { input } = fieldProps;
                        const { value } = input;

                        // Callback hooks
                        const onUploadCheckboxChange = React.useCallback(
                            (_event: unknown, checked?: boolean) => {
                                uploadFileEnabledOnChange(checked ?? false);
                            },
                            [uploadFileEnabledOnChange]
                        );

                        return (
                            <Checkbox
                                checked={value}
                                onChange={onUploadCheckboxChange}
                                label={formatMessage(messages.customizationFileFormFieldGroupUploadCheckboxLabel)}
                                ariaLabel={formatMessage(
                                    messages.customizationFileFormFieldGroupUploadCheckboxAriaLabel
                                )}
                            />
                        );
                    }}
                </Field>
            </div>
            {uploadFileEnabled && (
                <div className={stackStyles.item}>
                    <Field<CustomizationData> name={AddDevBoxFormField.FileCustomizations}>
                        {(_fieldProps) => {
                            return (
                                <AddDevBoxFormCustomizationFilePicker
                                    disabled={isSubmitting}
                                    onChange={onUploadedCustomizationFilesChange}
                                    fileCustomizations={fileCustomizations}
                                />
                            );
                        }}
                    </Field>
                </div>
            )}
            <div className={stackStyles.item}>
                <AddDevBoxFormCustomizationValidationSection
                    statusForValidateCustomizationTasks={statusForValidateCustomizationTasks}
                    validationResult={validationResult}
                    errors={errors}
                />
            </div>
        </div>
    );
};

export default CustomizationFileFormFieldGroup;
