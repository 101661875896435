import { Label as FluentLabel, ILabelProps, mergeStyleSets } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';
import InfoTooltip, { InfoTooltipProps } from '../info-tooltip';
import { Tag } from '../tag/tag';

/**
 * Styles
 */

const useInfoTooltipStyles = makeStyles({
    root: { paddingBottom: '5px', paddingTop: '7px' },
});

const useLabelStackItemStyles = makeStyles({ root: { paddingRight: '5px', align: 'center' } });

const useTooltipLabelStyles = makeStyles({
    root: {
        '::after': {
            paddingRight: '2px',
        },
    },
});

const useTooltipStyles = makeStyles({
    root: {
        align: 'center',
    },
});

/**
 * END Styles
 */

export interface LabelProps extends ILabelProps {
    tooltipValue?: React.ReactElement | string;
    tagContent?: React.ReactNode;
}

export const Label: React.FC<LabelProps> = (props: LabelProps) => {
    const { tooltipValue, children, styles, disabled, tagContent } = props;

    // Style hooks
    const infoTooltipStyles = useInfoTooltipStyles();
    const labelStackItemStyles = useLabelStackItemStyles();
    const tooltipLabelStyles = useTooltipLabelStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const tooltipStyles = useTooltipStyles();

    // Memoized data
    const tooltipProps = React.useMemo(
        (): InfoTooltipProps | undefined =>
            tooltipValue
                ? {
                      content: tooltipValue,
                  }
                : undefined,
        [tooltipValue]
    );

    const labelStyles = React.useMemo(() => mergeStyleSets(tooltipLabelStyles, styles), [tooltipLabelStyles, styles]);

    if (!tooltipProps && !tagContent) {
        return <FluentLabel {...props}>{children}</FluentLabel>;
    }

    return (
        <div className={horizontalStackStyles.root}>
            <div className={mergeClasses(horizontalStackStyles.item, labelStackItemStyles.root)}>
                <FluentLabel {...props} styles={labelStyles}>
                    {children}
                </FluentLabel>
            </div>
            {tooltipProps && (
                <div className={mergeClasses(horizontalStackStyles.item, tooltipStyles.root)}>
                    <InfoTooltip {...tooltipProps} styles={infoTooltipStyles} disabled={disabled} />
                </div>
            )}
            {tagContent && (
                <div className={horizontalStackStyles.item}>
                    <Tag>{tagContent}</Tag>
                </div>
            )}
        </div>
    );
};

export default Label;
