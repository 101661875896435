import { Checkbox as FluentCheckbox, ICheckboxProps, Label } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';

interface CheckboxProps extends Omit<ICheckboxProps, 'label' | 'ariaLabel'> {
    value: boolean;
    onChangeHandler: (value: boolean) => void;
    label: string;
    checkboxLabel: string;
    checkboxAriaLabel: string;
}

/**
 * Styles
 */

const useLabelStyles = makeStyles({
    root: {
        paddingBottom: '10px',
        marginTop: '15px',
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * End Styles
 */

export const Checkbox: React.FC<CheckboxProps> = (props: CheckboxProps) => {
    const { value, onChangeHandler, label, checkboxLabel, checkboxAriaLabel } = props;

    const environmentExpirationEnabledOnChange = React.useCallback(
        (_event?: React.FormEvent<HTMLInputElement | HTMLElement>, checked?: boolean | undefined) => {
            onChangeHandler(!!checked);
        },
        [onChangeHandler]
    );

    // Style hooks
    const labelStyles = useLabelStyles();
    const stackStyles = useStackStyles();
    const containerStyles = useContainerStyles();

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.item}>
                <Label styles={labelStyles}>{label}</Label>
            </div>
            <div className={stackStyles.item}>
                <FluentCheckbox
                    {...props}
                    label={checkboxLabel}
                    ariaLabel={checkboxAriaLabel}
                    onChange={environmentExpirationEnabledOnChange}
                    checked={value}
                />
            </div>
        </div>
    );
};

export default Checkbox;
