import { Depths, FontSizes, FontWeights, Icon, makeStyles as legacyMakeStyles } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../utilities/styles';

// TODO: a lot of similarities with QuickActionCard, but there are enough minor style differences that trying
// to refactor would have slowed down work to get this dialog implemented. Tackle unifying these cards in a later
// refactor.

interface OpenWindowsDesktopClientOptionCardProps {
    contentFooter?: React.ReactNode;
    iconAriaLabel: string;
    iconName: string;
    paddingBottom?: number;
    subtitle: React.ReactNode;
    title: React.ReactNode;
}

/**
 * Styles
 */

const rootStylesFactory = (paddingBottom?: number) =>
    legacyMakeStyles((theme) => ({
        root: {
            backgroundColor: getSemanticColor(theme, 'openWindowsDesktopClientOptionCardBackground'),
            borderRadius: '4px',
            boxShadow: Depths.depth16,

            padding: paddingBottom !== undefined ? `32px 32px ${paddingBottom}px 32px` : '32px',
            gap: '24px',
        },
    }));

const useStyles = legacyMakeStyles((theme) => ({
    icon: {
        color: getSemanticColor(theme, 'openWindowsDesktopClientOptionCardIcon'),
        fontSize: '34px',
        lineHeight: '34px',
        width: '34px',
    },
}));

const useSubtitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        lineHeight: '16px',
        marginBottom: '10px',
    },
});

const useTextContainerStyles = makeStyles({
    root: {
        // setting a min width value for this element allows it to shrink
        minWidth: 0,
        width: '100%',
    },
});

const useTextStyles = makeStyles({
    root: {
        textAlign: 'left',
        width: '100%',
        gap: '2px',
    },
});

const useTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.semibold,
        lineHeight: '20px',
    },
});

const useContentStyles = makeStyles({
    root: {
        gap: '20px',
        alignItems: 'start',
    },
});

/**
 * END Styles
 */

export const OpenWindowsDesktopClientOptionCard: React.FC<OpenWindowsDesktopClientOptionCardProps> = (
    props: OpenWindowsDesktopClientOptionCardProps
) => {
    const { contentFooter, iconAriaLabel, iconName, paddingBottom, subtitle, title } = props;

    // Style hooks
    const useRootStyles = useDynamicMakeStyles(rootStylesFactory, paddingBottom);

    const { icon: iconClassName } = useStyles();
    const rootStyles = useRootStyles();
    const subtitleStyles = useSubtitleStyles();
    const textContainerStyles = useTextContainerStyles();
    const textStyles = useTextStyles();
    const titleStyles = useTitleStyles();
    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const contentStyles = useContentStyles();

    return (
        <div className={mergeClasses(stackStyles.root, rootStyles.root)}>
            <div className={stackStyles.item}>
                <div className={mergeClasses(horizontalStackStyles.root, contentStyles.root)}>
                    <div className={horizontalStackStyles.item}>
                        <Icon aria-label={iconAriaLabel} className={iconClassName} iconName={iconName} />
                    </div>

                    <div className={mergeClasses(horizontalStackStyles.item, textContainerStyles.root)}>
                        <div className={mergeClasses(stackStyles.root, textStyles.root)}>
                            <div className={mergeClasses(stackStyles.item, titleStyles.root)}>{title}</div>
                            <div className={mergeClasses(stackStyles.item, subtitleStyles.root)}>{subtitle}</div>

                            {contentFooter && <div className={stackStyles.item}>{contentFooter}</div>}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpenWindowsDesktopClientOptionCard;
