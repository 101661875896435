import { FontSizes, Spinner, SpinnerSize } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useHorizontalStackStyles } from '../../themes/styles/flexbox-styles';
import { PropsWithChildren } from '../../types/props-with-children';
import { AriaLiveWrapper } from './accessibility/aria-live-wrapper';

type InProgressOperationMessageProps = PropsWithChildren<{
    ariaLabel: string | undefined;
}>;

/**
 * Styles
 */

const useInProgressOperationMessageContainerStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
    },
});

const useSpinnerStyles = makeStyles({
    root: {
        position: 'absolute',
        left: '12px',
    },
});

/**
 * End Styles
 */

export const InProgressOperationMessage: React.FC<InProgressOperationMessageProps> = (props) => {
    const { children, ariaLabel } = props;

    // Style hooks
    const inProgressOperationMessageContainerStyles = useInProgressOperationMessageContainerStyles();
    const spinnerStyles = useSpinnerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <AriaLiveWrapper>
            <div className={horizontalStackStyles.root}>
                <div className={horizontalStackStyles.item}>
                    <Spinner ariaLabel={ariaLabel} styles={spinnerStyles} size={SpinnerSize.small} />
                </div>

                <div
                    className={mergeClasses(horizontalStackStyles.item, inProgressOperationMessageContainerStyles.root)}
                >
                    {children}
                </div>
            </div>
        </AriaLiveWrapper>
    );
};

export default InProgressOperationMessage;
