import { combineReducers } from 'redux';
import { applicationReducer } from './application-reducer';
import { azureDevOpsReducer } from './azure-dev-ops-reducer';
import { customizationReducer } from './customization-reducer';
import { devBoxActionReducer } from './dev-box-action-reducer';
import { devBoxOperationReducer } from './dev-box-operation-reducer';
import { devBoxReducer } from './dev-box-reducer';
import { devBoxRegionRecommendationReducer } from './dev-box-region-recommendation-reducer';
import { devCenterReducer } from './dev-center-reducer';
import { environmentDefinitionReducer } from './environment-definition-reducer';
import { environmentOperationReducer } from './environment-operation-reducer';
import { environmentReducer } from './environment-reducer';
import { featuresReducer } from './features-reducer';
import { graphReducer } from './graph-reducer';
import { identityReducer } from './identity-reducer';
import { localizationReducer } from './localization-reducer';
import { mocksReducer } from './mocks-reducer';
import { permissionReducer } from './permission-reducer';
import { poolReducer } from './pool-reducer';
import { projectEnvironmentTypeReducer } from './project-environment-type-reducer';
import { projectReducer } from './project-reducer';
import { remoteConnectionReducer } from './remote-connection-reducer';
import { scheduleReducer } from './schedule-reducer';
import { storageReducer } from './storage-reducer';
import { subApplicationsReducer } from './sub-applications-reducer';
import { subscriptionReducer } from './subscription-reducer';
import { telemetryReducer } from './telemetry-reducer';
import { tenantReducer } from './tenant-reducer';
import { timeReducer } from './time-reducer';
import { userSettingsReducer } from './user-settings-reducer';

// disabling @typescript-eslint/explicit-module-boundary-types as this is a changing type and it becomes verbose.
// eslint-disable-next-line
export const combinedReducer = () =>
    combineReducers({
        applicationStore: applicationReducer,
        graphStore: graphReducer,
        identityStore: identityReducer,
        localizationStore: localizationReducer,
        mocksStore: mocksReducer,
        userSettingsStore: userSettingsReducer,
        poolStore: poolReducer,
        devBoxStore: devBoxReducer,
        environmentStore: environmentReducer,
        permissionStore: permissionReducer,
        tenantStore: tenantReducer,
        subscriptionStore: subscriptionReducer,
        projectEnvironmentTypeStore: projectEnvironmentTypeReducer,
        scheduleStore: scheduleReducer,
        devBoxActionStore: devBoxActionReducer,
        storageStore: storageReducer,
        featuresStore: featuresReducer,
        environmentDefinitionStore: environmentDefinitionReducer,
        subApplicationsStore: subApplicationsReducer,
        remoteConnectionStore: remoteConnectionReducer,
        devBoxOperationsStore: devBoxOperationReducer,
        devCenterStore: devCenterReducer,
        timeStore: timeReducer,
        telemetryStore: telemetryReducer,
        customizationStore: customizationReducer,
        projectStore: projectReducer,
        environmentOperationsStore: environmentOperationReducer,
        azureDevOpsStore: azureDevOpsReducer,
        devBoxRegionRecommendationStore: devBoxRegionRecommendationReducer,
    });

export default combinedReducer;
