import { FontSizes, FontWeights, makeStyles as legacyMakeStyles } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';
import { operatingSystemFamily } from '../../../utilities/browser';
import { getSemanticColor } from '../../../utilities/styles';
import { DownloadRemoteDesktopButton } from './download-remote-desktop-button';
import OperatingSystemFamilyImage from './operating-system-family-image';
import { getMessageDescriptorForTitle } from './selectors';

/**
 * Styles
 */

const useContainerStyles = legacyMakeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, 'downloadRemoteDesktopCardBackground'),
        borderRadius: '4px',
        boxShadow: '0px 1.2px 3.6px rgba(0, 0, 0, 0.1), 0px 6.4px 14.4px rgba(0, 0, 0, 0.13)',
        padding: '20px 14px 16px 20px',
        gap: '12px',
    },
}));

const useImageContainerStyles = legacyMakeStyles((theme) => ({
    root: {
        border: `1px solid ${getSemanticColor(theme, 'downloadRemoteDesktopCardImageBorder')}`,
        borderRadius: '6px',
        padding: '14px',
    },
}));

const useStyles = makeStyles({
    image: {
        verticalAlign: 'middle',
    },
});

const useSubtitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        lineHeight: '16px',
    },
});

const useTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size14,
        fontWeight: FontWeights.semibold,
        lineHeight: '20px',
    },
});

const useInformationStyles = makeStyles({
    root: {
        gap: '2px',
    },
});

const useContentStyles = makeStyles({
    root: {
        gap: '16px',
    },
});

const useButtonStyles = makeStyles({
    root: {
        justifyContent: 'end',
    },
});

/**
 * END Styles
 */

export const DownloadRemoteDesktopCard: React.FC = () => {
    // Style hooks
    const classNames = useStyles();
    const containerStyles = useContainerStyles();
    const imageContainerStyles = useImageContainerStyles();
    const subtitleStyles = useSubtitleStyles();
    const titleStyles = useTitleStyles();
    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const informationStyles = useInformationStyles();
    const contentStyles = useContentStyles();
    const buttonStyles = useButtonStyles();

    // Memoized data
    const titleMessageDescriptor = React.useMemo(() => getMessageDescriptorForTitle(operatingSystemFamily), []);

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.item}>
                <div className={mergeClasses(horizontalStackStyles.root, contentStyles.root)}>
                    <div className={mergeClasses(horizontalStackStyles.item, imageContainerStyles.root)}>
                        <OperatingSystemFamilyImage
                            className={classNames.image}
                            operatingSystemFamily={operatingSystemFamily}
                        />
                    </div>

                    <div className={horizontalStackStyles.item}>
                        <div className={mergeClasses(stackStyles.root, informationStyles.root)}>
                            <div className={mergeClasses(stackStyles.item, titleStyles.root)}>
                                <FormattedMessage {...titleMessageDescriptor} />
                            </div>

                            <div className={mergeClasses(stackStyles.item, subtitleStyles.root)}>
                                <FormattedMessage
                                    id="DownloadRemoteDesktopCard_Subtitle_Text"
                                    defaultMessage="Microsoft Corporation"
                                    description="Text informing the user of the company (Microsoft) that produced the Remote Desktop software we're linking to."
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className={stackStyles.item}>
                <div className={mergeClasses(horizontalStackStyles.root, buttonStyles.root)}>
                    <div className={horizontalStackStyles.item}>
                        <DownloadRemoteDesktopButton operatingSystemFamily={operatingSystemFamily} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DownloadRemoteDesktopCard;
