import { Method } from '../../../constants/http';
import { DataResponse, FailureOperation } from '../../../models/common';
import Settings from '../../../settings/settings';
import { RegionRecommendationContract } from '../../contracts/region-recommendation';
import { paginatedResourceRequest } from '../paginated-resource-request';

export type ListRegionRecommendationsResponse = DataResponse<RegionRecommendationContract[]>;

export const listRegionRecommendations = async (
    accessToken: string,
    tenantId: string,
    region: string,
    activityId?: string
): Promise<ListRegionRecommendationsResponse> => {
    const result = await paginatedResourceRequest<RegionRecommendationContract>(
        `/regionRecommendations?sourceRegion=${encodeURIComponent(region)}`,
        Method.GET,
        accessToken,
        {
            operation: FailureOperation.ListProjects,
            activityId,
        },
        `https://${tenantId}.${Settings.DiscoveryBaseHostName}`
    );
    return result;
};
