import { UnionValueMap } from '../../../types/union-map';
import { ActionName, ActionType } from '../core-actions';
import {
    getClosestRDGatewayRegion,
    listDevBoxRegionRecommendations,
    loadDevBoxRegionRecommendations,
} from './dev-box-region-recommendation-action-creators';

/**
 * Action types
 */

type DevBoxRegionRecommendationActionName =
    | ActionName<'GetClosestRDGatewayRegion', 'Error' | 'Failed' | 'Success'>
    | ActionName<'ListDevBoxRegionRecommendations', 'Error' | 'Failed' | 'Success'>
    | ActionName<'LoadDevBoxRegionRecommendations', 'Error' | 'Failed' | 'Success'>;

export type DevBoxRegionRecommendationActionType =
    | ActionType<'GET_CLOSEST_RD_GATEWAY_REGION', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LIST_DEV_BOX_REGION_RECOMMENDATIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>
    | ActionType<'LOAD_DEV_BOX_REGION_RECOMMENDATIONS', '_ERROR' | '_FAILED' | '_SUCCESS'>;

export const DevBoxRegionRecommendationActionType: UnionValueMap<
    DevBoxRegionRecommendationActionName,
    DevBoxRegionRecommendationActionType
> = {
    GetClosestRDGatewayRegion: 'GET_CLOSEST_RD_GATEWAY_REGION',
    GetClosestRDGatewayRegionError: 'GET_CLOSEST_RD_GATEWAY_REGION_ERROR',
    GetClosestRDGatewayRegionFailed: 'GET_CLOSEST_RD_GATEWAY_REGION_FAILED',
    GetClosestRDGatewayRegionSuccess: 'GET_CLOSEST_RD_GATEWAY_REGION_SUCCESS',

    ListDevBoxRegionRecommendations: 'LIST_DEV_BOX_REGION_RECOMMENDATIONS',
    ListDevBoxRegionRecommendationsError: 'LIST_DEV_BOX_REGION_RECOMMENDATIONS_ERROR',
    ListDevBoxRegionRecommendationsFailed: 'LIST_DEV_BOX_REGION_RECOMMENDATIONS_FAILED',
    ListDevBoxRegionRecommendationsSuccess: 'LIST_DEV_BOX_REGION_RECOMMENDATIONS_SUCCESS',

    LoadDevBoxRegionRecommendations: 'LOAD_DEV_BOX_REGION_RECOMMENDATIONS',
    LoadDevBoxRegionRecommendationsError: 'LOAD_DEV_BOX_REGION_RECOMMENDATIONS_ERROR',
    LoadDevBoxRegionRecommendationsFailed: 'LOAD_DEV_BOX_REGION_RECOMMENDATIONS_FAILED',
    LoadDevBoxRegionRecommendationsSuccess: 'LOAD_DEV_BOX_REGION_RECOMMENDATIONS_SUCCESS',
};

/**
 * Payloads
 */

export type ListDevBoxRegionRecommendationsPayload = {
    region: string;
};

/**
 * Action shorthands
 */

export type GetClosestRDGatewayRegionAction = ReturnType<typeof getClosestRDGatewayRegion>;

export type LoadDevBoxRegionRecommendationsAction = ReturnType<typeof loadDevBoxRegionRecommendations>;

export type ListDevBoxRegionRecommendationsAction = ReturnType<typeof listDevBoxRegionRecommendations>;
