import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    FontSizes,
    FontWeights,
    IDialogContentProps,
    IModalProps,
    makeStyles as legacyMakeStyles,
    Text,
    TextField,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { SubscriptionLink } from '../../../constants/avd';
import { OperatingSystemFamily } from '../../../constants/browser';
import { useConfigureRemoteDesktopDialogContext } from '../../../hooks/context/dialogs';
import { useDynamicMakeStyles } from '../../../hooks/styling';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';
import { operatingSystemFamily } from '../../../utilities/browser';
import { getSemanticColor } from '../../../utilities/styles';
import DownloadRemoteDesktopCard from './download-remote-desktop-card';

interface ConfigureRemoteDesktopDialogProps {
    hidden?: boolean;
    onDismiss: () => void;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'ConfigureRemoteDesktopDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for "close" button in the "Configure Remote Desktop" dialog',
    },
    copyButton: {
        id: 'ConfigureRemoteDesktopDialog_CopyButton_Text',
        defaultMessage: 'Copy',
        description: 'Text for the "Copy" button in the "Configure Remote Desktop" dialog',
    },
    copyButtonAriaLabel: {
        id: 'ConfigureRemoteDesktopDialog_CopyButton_AriaLabel',
        defaultMessage: 'Copy',
        description: 'Aria label for the "Copy" button in the "Configure Remote Desktop" dialog',
    },
    doneButton: {
        id: 'ConfigureRemoteDesktopDialog_DoneButton_Text',
        defaultMessage: 'Done',
        description: 'Text for the "Done" button in the "Configure Remote Desktop" dialog',
    },
    doneButtonAriaLabel: {
        id: 'ConfigureRemoteDesktopDialog_DoneButton_AriaLabel',
        defaultMessage: 'Done',
        description: 'Aria label for the "Done" button in the "Configure Remote Desktop" dialog',
    },
    header: {
        id: 'ConfigureRemoteDesktopDialog_Header_Text',
        defaultMessage: 'Configure Remote Desktop',
        description: 'Title of the "Configure Remote Desktop" dialog',
    },
    subscriptionUrlAriaLabel: {
        id: 'SubscriptionUrl_TextField_AriaLabel',
        defaultMessage: 'Subscription URL',
        description: 'Aria label for the Subscription URL textfield in the "Configure Remote Desktop" dialog',
    },
});

/**
 * Styles
 */

const useCopyConfirmationStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'confirmationText'),
        fontSize: FontSizes.size12,
        lineHeight: '16px',
    },
}));

const useDialogStyles = makeStyles({
    inner: {
        padding: '16px 24px 24px 24px',
    },
});

const useStepNumberStyles = makeStyles({
    root: {
        border: '1px solid',
        borderRadius: '12px',
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
        height: '24px',
        minWidth: '24px',
        textAlign: 'center',
    },
});

const subscriptionTextFieldStylesFactory = (isCopiedToClipboard: boolean) =>
    isCopiedToClipboard ? copiedToClipboardStyles : notCopiedToClipboardStyles;

const copiedToClipboardStyles = makeStyles({
    root: {
        marginBottom: '8px',
    },
});

const notCopiedToClipboardStyles = makeStyles({
    root: {
        marginBottom: '27px',
    },
});

const useStepsContainerStyles = makeStyles({
    root: {
        gap: '34px',
    },
    item: {
        width: '100%',
    },
});

const useStepStyles = makeStyles({
    root: {
        gap: '12px',
        alignItems: 'start',
    },
    item: {
        width: '100%',
    },
});

const useInstructionStyles = makeStyles({
    root: {
        gap: '20px',
    },
    item: {
        width: '100%',
    },
});

const useCopyFieldStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * END Styles
 */

const modalProps: IModalProps = {
    isBlocking: true,
};

const connectFirstInstructionValues = {
    b: (chunks: string) => <b>{chunks}</b>,
};

const ConfigureRemoteDesktopDialog: React.FC<ConfigureRemoteDesktopDialogProps> = (
    props: ConfigureRemoteDesktopDialogProps
) => {
    const { hidden, onDismiss } = props;

    // Component state hooks
    const [isCopiedToClipboard, setIsCopiedToClipboard] = React.useState(false);

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const copyConfirmationStyles = useCopyConfirmationStyles();
    const dialogStyles = useDialogStyles();
    const stepNumberStyles = useStepNumberStyles();
    const subscriptionTextFieldStyles = useDynamicMakeStyles(subscriptionTextFieldStylesFactory, isCopiedToClipboard)();
    const stackStyles = useStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const stepsContainerStyles = useStepsContainerStyles();
    const stepStyles = useStepStyles();
    const instructionStyles = useInstructionStyles();
    const copyFieldStyles = useCopyFieldStyles();

    // Callback hooks
    const onCopyClickedHandler = React.useCallback(async () => {
        await navigator.clipboard.writeText(SubscriptionLink);
        setIsCopiedToClipboard(true);
    }, []);

    const onDismissHandler = React.useCallback(() => {
        setIsCopiedToClipboard(false);
        onDismiss();
    }, [onDismiss]);

    // Memoized data
    const dialogContentProps = React.useMemo<IDialogContentProps>(
        () => ({
            title: formatMessage(messages.header),
            type: DialogType.normal,
            styles: dialogStyles,
        }),
        [dialogStyles, formatMessage]
    );

    return (
        <Dialog
            closeButtonAriaLabel={formatMessage(messages.closeButtonAriaLabel)}
            dialogContentProps={dialogContentProps}
            hidden={hidden}
            modalProps={modalProps}
            onDismiss={onDismissHandler}
            maxWidth={560}
        >
            <div className={mergeClasses(stackStyles.root, stepsContainerStyles.root)}>
                <div className={mergeClasses(stackStyles.item, stepsContainerStyles.item)}>
                    <div className={mergeClasses(horizontalStackStyles.root, stepStyles.root)}>
                        <div className={mergeClasses(horizontalStackStyles.item, stepNumberStyles.root)}>1</div>

                        <div className={mergeClasses(horizontalStackStyles.item, stepStyles.item)}>
                            <div className={mergeClasses(stackStyles.root, instructionStyles.root)}>
                                <div className={stackStyles.item}>
                                    <FormattedMessage
                                        id="ConfigureRemoteDesktopDialog_DownloadFirstInstruction_Text"
                                        defaultMessage="To begin, download and install the Remote Desktop application."
                                        description="Text for instructions on downloading a Remote Desktop client."
                                    />
                                </div>

                                <div className={mergeClasses(stackStyles.item, instructionStyles.item)}>
                                    <DownloadRemoteDesktopCard />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={stackStyles.item}>
                    <div className={mergeClasses(horizontalStackStyles.root, stepStyles.root)}>
                        <div className={mergeClasses(horizontalStackStyles.item, stepNumberStyles.root)}>2</div>

                        <div className={mergeClasses(horizontalStackStyles.item, stepStyles.item)}>
                            <div className={mergeClasses(stackStyles.root, instructionStyles.root)}>
                                <div className={stackStyles.item}>
                                    {operatingSystemFamily === OperatingSystemFamily.Windows ? (
                                        <FormattedMessage
                                            id="ConfigureRemoteDesktopDialog_ConnectFirstInstructionWindows_Text"
                                            defaultMessage="Once Remote Desktop is installed, connect your dev box with a subscription URL. Copy the URL, select <b>Subscribe with URL</b> in the Remote Desktop app, then paste it."
                                            description="Text for first set of instructions on connecting to your dev box using a Remote Desktop client on Windows. Do not localize <b> and </b>."
                                            values={connectFirstInstructionValues}
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id="ConfigureRemoteDesktopDialog_ConnectFirstInstructionNonWindows_Text"
                                            defaultMessage="Once Remote Desktop is installed, you’ll need to connect your dev box with a subscription URL. Copy the URL, select <b>Add Workspace</b> in the Remote Desktop app, then paste it."
                                            description="Text for first set of instructions on connecting to your dev box using a Remote Desktop client on non-Windows platforms. Do not localize <b> and </b>."
                                            values={connectFirstInstructionValues}
                                        />
                                    )}
                                </div>

                                <div className={mergeClasses(stackStyles.item, stepStyles.item)}>
                                    <div className={mergeClasses(horizontalStackStyles.root, copyFieldStyles.root)}>
                                        <div
                                            className={mergeClasses(horizontalStackStyles.item, instructionStyles.item)}
                                        >
                                            <TextField
                                                defaultValue={SubscriptionLink}
                                                readOnly
                                                styles={subscriptionTextFieldStyles}
                                                ariaLabel={formatMessage(messages.subscriptionUrlAriaLabel)}
                                            />

                                            {isCopiedToClipboard && (
                                                <Text styles={copyConfirmationStyles}>
                                                    <FormattedMessage
                                                        id="ConfigureRemoteDesktopDialog_CopyConfirmation_Text"
                                                        defaultMessage="Subscription URL copied successfully"
                                                        description="Message confirming that the subscription URL was copied to a user's clipboard"
                                                    />
                                                </Text>
                                            )}
                                        </div>

                                        <div className={horizontalStackStyles.item}>
                                            <DefaultButton
                                                ariaLabel={formatMessage(messages.copyButtonAriaLabel)}
                                                onClick={onCopyClickedHandler}
                                                text={formatMessage(messages.copyButton)}
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className={stackStyles.item}>
                                    <FormattedMessage
                                        id="ConfigureRemoteDesktopDialog_ConnectSecondInstruction_Text"
                                        defaultMessage="All your dev boxes will be available within your Remote Desktop client application once this process is complete."
                                        description="Text for second set of instructions on connecting to your dev box using a Remote Desktop client."
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DialogFooter>
                <DefaultButton
                    ariaLabel={formatMessage(messages.doneButtonAriaLabel)}
                    onClick={onDismissHandler}
                    text={formatMessage(messages.doneButton)}
                />
            </DialogFooter>
        </Dialog>
    );
};

export const ConfigureRemoteDesktopDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen, closeSurface } = useConfigureRemoteDesktopDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <ConfigureRemoteDesktopDialog onDismiss={closeSurface} hidden={!isOpen} />;
};

export default ConfigureRemoteDesktopDialogContextWrapper;
