import { ListRegionRecommendationsResponse } from '../../../data/services/discovery-service/region-recommendation';
import { DataResponse } from '../../../models/common';
import { RegionRecommendation } from '../../../models/region-recommendation';
import { createActionCreator, createActionCreatorWithoutPayload } from '../core-action-creators';
import { ErrorPayload, FailedPayload, ResultPayload } from '../core-actions';
import {
    DevBoxRegionRecommendationActionType,
    ListDevBoxRegionRecommendationsPayload,
} from './dev-box-region-recommendation-actions';

/**
 * RD Gateway region
 */

export const getClosestRDGatewayRegion = createActionCreatorWithoutPayload<DataResponse<string>>(
    DevBoxRegionRecommendationActionType.GetClosestRDGatewayRegion,
    { async: true }
);

export const getClosestRDGatewayRegionError = createActionCreator<ErrorPayload>(
    DevBoxRegionRecommendationActionType.GetClosestRDGatewayRegionError
);

export const getClosestRDGatewayRegionFailed = createActionCreator<FailedPayload>(
    DevBoxRegionRecommendationActionType.GetClosestRDGatewayRegionFailed
);

export const getClosestRDGatewayRegionSuccess = createActionCreator<ResultPayload<string>>(
    DevBoxRegionRecommendationActionType.GetClosestRDGatewayRegionSuccess
);

/**
 * List region recommendations
 */

export const listDevBoxRegionRecommendations = createActionCreator<
    ListDevBoxRegionRecommendationsPayload,
    ListRegionRecommendationsResponse
>(DevBoxRegionRecommendationActionType.ListDevBoxRegionRecommendations, { async: true, startsActivity: true });

export const listDevBoxRegionRecommendationsError = createActionCreator<ErrorPayload>(
    DevBoxRegionRecommendationActionType.ListDevBoxRegionRecommendationsError
);

export const listDevBoxRegionRecommendationsFailed = createActionCreator<FailedPayload>(
    DevBoxRegionRecommendationActionType.ListDevBoxRegionRecommendationsFailed
);

export const listDevBoxRegionRecommendationsSuccess = createActionCreator<ResultPayload<RegionRecommendation[]>>(
    DevBoxRegionRecommendationActionType.ListDevBoxRegionRecommendationsSuccess
);

/**
 * Load region recommendations
 */

export const loadDevBoxRegionRecommendations = createActionCreatorWithoutPayload<DataResponse>(
    DevBoxRegionRecommendationActionType.LoadDevBoxRegionRecommendations,
    { async: true }
);

export const loadDevBoxRegionRecommendationsError = createActionCreator<ErrorPayload>(
    DevBoxRegionRecommendationActionType.LoadDevBoxRegionRecommendationsError
);

export const loadDevBoxRegionRecommendationsFailed = createActionCreator<FailedPayload>(
    DevBoxRegionRecommendationActionType.LoadDevBoxRegionRecommendationsFailed
);

export const loadDevBoxRegionRecommendationsSuccess = createActionCreatorWithoutPayload(
    DevBoxRegionRecommendationActionType.LoadDevBoxRegionRecommendationsSuccess
);
