import { makeStyles } from '@fluentui/react-components';
import { mergeClasses } from '@griffel/core';
import * as React from 'react';
import { RootStyle, useStackStyles } from '../../../themes/styles/flexbox-styles';
import MetadataItem, { ShimmeredMetadataItem } from './metadata-item';
import { MetadataItemViewModel, ShimmeredMetadataItemViewModel } from './models';

export interface MetadataProps {
    metadataList?: MetadataItemViewModel[];
    useContainerStyles?: () => { [key in RootStyle]: string };
}

export interface ShimmeredMetadataProps {
    metadataList?: ShimmeredMetadataItemViewModel[];
}

/*
 * Styles
 */

const useDefaultContainerStyles = makeStyles({
    root: {
        gap: 0,
    },
});

const useShimmerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/*
 * End Styles
 */

export const ShimmeredMetadataList: React.FC<ShimmeredMetadataProps> = React.memo((props: ShimmeredMetadataProps) => {
    const { metadataList } = props;

    if (!metadataList) {
        return <></>;
    }

    const shimmerStyles = useShimmerStyles();
    const stackStyles = useStackStyles();

    return (
        <div className={mergeClasses(stackStyles.root, shimmerStyles.root)}>
            {metadataList.map((metadata) => {
                const { key, width } = metadata;

                return (
                    <div className={stackStyles.item} key={key}>
                        <ShimmeredMetadataItem width={width} />
                    </div>
                );
            })}
        </div>
    );
});

export const MetadataList: React.FC<MetadataProps> = React.memo((props: MetadataProps) => {
    const { metadataList, useContainerStyles } = props;

    const metadataStyles = useContainerStyles ? useContainerStyles() : useDefaultContainerStyles();
    const stackStyles = useStackStyles();

    return (
        <div className={mergeClasses(stackStyles.root, metadataStyles.root)}>
            {(metadataList ?? []).map((metadata) => {
                const { description, icon, key, primary, value } = metadata;

                return (
                    <div className={stackStyles.item} key={key}>
                        <MetadataItem content={value} highlight={primary} iconAriaLabel={description} iconName={icon} />
                    </div>
                );
            })}
        </div>
    );
});

export default MetadataList;
