import { FontSizes, FontWeights, Spinner, SpinnerSize } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useHorizontalStackStyles } from '../../themes/styles/flexbox-styles';
import { PropsWithChildren } from '../../types/props-with-children';
import { AriaLiveWrapper } from './accessibility/aria-live-wrapper';

type InProgressStateMessageProps = PropsWithChildren<{
    ariaLabel: string;
}>;

/**
 * Styles
 */

const useInProgressStateMessageContainerStyles = makeStyles({
    root: {
        gap: '8px',
        alignItems: 'center',
    },
    item: {
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.semibold,
        lineHeight: 16,
    },
});

/**
 * End Styles
 */

export const InProgressStateMessage: React.FC<InProgressStateMessageProps> = (props) => {
    const { children, ariaLabel } = props;

    // Style hooks
    const inProgressStateMessageContainerStyles = useInProgressStateMessageContainerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    return (
        <AriaLiveWrapper>
            <div className={mergeClasses(horizontalStackStyles.root, inProgressStateMessageContainerStyles.root)}>
                <div className={horizontalStackStyles.item}>
                    <Spinner ariaLabel={ariaLabel} size={SpinnerSize.small} />
                </div>

                <div className={mergeClasses(horizontalStackStyles.item, inProgressStateMessageContainerStyles.item)}>
                    {children}
                </div>
            </div>
        </AriaLiveWrapper>
    );
};

export default InProgressStateMessage;
