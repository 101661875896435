import { makeStyles as legacyMakeStyles, Text } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { AppSemanticColor } from '../../../themes/app-semantic-colors';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { isBoolean } from '../../../utilities/boolean';
import { isNumber } from '../../../utilities/number';
import { isString } from '../../../utilities/string';
import { getSemanticColor } from '../../../utilities/styles';
import Label from './label';

export interface ReadOnlyControlProps {
    label?: string;
    value: React.ReactNode;
    required?: boolean;
    ariaLabel?: string;
    tooltipText?: React.ReactElement | string;
    /** This prop changes the styling of the control to match the styling of a disabled input. It has no impact on the functionality of the control. */
    disabled?: boolean;
    tagText?: string;
}

/**
 * Styles
 */

const readOnlyOptionStyleFactory = (disabled: boolean | undefined) =>
    legacyMakeStyles((theme) => ({
        root: {
            boxSizing: 'border-box',
            textOverflow: 'ellipsis',
            paddingRight: 8,
            paddingBottom: 6,
            color: disabled ? getSemanticColor(theme, AppSemanticColor.disabledText) : undefined,
        },
    }));

const readOnlyTextStyleFactory = (disabled: boolean | undefined) =>
    legacyMakeStyles((theme) => ({
        root: {
            color: disabled ? getSemanticColor(theme, AppSemanticColor.disabledText) : undefined,
        },
    }));

const useReadOnlyOptionContainerStyles = makeStyles({
    root: {
        gap: '5px',
    },
});

/**
 * End Styles
 */

export const ReadOnlyControl: React.FC<ReadOnlyControlProps> = (props: ReadOnlyControlProps) => {
    const { label, value, required, ariaLabel, tooltipText, disabled, tagText } = props;

    // Style hooks
    const useReadOnlyOptionStyles = readOnlyOptionStyleFactory(disabled);
    const useReadOnlyTextStyles = readOnlyTextStyleFactory(disabled);
    const readOnlyOptionStyles = useReadOnlyOptionStyles();
    const readOnlyTextStyles = useReadOnlyTextStyles();
    const stackStyles = useStackStyles();
    const readOnlyOptionContainerStyles = useReadOnlyOptionContainerStyles();

    const valueIsStringBooleanOrNumber = React.useMemo(
        () => isString(value) || isNumber(value) || isBoolean(value),
        [value]
    );

    return (
        <div className={mergeClasses(stackStyles.root, readOnlyOptionContainerStyles.root)}>
            <div className={stackStyles.item}>
                <Label
                    aria-label={ariaLabel}
                    required={required}
                    tooltipValue={tooltipText}
                    disabled={disabled}
                    tagContent={tagText}
                >
                    {label}
                </Label>
            </div>
            <div className={mergeClasses(stackStyles.item, readOnlyOptionStyles.root)}>
                {valueIsStringBooleanOrNumber ? <Text styles={readOnlyTextStyles}>{value}</Text> : value}
            </div>
        </div>
    );
};

export default ReadOnlyControl;
