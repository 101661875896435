import { DefaultButton } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useImperativeFilePicker } from 'use-file-picker';
import { FileContent } from 'use-file-picker/dist/interfaces';
import { useStackStyles, useStackWithFullWidthItemStyles } from '../../../../themes/styles/flexbox-styles';
import { areStringsEquivalent } from '../../../../utilities/string';
import FailureMessage from '../../../common/failure-message/failure-message';
import { FailureMessageSeverity, FailureMessageSize } from '../../../common/failure-message/models';
import { CustomizationData } from '../../models';
import { getTaskListFromCustomizationFiles } from '../../selectors';
import { CustomizationFileDisplay } from './customization-file-display';

export interface AddDevBoxFormCustomizationFilePickerProps {
    disabled: boolean;
    onChange: (value: CustomizationData | undefined) => void;
    fileCustomizations: CustomizationData | undefined;
}

interface CustomizationFileProps {
    currentFile: File;
    currentFileContent: FileContent<string>;
    removeFile: (currentFile: File, fileContent: FileContent<string>) => void;
    disabled: boolean;
}

const messages = defineMessages({
    addDevBoxPanelAddCustomizationsButtonText: {
        id: 'AddDevBoxPanelFilePicker_AddCustomizationsButton_Text',
        defaultMessage: 'Add customizations from file',
        description: 'Text for the "Add customizations from file" button',
    },
    addDevBoxPanelAddCustomizationsButtonAriaLabel: {
        id: 'AddDevBoxPanelFilePicker_AddCustomizationsButton_AriaLabel',
        defaultMessage: 'Add customizations from file',
        description: 'Aria label for the "Add customizations from file" button',
    },
});

/**
 * Styles
 */

const useContainerStyles = makeStyles({
    root: {
        gap: '18px',
    },
});

const buttonStyle: React.CSSProperties = {
    width: '100%',
};

/**
 * End Styles
 */

const CustomizationFile: React.FC<CustomizationFileProps> = React.memo((props: CustomizationFileProps) => {
    const { currentFile, removeFile, currentFileContent, disabled } = props;

    const stackStyles = useStackStyles();

    const remove = React.useCallback(() => {
        removeFile(currentFile, currentFileContent);
    }, [removeFile, currentFile, currentFileContent]);

    return (
        <div className={stackStyles.item}>
            <CustomizationFileDisplay name={currentFile.name} onRemove={remove} disabled={disabled} />
        </div>
    );
});

const getPlainFile = (plainFiles: File[], currentFile: FileContent<string>) =>
    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    // Justification: this will never be undefined because there should always be a corresponding plain file
    plainFiles.find((plain: File) => areStringsEquivalent(plain.name, currentFile.name))!;
/* eslint-disable @typescript-eslint/no-non-null-assertion */

export const AddDevBoxFormCustomizationFilePicker: React.FC<AddDevBoxFormCustomizationFilePickerProps> = React.memo(
    (props: AddDevBoxFormCustomizationFilePickerProps) => {
        const { disabled, onChange, fileCustomizations } = props;

        const [hasNoTasks, setHasNoTasks] = React.useState<boolean>(false);

        // Check to see if selected file(s) have tasks
        const checkIfFileHasTasks = React.useCallback((contents: string[]) => {
            const taskList = getTaskListFromCustomizationFiles(contents);

            setHasNoTasks(!taskList);
        }, []);

        // Need to use useImperativeFilePicker in order to remove files one at a time
        const { openFilePicker, filesContent, plainFiles, removeFileByReference } = useImperativeFilePicker({
            accept: '.yaml',
            multiple: false,
            onFilesSelected: ({ plainFiles, filesContent }) => {
                const contents = filesContent.map((file: FileContent<string>) => file.content);

                checkIfFileHasTasks(contents);

                const files = filesContent.map((file: FileContent<string>) => ({
                    fileContent: file,
                    plainFile: getPlainFile(plainFiles, file),
                }));

                onChange({ contents, files });
            },
        });

        // Intl hooks
        const { formatMessage } = useIntl();

        // Style hooks
        const stackStyles = useStackWithFullWidthItemStyles();
        const containerStyles = useContainerStyles();

        const filePicker = React.useCallback(() => openFilePicker(), [openFilePicker]);

        // Need to filter out current file because filesContent doesn't automatically update
        // Filing an issue with use-file-picker to get a onFileRemoved callback
        const removeFile = React.useCallback(
            (currentFile, currentFileContent) => {
                removeFileByReference(currentFile);

                const resultingFiles = filesContent.filter((file) => file !== currentFileContent);

                const contents = resultingFiles.map((file: FileContent<string>) => file.content);

                if (contents.length === 0) {
                    setHasNoTasks(false);
                } else {
                    checkIfFileHasTasks(contents);
                }

                const files = resultingFiles.map((file) => ({
                    fileContent: file,
                    plainFile: getPlainFile(plainFiles, file),
                }));

                onChange({ contents, files });
            },
            [removeFileByReference, filesContent, plainFiles, onChange, checkIfFileHasTasks]
        );

        return (
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                {fileCustomizations?.files?.map((file, index) => (
                    <CustomizationFile
                        key={index}
                        currentFile={file.plainFile}
                        currentFileContent={file.fileContent}
                        removeFile={removeFile}
                        disabled={disabled}
                    />
                ))}
                <div className={stackStyles.item}>
                    <DefaultButton
                        ariaLabel={formatMessage(messages.addDevBoxPanelAddCustomizationsButtonAriaLabel)}
                        text={formatMessage(messages.addDevBoxPanelAddCustomizationsButtonText)}
                        onClick={filePicker}
                        disabled={disabled}
                        style={buttonStyle}
                    />
                </div>
                {hasNoTasks && (
                    <div className={stackStyles.item}>
                        <FailureMessage severity={FailureMessageSeverity.Warning} size={FailureMessageSize.Default}>
                            <FormattedMessage
                                id="AddDevBoxPanel_CustomizationsSectionNoTasks_Text"
                                defaultMessage="There are no customization tasks in the uploaded file(s)"
                                description="Text informing users that their uploaded file has no customization tasks"
                            />
                        </FailureMessage>
                    </div>
                )}
            </div>
        );
    }
);

export default AddDevBoxFormCustomizationFilePicker;
