import { Checkbox, FontWeights, Stack, TooltipHost } from '@fluentui/react';
import { makeStyles } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IntrinsicTask } from '../../../constants/customization';
import { getIntrinsicTasks } from '../../../utilities/customization-task';
import { IntrinsicTaskWithDescription } from './models';
import { getIntrinsicTaskItems } from './selectors';

export interface CustomizationSettingsComponentProps {
    intrinsicTasksOnChange: (value: IntrinsicTask[] | undefined) => void;
    devBoxName: string;
    selectedIntrinsicTasks: IntrinsicTask[] | undefined;
}

/**
 * Styles
 */

const useHeaderTextStyles = makeStyles({
    root: {
        fontWeight: FontWeights.semibold,
        marginBottom: '10px',
    },
});

const containerTokens = { childrenGap: 8 };

/**
 * END Styles
 */

interface IntrinsicTaskCheckboxProps {
    item: IntrinsicTaskWithDescription;
    isChecked: boolean;
    onChange: (task: IntrinsicTask, checked?: boolean) => void;
}

const IntrinsicTaskCheckbox: React.FC<IntrinsicTaskCheckboxProps> = (props) => {
    const { item, onChange, isChecked } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    const onCheckboxChange = React.useCallback(
        (_event: unknown, checked?: boolean) => {
            onChange(item.key, checked);
        },
        [onChange, item]
    );

    return (
        <Stack.Item key={item.key}>
            <TooltipHost content={formatMessage(item.hint, item.hintParameters)}>
                <Checkbox label={formatMessage(item.description)} onChange={onCheckboxChange} checked={isChecked} />
            </TooltipHost>
        </Stack.Item>
    );
};

export const CustomizationSettingsComponent: React.FC<CustomizationSettingsComponentProps> = (props) => {
    const { intrinsicTasksOnChange, devBoxName, selectedIntrinsicTasks } = props;

    // Style hooks
    const headerTextStyles = useHeaderTextStyles();

    const intrinsicTasks = React.useMemo(() => getIntrinsicTasks(), []);

    const intrinsicTaskItems = React.useMemo(
        () => getIntrinsicTaskItems(intrinsicTasks, devBoxName),
        [devBoxName, intrinsicTasks]
    );

    const onCheckboxChange = React.useCallback(
        (key: IntrinsicTask, checked?: boolean) => {
            // Update the customization data when the checked items change
            const previousSelectedIntrinsicTasks = selectedIntrinsicTasks ?? [];

            const updatedSelectedIntrinsicTasks =
                checked ?? false
                    ? [...previousSelectedIntrinsicTasks, key]
                    : previousSelectedIntrinsicTasks.filter((task) => task !== key);

            intrinsicTasksOnChange(
                updatedSelectedIntrinsicTasks.length > 0 ? updatedSelectedIntrinsicTasks : undefined
            );
        },
        [selectedIntrinsicTasks, intrinsicTasksOnChange]
    );

    return (
        <div>
            <Stack tokens={containerTokens}>
                <Stack.Item styles={headerTextStyles}>
                    <FormattedMessage
                        id="AddDevBoxFormCustomizationSettingsControls_Text_IntrinsicTasksHeaderText"
                        defaultMessage="Customization settings"
                        description="Text for the header of the intrinsic tasks section"
                    />
                </Stack.Item>
                {intrinsicTaskItems.map((item: IntrinsicTaskWithDescription) => (
                    <IntrinsicTaskCheckbox
                        item={item}
                        onChange={onCheckboxChange}
                        key={item.key}
                        isChecked={selectedIntrinsicTasks?.includes(item.key) ?? false}
                    />
                ))}
            </Stack>
        </div>
    );
};

export default CustomizationSettingsComponent;
