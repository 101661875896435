import {
    Announced,
    CheckboxVisibility,
    DetailsList,
    IColumn,
    IDetailsHeaderProps,
    IObjectWithKey,
    ISelection,
    Selection,
    SelectionMode,
    TextField,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { EnvironmentDefinitionViewModel } from '../models';
import { selectEnvironmentDefinitionDetailsListMessages } from './messages';
import { EnvironmentDefinitionColumnKey, EnvironmentDefinitionDetailsListItem } from './models';
import {
    getEnvironmentDefinitionDetailsListColumns,
    getEnvironmentDefinitionViewModelKey,
    getFilteredEnvironmentDefinitionDetailsListItems,
    getFormattedEnvironmentDefinitionDetailsListItems,
    getSortedEnvironmentDefinitionDetailsListItems,
} from './selectors';

interface SelectEnvironmentDefinitionDetailsListProps {
    environmentDefinitions: EnvironmentDefinitionViewModel[];
    selectedEnvironmentDefinition: EnvironmentDefinitionViewModel | undefined;
    onEnvironmentDefinitionSelected: (item: EnvironmentDefinitionViewModel | undefined) => void;
}

/**
 * Styles
 */

const useFilterTextFieldStyles = makeStyles({
    root: {
        width: '320px',
    },
});

const useDetailsListContainerStyles = makeStyles({
    root: {
        minHeight: '509px',
        maxHeight: '509px',
        overflowY: 'auto',
    },
});

const useDetailsListHeaderStyles = makeStyles({
    root: {
        paddingTop: 0,
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '51px',
    },
});

/**
 * End Styles
 */

const onRenderItem = (
    item: EnvironmentDefinitionDetailsListItem,
    _index?: number,
    column?: IColumn
): JSX.Element | null => {
    if (!column) {
        return null;
    }

    const { key } = column;
    const { columns } = item;
    const value = columns[key as EnvironmentDefinitionColumnKey];

    if (isUndefinedOrWhiteSpace(value)) {
        return <span>--</span>;
    }

    return <span>{value}</span>;
};

export const SelectEnvironmentDefinitionDetailsList: React.FC<SelectEnvironmentDefinitionDetailsListProps> = (
    props: SelectEnvironmentDefinitionDetailsListProps
) => {
    const {
        selectedEnvironmentDefinition: selectedEnvironmentDefinition,
        environmentDefinitions: environmentDefinitions,
        onEnvironmentDefinitionSelected: onEnvironmentDefinitionSelected,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const detailsListContainerStyles = useDetailsListContainerStyles();
    const filterTextFieldStyles = useFilterTextFieldStyles();
    const headerStyles = useDetailsListHeaderStyles();
    const containerStyles = useContainerStyles();
    const stackStyles = useStackStyles();

    // State hooks
    const [filterText, setFilterText] = React.useState<string>('');
    const [isSortedDescending, setIsSortedDescending] = React.useState<boolean>(false);
    const [sortKey, setSortKey] = React.useState<EnvironmentDefinitionColumnKey>(EnvironmentDefinitionColumnKey.Name);

    const onFilterChange = React.useCallback(
        (_event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue: string | undefined): void => {
            setFilterText(newValue ?? '');
        },
        []
    );

    const onColumnClick = React.useCallback(
        (_ev: unknown, column: IColumn): void => {
            const { key: clickedColumnKey } = column;

            // If sorted column is changing, ensure it's initially sorted ascending
            setIsSortedDescending(clickedColumnKey === sortKey ? !isSortedDescending : false);
            setSortKey(clickedColumnKey as EnvironmentDefinitionColumnKey);
        },
        [sortKey, isSortedDescending]
    );

    const onSelectedEnvironmentDefinitionChange = React.useCallback(
        (selectedItems: EnvironmentDefinitionDetailsListItem[]): void => {
            onEnvironmentDefinitionSelected(selectedItems[0]?.value);
        },
        [onEnvironmentDefinitionSelected]
    );

    const onRenderDetailsHeader = React.useCallback(
        (
            headerProps?: IDetailsHeaderProps,
            defaultRender?: (props?: IDetailsHeaderProps) => JSX.Element | null
        ): JSX.Element | null => {
            if (!headerProps || !defaultRender) {
                return null;
            }

            return defaultRender({
                ...headerProps,
                styles: headerStyles,
            });
        },
        [headerStyles]
    );

    // Memo hooks
    const columns: IColumn[] = React.useMemo(
        () =>
            getEnvironmentDefinitionDetailsListColumns(
                isSortedDescending,
                formatMessage,
                sortKey,
                onColumnClick,
                onRenderItem
            ),
        [sortKey, formatMessage, isSortedDescending, onColumnClick, onRenderItem]
    );

    // Format, sort, and filter items
    const formattedItems: EnvironmentDefinitionDetailsListItem[] = React.useMemo(
        () => getFormattedEnvironmentDefinitionDetailsListItems(environmentDefinitions),
        [environmentDefinitions]
    );
    const sortedItems: EnvironmentDefinitionDetailsListItem[] = React.useMemo(
        () => getSortedEnvironmentDefinitionDetailsListItems(sortKey, isSortedDescending, formattedItems),
        [sortKey, isSortedDescending, formattedItems]
    );
    const items: EnvironmentDefinitionDetailsListItem[] = React.useMemo(
        () => getFilteredEnvironmentDefinitionDetailsListItems(filterText, sortedItems),
        [filterText, sortedItems]
    );

    const announceKey: string = React.useMemo(() => `${filterText}-${items.length}`, [filterText, items]);

    const itemCount: number = items.length;

    const announceMessage: string = React.useMemo(
        () =>
            formatMessage(selectEnvironmentDefinitionDetailsListMessages.environmentResourcesFilterText, { itemCount }),
        [formatMessage, itemCount]
    );

    const selection: ISelection<EnvironmentDefinitionDetailsListItem> = React.useMemo(() => {
        return new Selection<EnvironmentDefinitionDetailsListItem>({
            onSelectionChanged: () => {
                onSelectedEnvironmentDefinitionChange(selection.getSelection());
            },
        });
    }, [onSelectedEnvironmentDefinitionChange]);

    // keeping the selection state in sync with our form state on first load
    React.useEffect(() => {
        if (selectedEnvironmentDefinition?.id !== undefined) {
            selection.setKeySelected(getEnvironmentDefinitionViewModelKey(selectedEnvironmentDefinition), true, false);
        }
    }, []);

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.item}>
                <TextField
                    placeholder={formatMessage(selectEnvironmentDefinitionDetailsListMessages.filterInputPlaceholder)}
                    ariaLabel={formatMessage(selectEnvironmentDefinitionDetailsListMessages.filterInputAriaLabel)}
                    autoFocus
                    onChange={onFilterChange}
                    styles={filterTextFieldStyles}
                />
            </div>
            <Announced key={announceKey} message={announceMessage} />
            <div className={mergeClasses(stackStyles.item, detailsListContainerStyles.root)}>
                <DetailsList
                    items={items}
                    compact={false}
                    columns={columns}
                    selectionMode={SelectionMode.single}
                    setKey="single"
                    selectionPreservedOnEmptyClick={true}
                    // Need this type coercion because DetailsLists don't support generics and while a PoolDetailsListItem is an IObjectWithKey an IObjectWithyKey is not a PoolDetailsListItem
                    selection={selection as any as ISelection<IObjectWithKey>} // eslint-disable-line @typescript-eslint/no-explicit-any
                    checkboxVisibility={CheckboxVisibility.hidden}
                    onRenderDetailsHeader={onRenderDetailsHeader}
                />
            </div>
        </div>
    );
};

export default SelectEnvironmentDefinitionDetailsList;
