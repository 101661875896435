import {
    BaseButton,
    FontSizes,
    FontWeights,
    Icon,
    IconButton,
    IIconProps,
    makeStyles as legacyMakeStyles,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { CssSelector } from '../../themes/constants/css-selectors';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import { isUndefinedOrWhiteSpace } from '../../utilities/string';
import { getSemanticColor } from '../../utilities/styles';

interface QuickActionCardProps {
    iconAriaLabel?: string;
    iconName?: string;
    onActionInvoked: () => void;
    onDismiss?: () => void;
    subtitle: React.ReactNode;
    title: React.ReactNode;
}

const messages = defineMessages({
    closeButtonAriaLabel: {
        id: 'QuickActionCard_CloseButton_AriaLabel',
        defaultMessage: 'Close',
        description: 'Aria label for quick action card "Close" button',
    },
});

/**
 * Styles
 */

const useCardStyles = makeStyles({
    root: {
        borderRadius: '4px',
        // Indicates that this element is clickable
        cursor: 'pointer',
        overflow: 'hidden',
        position: 'relative',
        width: '256px',

        ':before': {
            bottom: 0,
            // IMPORTANT: need to double-quote content properties. Empty single quotes get translated to undefined,
            // essentially, meaning the ::before element won't render
            content: '""',
            display: 'block',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '4px',
        },

        [CssSelector.ScreenSizeSmallAndBelow]: {
            width: '100%',
        },
    },
});

const useCloseButtonStyles = makeStyles({
    icon: {
        fontSize: '12px',
    },
    root: {
        height: '24px',
        position: 'absolute',
        right: 0,
        top: 0,
        width: '24px',
    },
});

const useContentContainerStyles = makeStyles({
    root: {
        padding: '12px 12px 12px 16px',
        height: '100%',
    },
});

const useIconContainerStyles = legacyMakeStyles((theme) => ({
    root: {
        color: getSemanticColor(theme, 'quickActionCardIcon'),
    },
}));

const useStyles = makeStyles({
    icon: {
        fontSize: FontSizes.size24,
        lineHeight: '24px',
        width: '32px',
    },
});

const useSubtitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size10,
        lineHeight: '14px',
    },
});

const useTextContainerStyles = makeStyles({
    root: {
        // setting a min width value for this element allows it to shrink
        minWidth: 0,
    },
});

const useTextStyles = makeStyles({
    root: {
        textAlign: 'left',
        width: '100%',
        gap: '2px',
    },
});

const useTitleStyles = makeStyles({
    root: {
        fontSize: FontSizes.size12,
        fontWeight: FontWeights.semibold,
        lineHeight: '16px',
    },
});

const useContentStyles = makeStyles({
    root: {
        gap: '12px',
        alignItems: 'center',
    },
});

/**
 * END Styles
 */

const closeButtonIconProps: IIconProps = {
    iconName: 'Cancel',
};

export const QuickActionCard: React.FC<QuickActionCardProps> = React.memo((props: QuickActionCardProps) => {
    const { iconAriaLabel, iconName, onActionInvoked, onDismiss, subtitle, title } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const cardStyles = useCardStyles();
    const classNames = useStyles();
    const closeButtonStyles = useCloseButtonStyles();
    const contentContainerStyles = useContentContainerStyles();
    const iconContainerStyles = useIconContainerStyles();
    const subtitleStyles = useSubtitleStyles();
    const textContainerStyles = useTextContainerStyles();
    const textStyles = useTextStyles();
    const titleStyles = useTitleStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const stackStyles = useStackStyles();
    const contentStyles = useContentStyles();

    // Callback hooks
    const onDismissHandler = React.useCallback(
        (
            event: React.MouseEvent<
                HTMLAnchorElement | HTMLButtonElement | HTMLDivElement | HTMLSpanElement | BaseButton,
                MouseEvent
            >
        ) => {
            if (onDismiss) {
                // Block event from propagating up to the card's click handler
                event.stopPropagation();

                onDismiss();
            }
        },
        [onDismiss]
    );

    return (
        <div
            className={mergeClasses(horizontalStackStyles.root, cardStyles.root)}
            tabIndex={0}
            onClick={onActionInvoked}
            onKeyPress={onActionInvoked}
        >
            <div className={mergeClasses(horizontalStackStyles.item, contentContainerStyles.root)}>
                <div className={mergeClasses(horizontalStackStyles.root, contentStyles.root)}>
                    {!isUndefinedOrWhiteSpace(iconName) && (
                        <div className={mergeClasses(horizontalStackStyles.item, iconContainerStyles.root)}>
                            <Icon aria-label={iconAriaLabel} className={classNames.icon} iconName={iconName} />
                        </div>
                    )}

                    <div className={mergeClasses(horizontalStackStyles.item, textContainerStyles.root)}>
                        <div className={mergeClasses(stackStyles.root, textStyles.root)}>
                            <div className={mergeClasses(stackStyles.item, titleStyles.root)}>{title}</div>
                            <div className={mergeClasses(stackStyles.item, subtitleStyles.root)}>{subtitle}</div>
                        </div>
                    </div>
                </div>
            </div>

            {onDismiss && (
                <IconButton
                    ariaLabel={formatMessage(messages.closeButtonAriaLabel)}
                    iconProps={closeButtonIconProps}
                    onClick={onDismissHandler}
                    styles={closeButtonStyles}
                />
            )}
        </div>
    );
});

export default QuickActionCard;
