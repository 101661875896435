import { FontSizes, FontWeights } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { useActionCreator } from '../../hooks/action-creator';
import {
    useChangeEnvironmentExpirationDialogContext,
    useConfirmationDialogContext,
    useRedeployEnvironmentDialogContext,
} from '../../hooks/context/dialogs';
import { useProjectFilterContext } from '../../hooks/context/filters';
import {
    useEnvironmentDetailsPanelContext,
    useErrorDetailsPanelContext,
    useRedeployEnvironmentPanelContext,
} from '../../hooks/context/panels';
import { Failure } from '../../models/common';
import {
    clearDeployEnvironmentFailure,
    deleteEnvironment,
} from '../../redux/actions/environment/environment-action-creators';
import { CssSelector } from '../../themes/constants/css-selectors';
import { useHorizontalStackStyles, useStackStyles } from '../../themes/styles/flexbox-styles';
import { ReturnVoid } from '../../types/return-void';
import { ConfirmationDialogProperties } from '../common/confirmation-dialog/contexts';
import { ErrorDetailsPanelContextProperties } from '../error-details-panel/contexts';
import { RedeployEnvironmentPanelContextProperties } from '../redeploy-environment-panel/contexts';
import { ChangeEnvironmentExpirationDialogProperties } from './change-expiration-dialog/contexts';
import EnvironmentCard from './environment-card/environment-card';
import { EnvironmentDetailsPanelContextProperties } from './environment-details-panel/context';
import { EnvironmentViewModel } from './models';
import { RedeployEnvironmentDialogProperties } from './redeploy-environment-dialog/contexts';
import { getEnvironmentViewModels, getEnvironmentViewModelsInProject } from './selectors';

interface EnvironmentsComponentProps {
    environments: EnvironmentViewModel[];
    selectedProjectFilter: string | undefined;
    onDeleteEnvironmentSubmitted: ReturnVoid<typeof deleteEnvironment>;
    onOpenErrorDetailsPanel: (props: ErrorDetailsPanelContextProperties) => void;
    onOpenConfirmationDialog: (properties: ConfirmationDialogProperties) => void;
    onOpenRedeployEnvironmentPanel: (props: RedeployEnvironmentPanelContextProperties) => void;
    onClearRedeployEnvironmentFailure: () => void;
    onOpenRedeployEnvironmentDialog: (props: RedeployEnvironmentDialogProperties) => void;
    onOpenChangeEnvironmentExpirationDialog: (props: ChangeEnvironmentExpirationDialogProperties) => void;
    onOpenEnvironmentDetailsPanel: (props: EnvironmentDetailsPanelContextProperties) => void;
}

const messages = defineMessages({
    yourEnvironmentMessage: {
        id: 'Environments_YourEnvironmentMessage_Text',
        defaultMessage: 'Your environment',
        description: 'This text is informing the user that their environment is listed on this page',
    },
    yourEnvironmentsPluralMessage: {
        id: 'Environments_YourEnvironmentPluralMessage_Text',
        defaultMessage: 'Your environments',
        description: 'This text is informing the user that their environments are listed on this page',
    },
});

/**
 * Styles
 */

const useGridStyles = makeStyles({
    root: {
        width: '100%',
        flexWrap: 'wrap',
    },
});

const useGridItemStyles = makeStyles({
    root: {
        marginBottom: '24px',
        marginRight: '12px',

        [CssSelector.ScreenSizeSmallAndBelow]: {
            width: '100%',
        },

        [CssSelector.ScreenSizeXXLarge]: {
            marginRight: '24px',
        },
    },
});

const useSectionStyles = makeStyles({
    root: {
        width: 'auto',

        [CssSelector.ScreenSizeSmallAndBelow]: {
            width: '100%',
        },
    },
});

const useSectionHeaderStyles = makeStyles({
    h2: {
        fontSize: FontSizes.size16,
        fontWeight: FontWeights.semibold,
    },
});

const useContainerStyles = makeStyles({
    root: {
        gap: '11px',
    },
});

/**
 * END Styles
 */

export const EnvironmentsComponent: React.FC<EnvironmentsComponentProps> = React.memo(
    (props: EnvironmentsComponentProps) => {
        const {
            environments,
            selectedProjectFilter,
            onDeleteEnvironmentSubmitted,
            onOpenErrorDetailsPanel,
            onOpenConfirmationDialog,
            onOpenRedeployEnvironmentPanel,
            onClearRedeployEnvironmentFailure,
            onOpenRedeployEnvironmentDialog,
            onOpenChangeEnvironmentExpirationDialog,
            onOpenEnvironmentDetailsPanel,
        } = props;

        // Style hooks
        const gridStyles = useGridStyles();
        const gridItemStyles = useGridItemStyles();
        const sectionStyles = useSectionStyles();
        const sectionHeaderStyles = useSectionHeaderStyles();
        const containerStyles = useContainerStyles();
        const stackStyles = useStackStyles();
        const horizontalStackStyles = useHorizontalStackStyles();

        // Callback hooks
        const onDeleteSubmitted = React.useCallback(
            (id: string) => {
                onDeleteEnvironmentSubmitted({ id });
            },
            [onDeleteEnvironmentSubmitted]
        );

        const onSeeErrorDetails = React.useCallback(
            (failure: Failure) => {
                onOpenErrorDetailsPanel({ failure });
            },
            [onOpenErrorDetailsPanel]
        );

        // Memoized data
        const filteredEnvironments = React.useMemo(
            () =>
                selectedProjectFilter
                    ? getEnvironmentViewModelsInProject(environments, selectedProjectFilter)
                    : environments,
            [environments, selectedProjectFilter]
        );

        const yourEnvironmentMessage = React.useMemo(
            () =>
                filteredEnvironments.length === 0
                    ? undefined
                    : filteredEnvironments.length > 1
                    ? messages.yourEnvironmentsPluralMessage
                    : messages.yourEnvironmentMessage,
            [filteredEnvironments, messages]
        );

        return (
            <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                <div className={stackStyles.item}>
                    <h2 className={sectionHeaderStyles.h2}>
                        {yourEnvironmentMessage && <FormattedMessage {...yourEnvironmentMessage} />}
                    </h2>
                </div>

                <div className={mergeClasses(stackStyles.item, sectionStyles.root)}>
                    <div className={mergeClasses(horizontalStackStyles.root, gridStyles.root)}>
                        {filteredEnvironments.map((environment: EnvironmentViewModel) => (
                            <div
                                key={environment.key}
                                className={mergeClasses(horizontalStackStyles.item, gridItemStyles.root)}
                            >
                                <EnvironmentCard
                                    environment={environment}
                                    onDeleteSubmitted={onDeleteSubmitted}
                                    onSeeErrorDetails={onSeeErrorDetails}
                                    onOpenConfirmationDialog={onOpenConfirmationDialog}
                                    onOpenRedeployEnvironmentPanel={onOpenRedeployEnvironmentPanel}
                                    onClearRedeployEnvironmentFailure={onClearRedeployEnvironmentFailure}
                                    onOpenRedeployDialog={onOpenRedeployEnvironmentDialog}
                                    onOpenChangeEnvironmentExpirationDialog={onOpenChangeEnvironmentExpirationDialog}
                                    onOpenEnvironmentDetailsPanel={onOpenEnvironmentDetailsPanel}
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
);

export const Environments: React.FC = () => {
    const { value } = useProjectFilterContext();

    // Application state hooks
    const environments = useSelector(getEnvironmentViewModels);

    // Action hooks
    const onDeleteEnvironmentSubmitted = useActionCreator(deleteEnvironment);
    const onClearRedeployEnvironmentFailure = useActionCreator(clearDeployEnvironmentFailure);

    // Context hooks
    const { openSurface: onOpenErrorDetailsPanel } = useErrorDetailsPanelContext();
    const { openSurface: onOpenConfirmationDialog } = useConfirmationDialogContext();
    const { openSurface: onOpenRedeployEnvironmentPanel } = useRedeployEnvironmentPanelContext();
    const { openSurface: OnOpenRedeployEnvironmentDialog } = useRedeployEnvironmentDialogContext();
    const { openSurface: onOpenChangeEnvironmentExpirationDialog } = useChangeEnvironmentExpirationDialogContext();
    const { openSurface: onOpenEnvironmentDetailsPanel } = useEnvironmentDetailsPanelContext();

    return (
        <EnvironmentsComponent
            environments={environments}
            selectedProjectFilter={value}
            onDeleteEnvironmentSubmitted={onDeleteEnvironmentSubmitted}
            onOpenErrorDetailsPanel={onOpenErrorDetailsPanel}
            onOpenConfirmationDialog={onOpenConfirmationDialog}
            onOpenRedeployEnvironmentPanel={onOpenRedeployEnvironmentPanel}
            onClearRedeployEnvironmentFailure={onClearRedeployEnvironmentFailure}
            onOpenRedeployEnvironmentDialog={OnOpenRedeployEnvironmentDialog}
            onOpenChangeEnvironmentExpirationDialog={onOpenChangeEnvironmentExpirationDialog}
            onOpenEnvironmentDetailsPanel={onOpenEnvironmentDetailsPanel}
        />
    );
};

export default Environments;
