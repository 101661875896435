import { MessageDescriptor } from 'react-intl';
import { IntrinsicTask } from '../../../constants/customization';
import { AzureDevOpsBranch } from '../../../models/azure-dev-ops';
import { CustomizationTaskListValidationError } from '../../../models/customization';
import { UnionMap } from '../../../types/union-map';

export type DevBoxNameFieldErrorType = 'AlreadyExists' | 'InvalidFormat' | 'None' | 'TooLong' | 'TooShort';

export const DevBoxNameFieldErrorType: UnionMap<DevBoxNameFieldErrorType> = {
    AlreadyExists: 'AlreadyExists',
    InvalidFormat: 'InvalidFormat',
    None: 'None',
    TooLong: 'TooLong',
    TooShort: 'TooShort',
};

export type AzureDevOpsRepoSelection = {
    branch?: AzureDevOpsBranch;
    customizationFiles?: string[];
};

export interface AzureDevOpsSelection extends AzureDevOpsRepoSelection {
    customizationFiles?: string[];
}

export type ValidationResultSection = {
    title: string;
    errors?: CustomizationTaskListValidationError[];
};

export type IntrinsicTaskWithDescription = {
    // We will ensure the uniqueness of the name of each intrinsic task, so that we can use it as an explicit key
    key: IntrinsicTask;
    description: MessageDescriptor;
    hint: MessageDescriptor;
    hintParameters?: { [key: string]: string };
};
