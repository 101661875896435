import { Label, makeStyles as legacyMakeStyles, Link } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { get } from '../../../utilities/serializable-map';
import { PoolDropdown } from '../../common/form/dropdown/data-controls/pool/pool-dropdown';
import PoolDropdownItem from '../../common/form/dropdown/data-controls/pool/pool-dropdown-item';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import { PoolViewModel, ProjectToPoolViewModelMap } from '../models';
import SelectDevBoxTypeDialog from '../select-dev-box-pool-dialog/select-dev-box-pool-dialog';

interface AddDevBoxFormPoolControlsProps {
    disabled: boolean;
    poolsByProject: ProjectToPoolViewModelMap;
    selectedProjectId: string | undefined;
    selectedPool: PoolViewModel | undefined;
    errorMessage: string;
    onChange: (event: PoolViewModel | undefined) => void;
}

const messages = defineMessages({
    addDevBoxFormPoolFieldDropdownText: {
        id: 'AddDevBoxFormPoolControls_Dropdown_Text',
        defaultMessage: 'Dev box pool',
        description: 'Text for the pool dropdown in the add dev box panel label',
    },
    addDevBoxFormPoolFieldDropdownAriaLabel: {
        id: 'AddDevBoxFormPoolControls_Dropdown_AriaLabel',
        defaultMessage: 'Pool for your dev box',
        description: 'Aria label for the pool dropdown in the add dev box panel',
    },
    addDevBoxFormPoolFieldDropdownPlaceholder: {
        id: 'AddDevBoxFormPoolControls_Dropdown_Placeholder',
        defaultMessage: 'Please select a pool closest to your physical location for optimal performance.',
        description: 'Placeholder text for the pool dropdown in the add dev box panel',
    },
});

/**
 * Styles
 */

const usePoolDropdownOptionStyles = legacyMakeStyles({
    // Leaving the old makeStyles here until we refactor the dropdown, because changing it causes problems with the placeholder text color
    title: {
        height: '58px',
        lineHeight: 20,
    },
    dropdownItem: {
        height: '62px',
    },
    dropdownItemSelected: {
        height: '62px',
    },
});

const useReadOnlyOptionStyles = makeStyles({
    root: {
        boxSizing: 'border-box',
        textOverflow: 'ellipsis',
        paddingRight: '8px',
        paddingBottom: '6px',
    },
});

const useSelectionContainerStyles = makeStyles({
    root: {
        gap: '8px',
        color: 'pink',
    },
});

const usePoolReadOnlyOptionContainerStyles = makeStyles({
    root: {
        gap: '5px',
    },
});

/**
 * End Styles
 */

export const AddDevBoxFormPoolControls: React.FC<AddDevBoxFormPoolControlsProps> = (
    props: AddDevBoxFormPoolControlsProps
) => {
    const { disabled, poolsByProject, selectedProjectId, selectedPool, errorMessage, onChange } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const poolDropdownOptionStyles = usePoolDropdownOptionStyles();
    const readOnlyOptionStyles = useReadOnlyOptionStyles();
    const stackStyles = useStackWithFullWidthItemStyles();
    const selectionContainerStyles = useSelectionContainerStyles();
    const poolReadOnlyOptionContainerStyles = usePoolReadOnlyOptionContainerStyles();

    // State hooks
    const [showSelectDevBoxPoolDialog, setShowSelectDevBoxPoolDialog] = React.useState(false);

    // Memo hooks
    const pools: PoolViewModel[] = React.useMemo(
        () => (!!selectedProjectId ? get(poolsByProject, selectedProjectId) ?? [] : []),
        [poolsByProject, selectedProjectId]
    );

    const hasMultiplePools = pools.length > 1;
    const hasNoPools = pools.length < 1;

    const selectDevBoxPoolModalOnDismiss = React.useCallback(() => setShowSelectDevBoxPoolDialog(false), []);
    const onSeeAllTypesButtonClick = React.useCallback(() => setShowSelectDevBoxPoolDialog(true), []);

    // pre-select the first pool if we only have one
    React.useEffect(() => {
        if (pools.length === 1) {
            onChange(pools[0]);
        }
    }, [pools]);

    if (hasMultiplePools) {
        return (
            <div className={mergeClasses(stackStyles.root, selectionContainerStyles.root)}>
                <div className={stackStyles.item}>
                    <PoolDropdown
                        value={selectedPool}
                        label={formatMessage(messages.addDevBoxFormPoolFieldDropdownText)}
                        ariaLabel={formatMessage(messages.addDevBoxFormPoolFieldDropdownAriaLabel)}
                        placeholder={formatMessage(messages.addDevBoxFormPoolFieldDropdownPlaceholder)}
                        options={pools}
                        onChange={onChange}
                        styles={poolDropdownOptionStyles}
                        disabled={disabled}
                        errorMessage={errorMessage}
                        autoSelectMode={AutoSelectMode.WhenOnlyHasOneOption}
                        required
                    />
                </div>
                <div className={stackStyles.item}>
                    <Link onClick={onSeeAllTypesButtonClick} disabled={disabled}>
                        <FormattedMessage
                            id="AddDevBoxFormPoolControls_SeeAllTypesLink_Text"
                            defaultMessage="See all pools"
                            description="Text for the see all pools link for the add dev box pool form field"
                        />
                    </Link>
                    <SelectDevBoxTypeDialog
                        pools={pools}
                        onDismiss={selectDevBoxPoolModalOnDismiss}
                        showDialog={showSelectDevBoxPoolDialog}
                        onChange={onChange}
                        selectedPool={selectedPool}
                    />
                </div>
            </div>
        );
    }

    if (!selectedPool || hasNoPools) {
        return <></>;
    }

    return (
        <div className={mergeClasses(stackStyles.root, poolReadOnlyOptionContainerStyles.root)}>
            <div className={stackStyles.item}>
                <Label>
                    <FormattedMessage
                        id="AddDevBoxFormPoolControls_PoolReadOnly_Text"
                        defaultMessage="Dev box pool"
                        description="Text for the pool read only field in the add dev box panel label"
                    />
                </Label>
            </div>
            <div className={mergeClasses(stackStyles.item, readOnlyOptionStyles.root)}>
                <PoolDropdownItem pool={selectedPool} />
            </div>
        </div>
    );
};

export default AddDevBoxFormPoolControls;
