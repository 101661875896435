import { DefaultButton, DialogFooter, Label, makeStyles } from '@fluentui/react';
import { mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { Field, Form } from 'react-final-form';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { AsyncState } from '../../../redux/store/common-state';
import { useStackWithFullWidthItemStyles } from '../../../themes/styles/flexbox-styles';
import { getDateForBrowserTimeZone } from '../../../utilities/time';
import { AutoSelectMode } from '../../common/form/dropdown/dropdown';
import SubmitButton from '../../common/submit-button';
import DelayShutdownDropdown from './delay-shutdown-dropdown';
import { DelayShutdownDropdownOption, DelayShutdownFormData, DelayShutdownFormFieldName } from './model';
import { getDropdownOptions, skipOrDelayInTerminalState } from './selectors';

interface DelayShutdownDialogFormProps {
    devBoxId: string;
    locale: string;
    skipState: AsyncState;
    delayState: AsyncState;
    originalScheduledTime: Date;
    nextScheduledTime: Date;
    onDismiss: () => void;
    onDelaySubmitted: (devBoxId: string, delayUntil: Date) => void;
    onSkipSubmitted: (devBoxId: string) => void;
    hasHibernateSupport: boolean;
}

interface DelayShutdownDialogFooterComponentProps {
    isSkipSelected: boolean;
    hasHibernateSupport: boolean;
    isSkipNextDay: boolean;
}

const messages = defineMessages({
    delayButtonText: {
        id: 'DelayShutdownDialogForm_DelayButton_Text',
        defaultMessage: 'Delay',
        description: 'Text for the "Delay" button',
    },
    delayButtonAriaLabel: {
        id: 'DelayShutdownDialogForm_DelayButton_AriaLabel',
        defaultMessage: 'Delay',
        description: 'Aria label for the "Delay" button',
    },
    skipButtonText: {
        id: 'DelayShutdownDialogForm_SkipButton_Text',
        defaultMessage: 'Skip',
        description: 'Text for the "Skip" button',
    },
    skipButtonAriaLabel: {
        id: 'DelayShutdownDialogForm_SkipButton_AriaLabel',
        defaultMessage: 'Skip',
        description: 'Aria label for the "Skip" button',
    },
    cancelButtonText: {
        id: 'DelayShutdownDialogForm_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the "Cancel" button',
    },
    cancelButtonAriaLabel: {
        id: 'DelayShutdownDialogForm_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the "Cancel" button',
    },
    delayShutdownFailureMessage: {
        id: 'DelayShutdownDialogForm_DelayShutdownErrorMessage_Text',
        defaultMessage: 'Invalid time selected, please select another time.',
        description: 'Failure message text for the delay shutdown until field',
    },
});

/**
 * Styles
 */

const useDialogContentStyles = makeStyles({
    root: {
        paddingLeft: '6px',
    },
});

const useLabelStyles = makeStyles({
    root: {
        paddingBottom: '10px',
        marginTop: '15px',
    },
});

const useStyles = makeStyles({
    skipLabel: {
        paddingTop: '7px',
    },
    dialogFooter: {
        marginTop: '55px',
    },
});

/**
 * END Styles
 */

export const DelayShutdownDialogFooterComponent: React.FC<DelayShutdownDialogFooterComponentProps> = (
    props: DelayShutdownDialogFooterComponentProps
) => {
    const { isSkipSelected, isSkipNextDay } = props;

    // Style hooks
    const styles = useStyles();

    if (isSkipSelected && isSkipNextDay) {
        return (
            <div className={styles.skipLabel}>
                <FormattedMessage
                    id="DelayShutdownDialogForm_SkipLabel_Tomorrow_Text"
                    defaultMessage="Tomorrow's shutdown will be skipped"
                    description="Text for the skip shutdown label for tomorrow"
                />
            </div>
        );
    }

    if (isSkipSelected) {
        <div className={styles.skipLabel}>
            <FormattedMessage
                id="DelayShutdownDialogForm_SkipLabel_Text"
                defaultMessage="Today's shutdown will be skipped"
                description="Text for the skip shutdown label"
            />
        </div>;
    }

    return <></>;
};

export const DelayShutdownDialogForm: React.FC<DelayShutdownDialogFormProps> = (
    props: DelayShutdownDialogFormProps
) => {
    const {
        devBoxId,
        locale,
        skipState,
        delayState,
        originalScheduledTime,
        nextScheduledTime,
        onDismiss,
        onSkipSubmitted,
        onDelaySubmitted,
        hasHibernateSupport,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const styles = useStyles();
    const dialogContentStyles = useDialogContentStyles();
    const labelStyles = useLabelStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    // Memoized data
    const scheduledShutdown = React.useMemo(() => getDateForBrowserTimeZone(nextScheduledTime), [nextScheduledTime]);

    const dropdownOptions: DelayShutdownDropdownOption[] = React.useMemo(
        () => getDropdownOptions(originalScheduledTime, scheduledShutdown, locale, new Date()),
        [originalScheduledTime, scheduledShutdown, locale]
    );

    const initialValues: DelayShutdownFormData = React.useMemo(
        () => ({
            delayShutdownUntil: undefined,
        }),
        []
    );

    // Callbacks
    const onFormSubmit = React.useCallback(
        (data: DelayShutdownFormData) => {
            const { delayShutdownUntil } = data;
            if (!delayShutdownUntil) {
                return;
            }

            if (delayShutdownUntil.isSkip) {
                onSkipSubmitted(devBoxId);
            } else {
                onDelaySubmitted(devBoxId, delayShutdownUntil.dateValue);
            }
        },
        [devBoxId, onDelaySubmitted, onSkipSubmitted]
    );

    return (
        <Form<DelayShutdownFormData> initialValues={initialValues} onSubmit={onFormSubmit}>
            {(formProps) => {
                const { form, valid, submitting, values } = formProps;
                const { delayShutdownUntil } = values;

                const { reset, submit } = form;

                const isSkipOrDelayInTerminalState = React.useMemo(
                    () => skipOrDelayInTerminalState(skipState, delayState),
                    [skipState, delayState]
                );

                const isSubmitting = React.useMemo(
                    () => !isSkipOrDelayInTerminalState && submitting,
                    [submitting, isSkipOrDelayInTerminalState]
                );

                const submitButtonDisabled = React.useMemo(() => isSubmitting || !valid, [isSubmitting, valid]);

                const submitButtonLabel = React.useMemo(
                    () =>
                        delayShutdownUntil?.isSkip
                            ? formatMessage(messages.skipButtonAriaLabel)
                            : formatMessage(messages.delayButtonAriaLabel),
                    [formatMessage, delayShutdownUntil?.isSkip]
                );

                const onDismissForm = React.useCallback(() => {
                    reset();
                    onDismiss();
                }, [reset, onDismiss]);

                const onSubmitClicked = React.useCallback(() => {
                    if (valid) {
                        submit();
                    }

                    if (!isSubmitting) {
                        onDismissForm();
                    }
                }, [valid, isSubmitting, submit, onDismissForm]);

                const dropdownOnChange = React.useCallback(
                    (date: DelayShutdownDropdownOption | undefined) => {
                        form.change(DelayShutdownFormFieldName.DelayShutdownUntil, date);
                    },
                    [form.change]
                );

                return (
                    <>
                        <div className={mergeClasses(stackStyles.root, dialogContentStyles.root)}>
                            <div className={stackStyles.item}>
                                <FormattedMessage
                                    id="DelayShutdownDialogForm_Header_Text"
                                    defaultMessage="Scheduled events can be delayed up to 8 hours from the originally scheduled time. A delay value outside that range will cause the scheduled event to be skipped for the day."
                                    description="Text explaining how delay works"
                                />
                            </div>
                            <div className={stackStyles.item}>
                                <Label styles={labelStyles}>
                                    <FormattedMessage
                                        id="DelayShutdownDialogForm_Input_Text"
                                        defaultMessage="Delay shutdown until"
                                        description='Text for the "delay shutdown until" input text'
                                    />
                                </Label>
                            </div>
                            <div className={stackStyles.item}>
                                <Field<DelayShutdownDropdownOption>
                                    name={DelayShutdownFormFieldName.DelayShutdownUntil}
                                >
                                    {() => (
                                        <>
                                            <DelayShutdownDropdown
                                                options={dropdownOptions}
                                                value={values.delayShutdownUntil}
                                                onChange={dropdownOnChange}
                                                autoSelectMode={AutoSelectMode.Always}
                                            />
                                            <DelayShutdownDialogFooterComponent
                                                isSkipSelected={delayShutdownUntil?.isSkip || false}
                                                isSkipNextDay={delayShutdownUntil?.isSkipNextDay || false}
                                                hasHibernateSupport={hasHibernateSupport}
                                            />
                                        </>
                                    )}
                                </Field>
                            </div>
                        </div>
                        <DialogFooter className={styles.dialogFooter}>
                            <SubmitButton
                                ariaLabel={submitButtonLabel}
                                onClick={onSubmitClicked}
                                text={submitButtonLabel}
                                isSubmitting={submitting}
                                disabled={submitButtonDisabled}
                            />
                            <DefaultButton
                                ariaLabel={formatMessage(messages.cancelButtonAriaLabel)}
                                onClick={onDismissForm}
                                text={formatMessage(messages.cancelButtonText)}
                            />
                        </DialogFooter>
                    </>
                );
            }}
        </Form>
    );
};

export default DelayShutdownDialogForm;
