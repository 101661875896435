import { Method } from '../../../constants/http';
import { DataResponse, FailureOperation } from '../../../models/common';
import Settings from '../../../settings/settings';
import { ProjectFromDiscoveryServiceContract } from '../../contracts/project';
import { paginatedResourceRequest } from '../paginated-resource-request';

export type ListProjectsResponse = DataResponse<ProjectFromDiscoveryServiceContract[]>;

// Mocking data layer /GET projects until service is available
export const listProjects = async (
    accessToken: string,
    tenantId: string,
    activityId?: string
): Promise<ListProjectsResponse> => {
    const result = await paginatedResourceRequest<ProjectFromDiscoveryServiceContract>(
        '/projects',
        Method.GET,
        accessToken,
        {
            operation: FailureOperation.ListProjects,
            activityId,
        },
        `https://${tenantId}.${Settings.DiscoveryBaseHostName}`
    );
    return result;
};
