import {
    DefaultButton,
    Dialog,
    DialogFooter,
    DialogType,
    IDialogContentProps,
    IModalProps,
    PrimaryButton,
    makeStyles as oldMakeStyles,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../utilities/styles';
import { EnvironmentDefinitionViewModel } from '../models';
import SelectEnvironmentDefinitionDetailsList from './select-environment-definition-details-list';

interface SelectEnvironmentDefinitionDialogProps {
    onDismiss: () => void;
    onChange: (selectedEnvironmentDefinition: EnvironmentDefinitionViewModel | undefined) => void;
    showDialog: boolean;
    environmentDefinitions: EnvironmentDefinitionViewModel[];
    selectedEnvironmentDefinition: EnvironmentDefinitionViewModel | undefined;
}

const messages = defineMessages({
    selectEnvironmentDefinitionDialogHeaderText: {
        id: 'SelectEnvironmentDefinitionDialog_Header_Text',
        defaultMessage: 'Select an environment definition',
        description: 'Text for the select environment definition dialog title',
    },
    selectEnvironmentDefinitionDialogSelectButtonText: {
        id: 'SelectEnvironmentDefinitionDialog_SelectButton_Text',
        defaultMessage: 'Select',
        description: 'Text for the select environment definition dialog select button',
    },
    selectEnvironmentDefinitionDialogSelectButtonAriaLabel: {
        id: 'SelectEnvironmentDefinitionDialog_SelectButton_AriaLabel',
        defaultMessage: 'Select the environment definition',
        description: 'Aria label for the select environment definition dialog select button',
    },
    selectEnvironmentDefinitionDialogCancelButtonText: {
        id: 'SelectEnvironmentDefinitionDialog_CancelButton_Text',
        defaultMessage: 'Cancel',
        description: 'Text for the select environment definition dialog cancel button',
    },
    selectEnvironmentDefinitionDialogCancelButtonAriaLabel: {
        id: 'SelectEnvironmentDefinitionDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel button for the select environment definition dialog',
        description: 'Aria label for the select environment definition dialog cancel button',
    },
    selectEnvironmentDefinitionDialogCloseButtonAriaLabel: {
        id: 'SelectEnvironmentDefinitionDialog_CloseButton_AriaLabel',
        defaultMessage: 'Close button for the select environment definition dialog',
        description: 'Aria label for the select environment definition dialog close button icon',
    },
});

/**
 * Style Section
 */

const dialogModalMainMaxWidth = 'unset !important';
const useSelectEnvironmentDefinitionDialogModalStyles = oldMakeStyles((theme) => ({
    main: {
        maxWidth: dialogModalMainMaxWidth,
        backgroundColor: getSemanticColor(theme, 'selectEnvironmentDefinitionDialogBackground'),
    },
}));

const useSelectEnvironmentDefinitionDialogFooterStyles = makeStyles({
    actions: {
        lineHeight: 0,
    },
});

const useButtonsContainerStyles = makeStyles({
    root: {
        justifyContent: 'end',
        gap: '8px',
    },
});

/* END */

const SelectEnvironmentDefinitionDialogComponent: React.FC<SelectEnvironmentDefinitionDialogProps> = (
    props: SelectEnvironmentDefinitionDialogProps
) => {
    const {
        onDismiss,
        onChange,
        environmentDefinitions,
        selectedEnvironmentDefinition: initialSelectedEnvironmentDefinition,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const selectEnvironmentDefinitionDialogModalStyles = useSelectEnvironmentDefinitionDialogModalStyles();
    const selectEnvironmentDefinitionDialogFooterStyles = useSelectEnvironmentDefinitionDialogFooterStyles();
    const buttonsContainerStyles = useButtonsContainerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    // State hooks
    const [selectedEnvironmentDefinition, setSelectedEnvironmentDefinition] = React.useState<
        EnvironmentDefinitionViewModel | undefined
    >(initialSelectedEnvironmentDefinition);

    // Callback hooks
    const onEnvironmentDefinitionSelectButtonClick = React.useCallback(() => {
        onChange(selectedEnvironmentDefinition);
        onDismiss();
    }, [selectedEnvironmentDefinition, onChange, onDismiss]);

    // Memo hooks
    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            closeButtonAriaLabel: formatMessage(messages.selectEnvironmentDefinitionDialogCloseButtonAriaLabel),
        }),
        [formatMessage]
    );

    const dialogContentProps: IDialogContentProps = React.useMemo(
        () => ({
            type: DialogType.normal,
            title: formatMessage(messages.selectEnvironmentDefinitionDialogHeaderText),
        }),
        [formatMessage]
    );

    return (
        <Dialog
            hidden={false}
            onDismiss={onDismiss}
            dialogContentProps={dialogContentProps}
            modalProps={modalProps}
            styles={selectEnvironmentDefinitionDialogModalStyles}
        >
            <SelectEnvironmentDefinitionDetailsList
                onEnvironmentDefinitionSelected={setSelectedEnvironmentDefinition}
                environmentDefinitions={environmentDefinitions}
                selectedEnvironmentDefinition={selectedEnvironmentDefinition}
            />
            <DialogFooter styles={selectEnvironmentDefinitionDialogFooterStyles}>
                <div className={mergeClasses(horizontalStackStyles.root, buttonsContainerStyles.root)}>
                    <div className={horizontalStackStyles.item}>
                        <PrimaryButton
                            text={formatMessage(messages.selectEnvironmentDefinitionDialogSelectButtonText)}
                            ariaLabel={formatMessage(messages.selectEnvironmentDefinitionDialogSelectButtonAriaLabel)}
                            onClick={onEnvironmentDefinitionSelectButtonClick}
                            disabled={!selectedEnvironmentDefinition}
                        />
                    </div>
                    <div className={horizontalStackStyles.item}>
                        <DefaultButton
                            text={formatMessage(messages.selectEnvironmentDefinitionDialogCancelButtonText)}
                            ariaLabel={formatMessage(messages.selectEnvironmentDefinitionDialogCancelButtonAriaLabel)}
                            onClick={onDismiss}
                        />
                    </div>
                </div>
            </DialogFooter>
        </Dialog>
    );
};

const SelectEnvironmentDefinitionDialogWrapper: React.FC<SelectEnvironmentDefinitionDialogProps> = (
    props: SelectEnvironmentDefinitionDialogProps
) => {
    const { showDialog } = props;

    if (!showDialog) {
        return <></>;
    }

    return <SelectEnvironmentDefinitionDialogComponent {...props} />;
};

export const SelectEnvironmentDefinitionDialog = SelectEnvironmentDefinitionDialogWrapper;
export default SelectEnvironmentDefinitionDialog;
