import { FontSizes, IIconProps, IconButton, makeStyles as legacyMakeStyles } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { AppSemanticColor } from '../../../../themes/app-semantic-colors';
import { useHorizontalStackStyles } from '../../../../themes/styles/flexbox-styles';
import { getSemanticColor } from '../../../../utilities/styles';
import { FluentIconNames } from '../../../common/fluent-icon-names';

export interface CustomizationFileDisplayProps {
    name: string;
    onRemove: () => void;
    disabled: boolean;
}

const useRemoveStyles = legacyMakeStyles((theme) => ({
    root: {
        fontSize: FontSizes.size14,
        color: getSemanticColor(theme, AppSemanticColor.expandIcon),
    },
}));

const useLegacyFileStyles = legacyMakeStyles((theme) => ({
    root: {
        backgroundColor: getSemanticColor(theme, AppSemanticColor.actionBarBorder),
        display: 'inline-flex',
        padding: '0 2px 0 8px',
        borderRadius: '3px',
        lineHeight: 'initial',
    },
}));

const useFileStyles = makeStyles({
    root: {
        alignItems: 'center',
    },
});

export const CustomizationFileDisplay: React.FC<CustomizationFileDisplayProps> = React.memo(
    (props: CustomizationFileDisplayProps) => {
        const { name, onRemove, disabled } = props;

        // Style hooks
        const fileStyles = useFileStyles(); // combine these once we've refactored the theming and can remove legacyMakeStyles
        const legacyFileStyles = useLegacyFileStyles();
        const removeStyles = useRemoveStyles();
        const horizontalStackStyles = useHorizontalStackStyles();

        const clearIcon: IIconProps = React.useMemo(
            () => ({ iconName: FluentIconNames.Clear, styles: removeStyles }),
            [removeStyles]
        );

        return (
            <div className={mergeClasses(horizontalStackStyles.root, fileStyles.root, legacyFileStyles.root)}>
                <div className={horizontalStackStyles.item}>{name}</div>
                <div className={horizontalStackStyles.item}>
                    <IconButton styles={removeStyles} iconProps={clearIcon} onClick={onRemove} disabled={disabled} />
                </div>
            </div>
        );
    }
);
