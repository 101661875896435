import { SagaIterator } from 'redux-saga';
import { call, put, select } from 'redux-saga/effects';
import {
    listRegionRecommendations,
    ListRegionRecommendationsResponse,
} from '../../../data/services/discovery-service/region-recommendation';
import { ClientError, FailureOperation, isFailureResponse } from '../../../models/common';
import {
    listDevBoxRegionRecommendations,
    listDevBoxRegionRecommendationsError,
    listDevBoxRegionRecommendationsFailed,
    listDevBoxRegionRecommendationsSuccess,
} from '../../actions/dev-box-region-recommendation/dev-box-region-recommendation-action-creators';
import { ListDevBoxRegionRecommendationsAction } from '../../actions/dev-box-region-recommendation/dev-box-region-recommendation-actions';
import { getAccessToken } from '../../actions/identity/identity-action-creators';
import { GetAccessTokenForDevCenterDataPlanePayload } from '../../actions/identity/identity-actions';
import { createSagaError } from '../../effects/create-saga-error';
import { putAndAwait } from '../../effects/put-and-await';
import { rejectAction } from '../../effects/reject-action';
import { resolveAction } from '../../effects/resolve-action';
import { takeLeading } from '../../effects/take';
import { getTenantId } from '../../selector/identity-selectors';

function* listDevBoxRegionRecommendationsSaga(action: ListDevBoxRegionRecommendationsAction) {
    const { meta, payload } = action;
    const { activityId } = meta ?? {};
    const { region } = payload;

    try {
        const accessToken: string = yield putAndAwait(
            getAccessToken(GetAccessTokenForDevCenterDataPlanePayload(), meta)
        );

        const tenantId: string = yield select(getTenantId);

        const response: ListRegionRecommendationsResponse = yield call(
            listRegionRecommendations,
            accessToken,
            tenantId,
            region,
            activityId
        );

        if (isFailureResponse(response)) {
            yield put(listDevBoxRegionRecommendationsFailed({ failure: response }, meta));
            yield resolveAction(action, response);
            return;
        }

        const { data: regionRecommendations } = response;

        yield put(listDevBoxRegionRecommendationsSuccess({ result: regionRecommendations }, meta));
        yield resolveAction(action, { data: regionRecommendations, succeeded: true });
    } catch (err) {
        const error: ClientError = yield createSagaError(err, FailureOperation.ListDevBoxRegionRecommendations);
        yield put(listDevBoxRegionRecommendationsError({ error }));
        yield rejectAction(action, error);
    }
}

export function* listDevBoxRegionRecommendationsListenerSaga(): SagaIterator {
    yield takeLeading(listDevBoxRegionRecommendations, listDevBoxRegionRecommendationsSaga);
}
