import { HighContrastSelector, Link, Shimmer } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { defineMessages, FormattedMessage, useIntl } from 'react-intl';
import { useHorizontalStackStyles } from '../../../themes/styles/flexbox-styles';
import { isUndefinedOrWhiteSpace } from '../../../utilities/string';
import { ShimmeredMetadataItemViewModel, ShimmeredMetadataItemWidth } from '../../common/metadata/models';
import { EnvironmentExpirationDateOffset, EnvironmentExpirationDateOffsetUnit } from '../models';

interface EnvironmentTypeMetadataProps {
    environmentType: string;
}

interface OwnerMetadataProps {
    ownerDisplayName: string | undefined;
    ownerIsReady: boolean;
}

interface ResourceGroupMetadataProps {
    resourceGroupId: string | undefined;
    tenantDomain: string | undefined;
    environmentName: string;
}

interface ExpirationDateOffsetMetadataProps {
    expirationDateOffset: EnvironmentExpirationDateOffset;
}

/**
 * Styles
 */

const useOwnerMetadataShimmerStyles = makeStyles({
    root: {
        gap: '8px',
    },
});

/**
 * END Styles
 */

const messages = defineMessages({
    environmentResourcesLinkAriaLabel: {
        id: 'EnvironmentResources_Link_AriaLabel',
        defaultMessage: '{environmentName} environment resources',
        description: 'Aria label for Environment Resources Link.',
    },
});

export const EnvironmentTypeMetadata: React.FC<EnvironmentTypeMetadataProps> = (
    props: EnvironmentTypeMetadataProps
) => {
    const { environmentType } = props;

    // Memoized data
    const values = React.useMemo(() => ({ environmentType }), [environmentType]);

    return (
        <FormattedMessage
            id="EnvironmentTypeMetadata_Metadata_Text"
            defaultMessage="Type: {environmentType}"
            description="Text displaying environment type. {environmentType} should not be localized."
            values={values}
        />
    );
};

export const OwnerMetadata: React.FC<OwnerMetadataProps> = (props: OwnerMetadataProps) => {
    const { ownerDisplayName, ownerIsReady } = props;

    // Style hooks
    const ownerMetadataShimmerStyles = useOwnerMetadataShimmerStyles();
    const horizontalStackStyles = useHorizontalStackStyles();

    // Memoized data
    const values = React.useMemo(() => ({ owner: ownerDisplayName }), [ownerDisplayName]);

    if (isUndefinedOrWhiteSpace(ownerDisplayName)) {
        return <></>;
    }

    if (!ownerIsReady) {
        return (
            <div className={mergeClasses(horizontalStackStyles.root, ownerMetadataShimmerStyles.root)}>
                <span>
                    <FormattedMessage
                        id="OwnerMetadata_Metadata_NotReadyTitle"
                        defaultMessage="Owner: "
                        description="Loading state display title for environment owner."
                    />
                </span>
                <Shimmer styles={ownerMetadataShimmerStyles} />
            </div>
        );
    }

    return (
        <FormattedMessage
            id="OwnerMetadata_Metadata_Text"
            defaultMessage="Owner: {owner}"
            description="Text displaying environment owner. {owner} should not be localized."
            values={values}
        />
    );
};

export const ExpirationDateOffsetMetadata: React.FC<ExpirationDateOffsetMetadataProps> = (
    props: ExpirationDateOffsetMetadataProps
) => {
    const { expirationDateOffset } = props;

    const { offset, unit } = expirationDateOffset;

    const { formatNumber } = useIntl();

    // Memoized data
    const offsetInteger = React.useMemo(() => Math.floor(offset), [offset]);

    const values = React.useMemo(
        () => ({
            offset: formatNumber(offsetInteger),
        }),
        [offsetInteger, formatNumber]
    );

    const environmentExpirationDisplay = React.useMemo(() => {
        switch (unit) {
            case EnvironmentExpirationDateOffsetUnit.Years:
                if (offsetInteger === 1) {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Year"
                            defaultMessage="Expires in 1 year"
                            description="Text displaying that the environment will expire in 1 year."
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Years"
                            defaultMessage="Expires in {offset} years"
                            description="Text displaying how many years from now the environment will expire. {offset} should not be localized."
                            values={values}
                        />
                    );
                }
            case EnvironmentExpirationDateOffsetUnit.Months:
                if (offsetInteger === 1) {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Month"
                            defaultMessage="Expires in 1 month"
                            description="Text displaying that the environment will expire in 1 month."
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Months"
                            defaultMessage="Expires in {offset} months"
                            description="Text displaying how many months from now the environment will expire. {offset} should not be localized."
                            values={values}
                        />
                    );
                }
            case EnvironmentExpirationDateOffsetUnit.Weeks:
                if (offsetInteger === 1) {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Week"
                            defaultMessage="Expires in 1 week"
                            description="Text displaying that the environment will expire in 1 week."
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Weeks"
                            defaultMessage="Expires in {offset} weeks"
                            description="Text displaying how many weeks from now the environment will expire. {offset} should not be localized."
                            values={values}
                        />
                    );
                }
            case EnvironmentExpirationDateOffsetUnit.Days:
                if (offsetInteger === 1) {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Day"
                            defaultMessage="Expires in 1 day"
                            description="Text displaying that the environment will expire in 1 day."
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Days"
                            defaultMessage="Expires in {offset} days"
                            description="Text displaying how many days from now the environment will expire. {offset} should not be localized."
                            values={values}
                        />
                    );
                }
            case EnvironmentExpirationDateOffsetUnit.Hours:
                if (offsetInteger === 1) {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Hour"
                            defaultMessage="Expires in 1 hour"
                            description="Text displaying the environment will expire in 1 hour."
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Hours"
                            defaultMessage="Expires in {offset} hours"
                            description="Text displaying how many hours from now the environment will expire. {offset} should not be localized."
                            values={values}
                        />
                    );
                }
            case EnvironmentExpirationDateOffsetUnit.Minutes:
                if (offsetInteger === 1) {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Minute"
                            defaultMessage="Expires in 1 minute"
                            description="Text displaying that the environment will expire in 1 minute."
                        />
                    );
                } else {
                    return (
                        <FormattedMessage
                            id="ExpirationDateOffsetMetadata_Metadata_Minutes"
                            defaultMessage="Expires in {offset} minutes"
                            description="Text displaying how many minutes from now the environment will expire. {offset} should not be localized."
                            values={values}
                        />
                    );
                }
            case EnvironmentExpirationDateOffsetUnit.Seconds:
                return (
                    <FormattedMessage
                        id="ExpirationDateOffsetMetadata_Metadata_LessThanAMinute"
                        defaultMessage="Expires in less than a minute"
                        description="Text displaying from current time that the environment expires in less than a minute."
                    />
                );
            default:
                return <></>;
        }
    }, [offsetInteger, unit, values]);

    return environmentExpirationDisplay;
};

const highContrastStyles = {
    root: {
        [HighContrastSelector]: {
            'forced-color-adjust': 'none', // removes the color optimization made by the user agent for the link so that focus indicator is visible in HCM
        },
    },
};

export const ResourceGroupMetadata: React.FC<ResourceGroupMetadataProps> = (props: ResourceGroupMetadataProps) => {
    const { resourceGroupId, tenantDomain, environmentName } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Memoized data
    const ariaLabelValues = React.useMemo(() => ({ environmentName }), [environmentName]);

    const values = React.useMemo(
        () => ({
            Link: (chunks: string) => (
                <Link
                    href={`https://portal.azure.com/#@${tenantDomain}/resource${resourceGroupId}/overview`}
                    target="_blank"
                    aria-label={formatMessage(messages.environmentResourcesLinkAriaLabel, ariaLabelValues)}
                    styles={highContrastStyles}
                >
                    {chunks}
                </Link>
            ),
        }),
        [resourceGroupId, tenantDomain, ariaLabelValues]
    );

    if (isUndefinedOrWhiteSpace(resourceGroupId) || isUndefinedOrWhiteSpace(tenantDomain)) {
        return <></>;
    }

    return (
        <FormattedMessage
            id="ResourceGroupMetadata_Metadata_Text"
            defaultMessage="<Link>Environment resources</Link>"
            description="Link to environment resources. <Link> and </Link> should not be localized, but text between them should."
            values={values}
        />
    );
};

export const getShimmeredEnvironmentMetadata = (hasResourceGroup: boolean): ShimmeredMetadataItemViewModel[] => {
    const metadataItems = [
        { key: 'environment-type-shimmer', width: ShimmeredMetadataItemWidth.Small },
        { key: 'environment-owner-shimmer', width: ShimmeredMetadataItemWidth.Medium },
    ];

    if (hasResourceGroup) {
        metadataItems.push({ key: 'environment-resources-shimmer', width: ShimmeredMetadataItemWidth.Medium });
    }

    return metadataItems;
};
