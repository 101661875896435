import { Label } from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { devboxYamlName, wingetConfigurationExtension, workloadYamlName } from '../../../../constants/customization';
import { useActionCreator } from '../../../../hooks/action-creator';
import { AzureDevOpsBranch, AzureDevOpsRepo, AzureDevOpsTreeEntry } from '../../../../models/azure-dev-ops';
import { Status } from '../../../../models/common';
import {
    getAzureDevOpsRepoContents,
    getAzureDevOpsRepoItem,
    loadAzureDevOpsRepoResources,
} from '../../../../redux/actions/azure-dev-ops/azure-dev-ops-action-creators';
import {
    getAzureDevOpsBranches,
    getAzureDevOpsRepoContents as getAzureDevOpsRepoContentsSelector,
    getAzureDevOpsRepoItems,
    getStatusesForGetAzureDevOpsRepoItem,
    getStatusesForListAzureDevOpsBranches,
    getStatusesForLoadAzureDevOpsRepoContents,
    getStatusesForLoadAzureDevOpsRepoResources,
} from '../../../../redux/selector/azure-dev-ops-selectors';
import { isStatusInProgress, isStatusNotStarted } from '../../../../redux/selector/common';
import { useStackWithFullWidthItemStyles } from '../../../../themes/styles/flexbox-styles';
import { SerializableMap } from '../../../../types/serializable-map';
import { compact } from '../../../../utilities/array';
import { getAzureDevOpsBranchKey, getAzureDevOpsRepoItemId } from '../../../../utilities/azure-dev-ops';
import { getFileNameFromFilePath } from '../../../../utilities/file-path';
import { get } from '../../../../utilities/serializable-map';
import { areStringsEquivalent, doesStringEndWith } from '../../../../utilities/string';
import FailureMessage from '../../../common/failure-message/failure-message';
import { FailureMessageSeverity } from '../../../common/failure-message/models';
import { ValueDropdown } from '../../../common/form/dropdown/value-dropdown';
import { PathDropdown } from '../../../common/form/path-dropdown/path-dropdown';
import { ShimmeredInput } from '../../../common/form/shimmered-field';
import { CustomizationData } from '../../models';
import { getValueForRepoItem } from '../selectors';
import { CustomizationFileDisplay } from './customization-file-display';

interface AddDevBoxFormAzureDevOpsRepoPickerProps {
    repo: AzureDevOpsRepo | undefined;
    value: CustomizationData | undefined;
    onChange: (value: CustomizationData) => void;
    onBlur: () => void;
    disabled: boolean;
}

interface AddDevBoxFormAzureDevOpsRepoPickerComponentProps
    extends Omit<AddDevBoxFormAzureDevOpsRepoPickerProps, 'onChange' | 'value'> {
    branchesForRepo: AzureDevOpsBranch[];
    repoContentsForBranch: AzureDevOpsTreeEntry[] | undefined;
    onBranchChange: (value: AzureDevOpsBranch | undefined) => void;
    selectedBranch: AzureDevOpsBranch | undefined;
    selectedFilePaths: string[];
    onFilePathsChange: (value: string[]) => void;
    loadFilePathData: (filePath: string) => void;
    listBranchesStatus: Status | undefined;
    statusesForLoadAdoRepoContents: SerializableMap<Status>;
    statusesForLoadAdoRepoResources: SerializableMap<Status>;
}

const messages = defineMessages({
    branchFieldLabel: {
        id: 'AddDevBoxFormCustomizationRepoPicker_BranchDropdown_Label',
        defaultMessage: 'Branch',
        description: 'Label for the Azure DevOps branch dropdown on the create dev box form',
    },
    customizationFileFieldPlaceholder: {
        id: 'AddDevBoxFormCustomizationRepoPicker_CustomizationFile_Placeholder',
        defaultMessage: 'Add a customization file from {repoName}',
        description: 'Placeholder text for customization file dropdown',
    },
});

const getAzureDevOpsBranchOptionText = (option: AzureDevOpsBranch): string => option.name;

/**
 * Styles
 */

const useContainerStyles = makeStyles({
    root: {
        gap: '16px',
    },
});

/**
 * End Styles
 */

export const AddDevBoxFormAzureDevOpsFilePickerComponent: React.FC<AddDevBoxFormAzureDevOpsRepoPickerComponentProps> = (
    props: AddDevBoxFormAzureDevOpsRepoPickerComponentProps
) => {
    const {
        repo,
        branchesForRepo,
        onBranchChange,
        selectedBranch,
        repoContentsForBranch,
        onFilePathsChange,
        selectedFilePaths,
        loadFilePathData,
        listBranchesStatus,
        disabled,
        onBlur,
        statusesForLoadAdoRepoContents,
        statusesForLoadAdoRepoResources,
    } = props;

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const containerStyles = useContainerStyles();
    const stackStyles = useStackWithFullWidthItemStyles();

    // Memoized data
    const customizationFilePathOptions = React.useMemo(
        () =>
            repoContentsForBranch
                ?.map((treeEntry) => treeEntry.relativePath)
                .filter((relativePath) => {
                    const fileName = getFileNameFromFilePath(relativePath);
                    return (
                        areStringsEquivalent(fileName, workloadYamlName, true) ||
                        areStringsEquivalent(fileName, devboxYamlName, true) ||
                        doesStringEndWith(fileName, wingetConfigurationExtension, true)
                    );
                }),
        [repoContentsForBranch]
    );

    const hasCustomizationFileOptions = customizationFilePathOptions && customizationFilePathOptions.length > 0;

    const isBranchesLoading = React.useMemo(() => isStatusInProgress(listBranchesStatus), [listBranchesStatus]);

    const isRepoResourcesLoading = React.useMemo(
        () => isStatusInProgress(get(statusesForLoadAdoRepoResources, repo?.url)),
        [statusesForLoadAdoRepoResources, repo]
    );

    const isRepoContentsLoading = React.useMemo(
        () => isStatusInProgress(get(statusesForLoadAdoRepoContents, selectedBranch?.objectId)),
        [statusesForLoadAdoRepoContents, selectedBranch]
    );

    const shouldShowWarning = !isRepoResourcesLoading && !isRepoContentsLoading && !hasCustomizationFileOptions;

    const customizationFilePlaceholder = React.useMemo(
        () => (repo ? formatMessage(messages.customizationFileFieldPlaceholder, { repoName: repo.name }) : undefined),
        [formatMessage, repo]
    );

    // Callback hooks
    const onSelectNewPath = React.useCallback(
        (value: string) => {
            onFilePathsChange([value]);
            loadFilePathData(value);
        },
        [onFilePathsChange, loadFilePathData]
    );

    const onRemovePathForIndex = React.useCallback(
        (index: number) => {
            const onRemoveForIndex = () => {
                const updatedFilePaths = [...selectedFilePaths];
                updatedFilePaths.splice(index);

                onFilePathsChange(updatedFilePaths);
            };

            return onRemoveForIndex;
        },
        [onFilePathsChange, selectedFilePaths]
    );

    if (!repo) {
        return <></>;
    }

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <div className={stackStyles.item}>
                <ValueDropdown<AzureDevOpsBranch>
                    label={formatMessage(messages.branchFieldLabel)}
                    options={branchesForRepo}
                    getOptionKey={getAzureDevOpsBranchKey}
                    getOptionText={getAzureDevOpsBranchOptionText}
                    value={selectedBranch}
                    onChange={onBranchChange}
                    onBlur={onBlur}
                    isLoading={isBranchesLoading}
                    disabled={disabled}
                    isSearchable
                />
            </div>
            {isRepoContentsLoading && (
                <div className={stackStyles.item}>
                    <Label>
                        <FormattedMessage
                            id="AddDevBoxFormCustomizationRepoPicker_CustomizationFile"
                            defaultMessage="Customization file"
                            description="Label for a file picker control"
                        />
                    </Label>
                    <ShimmeredInput />
                </div>
            )}
            {hasCustomizationFileOptions && (
                <div className={stackStyles.item}>
                    <Label>
                        <FormattedMessage
                            id="AddDevBoxFormCustomizationRepoPicker_CustomizationFile"
                            defaultMessage="Customization file"
                            description="Label for a file picker control"
                        />
                    </Label>
                    <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
                        {selectedFilePaths.length > 0 && (
                            <div className={stackStyles.item}>
                                {selectedFilePaths.map((filePath, index) => (
                                    <CustomizationFileDisplay
                                        name={filePath}
                                        onRemove={onRemovePathForIndex(index)}
                                        key={index}
                                        disabled={disabled}
                                    />
                                ))}
                            </div>
                        )}
                        <div className={stackStyles.item}>
                            <PathDropdown
                                paths={customizationFilePathOptions}
                                selectedPath=""
                                onChange={onSelectNewPath}
                                placeholder={customizationFilePlaceholder}
                                disabled={disabled}
                            />
                        </div>
                    </div>
                </div>
            )}
            {shouldShowWarning && (
                <div className={stackStyles.item}>
                    <FailureMessage severity={FailureMessageSeverity.Warning}>
                        <FormattedMessage
                            id="AddDevBoxFormAzureDevOpsFilePicker_CustomizationFileNotFound_Text"
                            defaultMessage="No customization file found."
                            description="Message informing users that there was no customization file found."
                        />
                    </FailureMessage>
                    <FormattedMessage
                        id="AddDevBoxFormAzureDevOpsFilePicker_NeedWorkloadFile_Text"
                        defaultMessage="Please make sure the proper branch is selected and a valid workload.yaml file is in the repository."
                        description="Message informing users that we need a valid workload.yaml in the repository."
                    />
                </div>
            )}
        </div>
    );
};

export const AddDevBoxFormAzureDevOpsFilePickerContainer: React.FC<AddDevBoxFormAzureDevOpsRepoPickerProps> = (
    props: AddDevBoxFormAzureDevOpsRepoPickerProps
) => {
    const { repo, value, onChange } = props;

    const { contents } = value ?? {};

    // Application state hooks
    const statusesForLoadAdoRepoResources = useSelector(getStatusesForLoadAzureDevOpsRepoResources);
    const branches = useSelector(getAzureDevOpsBranches);
    const statusesForListAdoBranches = useSelector(getStatusesForListAzureDevOpsBranches);
    const repoContents = useSelector(getAzureDevOpsRepoContentsSelector);
    const statusesForLoadAdoRepoContents = useSelector(getStatusesForLoadAzureDevOpsRepoContents);
    const repoItems = useSelector(getAzureDevOpsRepoItems);
    const statusesForGetRepoItem = useSelector(getStatusesForGetAzureDevOpsRepoItem);

    // Action creator hooks
    const loadRepoResources = useActionCreator(loadAzureDevOpsRepoResources);
    const getRepoContents = useActionCreator(getAzureDevOpsRepoContents);
    const getRepoItem = useActionCreator(getAzureDevOpsRepoItem);

    // Memoized data
    const branchesForRepo = React.useMemo(() => get(branches, repo?.url) ?? [], [branches, repo?.url]);
    const listBranchesStatus = React.useMemo(
        () => get(statusesForListAdoBranches, repo?.url),
        [statusesForListAdoBranches, repo?.url]
    );

    const selectedBranch = value?.branch;
    const selectedOrDefaultBranch = React.useMemo(() => {
        if (selectedBranch) {
            return selectedBranch;
        }

        if (!repo?.defaultBranch || !branchesForRepo) {
            return undefined;
        }

        // git branch names are case sensitive, so it's OK to check exact string equality
        return branchesForRepo.find((branch) => branch.name === repo.defaultBranch);
    }, [selectedBranch, repo?.defaultBranch, branchesForRepo]);

    const repoContentsForBranch = React.useMemo(
        () => (selectedOrDefaultBranch ? get(repoContents, selectedOrDefaultBranch.objectId) : undefined),
        [selectedOrDefaultBranch, repoContents]
    );

    const customizationFiles = React.useMemo(
        () =>
            contents
                ? compact(
                      contents.map((content) => getValueForRepoItem(repoItems, repo, selectedOrDefaultBranch, content))
                  )
                : undefined,
        [repo, selectedOrDefaultBranch, contents, repoItems]
    );

    // Callback hooks
    const onBranchChange = React.useCallback(
        (branch: AzureDevOpsBranch | undefined) => {
            // Clear contents and files on branch change
            onChange({ ...value, branch, contents: undefined, repoFiles: undefined });

            if (!branch || !repo) {
                return;
            }

            // Only load branch contents if not already loaded
            const statusForBranch = get(statusesForLoadAdoRepoContents, branch.objectId);

            if (isStatusNotStarted(statusForBranch)) {
                getRepoContents({ branchObjectId: branch.objectId, repoUrl: repo.url });
            }
        },
        [onChange, value, repo, statusesForLoadAdoRepoContents, getRepoContents]
    );

    const onFilePathsChange = React.useCallback(
        (filePaths: string[] | undefined) => {
            onChange({ ...value, contents: filePaths });
        },
        [onChange, value]
    );

    const loadFilePathData = React.useCallback(
        (filePath: string) => {
            if (repo && selectedOrDefaultBranch) {
                const repoItemPathIdentifier = getAzureDevOpsRepoItemId(
                    repo.url,
                    selectedOrDefaultBranch.name,
                    filePath
                );

                const statusForRepoItem = get(statusesForGetRepoItem, repoItemPathIdentifier);

                if (isStatusNotStarted(statusForRepoItem)) {
                    getRepoItem({ repoItemPathIdentifier });
                }
            }
        },
        [repo, getRepoItem, selectedOrDefaultBranch, statusesForGetRepoItem]
    );

    // Effect hooks

    // repo is intentionally the only dep - we only want to execute this when it changes
    React.useEffect(() => {
        // Clear selection on repo change
        onChange({ branch: undefined, contents: undefined, repoFiles: undefined });

        if (repo) {
            const statusForUrl = get(statusesForLoadAdoRepoResources, repo.url);

            // Only load repo if not already loaded
            if (isStatusNotStarted(statusForUrl)) {
                loadRepoResources({ repoUrl: repo.url, repo });
            }
        }
    }, [repo]);

    // customizationFile is intentionally the only dep - we only want to execute this when it changes
    React.useEffect(() => {
        // Keep onChange up to date with customization file
        onChange({ ...value, contents, repoFiles: customizationFiles });
    }, [customizationFiles]);

    return (
        <AddDevBoxFormAzureDevOpsFilePickerComponent
            {...props}
            repo={repo}
            branchesForRepo={branchesForRepo}
            repoContentsForBranch={repoContentsForBranch}
            onBranchChange={onBranchChange}
            selectedBranch={selectedBranch}
            onFilePathsChange={onFilePathsChange}
            selectedFilePaths={contents ?? []}
            loadFilePathData={loadFilePathData}
            listBranchesStatus={listBranchesStatus}
            statusesForLoadAdoRepoContents={statusesForLoadAdoRepoContents}
            statusesForLoadAdoRepoResources={statusesForLoadAdoRepoResources}
        />
    );
};

const AddDevBoxFormAzureDevOpsFilePicker = AddDevBoxFormAzureDevOpsFilePickerContainer;
export default AddDevBoxFormAzureDevOpsFilePicker;
