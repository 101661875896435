import {
    FontSizes,
    GroupedList,
    IGroup,
    IGroupHeaderProps,
    IGroupRenderProps,
    SelectionMode,
    makeStyles as legacyMakeStyles,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PutCustomizationTask, RepoSourcedFileContent, UploadedFileContent } from '../../../models/customization';
import { useStackStyles } from '../../../themes/styles/flexbox-styles';
import { entries } from '../../../utilities/serializable-map';
import { getSemanticColor } from '../../../utilities/styles';
import { isWingetConfiguration } from '../../common/winget-configuration/selectors';
import { WingetConfigurationDetails } from '../../common/winget-configuration/winget-configuration-details';
import { getTaskListFromCustomizationFiles } from '../selectors';
import { AddDevBoxSummaryCustomizationSectionDetails } from './add-dev-box-summary-customization-section-details';

export interface AddDevBoxSummaryCustomizationsSectionProps {
    customizationFiles?: UploadedFileContent[];
    repoFiles?: RepoSourcedFileContent[];
}

interface CustomizationTaskDetailsProps {
    task: PutCustomizationTask;
}

interface CustomizationTaskSectionProps {
    onRenderTaskDetails: (task: string) => void;
    task: RepoSourcedFileContent;
}

interface CustomizationTaskInputsProps {
    parameterName: string;
    parameterValue: string;
}

/**
 * Styles
 */

const useGroupListStyles = legacyMakeStyles((theme) => ({
    expandedTaskDetails: {
        backgroundColor: getSemanticColor(theme, 'expandedCustomizationTaskBackground'),
        color: getSemanticColor(theme, 'expandedCustomizationTaskText'),
        padding: '4px 8px 10px 32px',
    },
    winget: {
        paddingBottom: '10px',
    },
    input: {
        paddingLeft: '10px',
    },
    runAs: {
        paddingLeft: '10px',
    },
}));

const useContainerStyles = makeStyles({
    root: {
        gap: '16px',
    },
});

const useTaskDetailsStyles = makeStyles({
    root: {
        gap: '8px',
        fontSize: FontSizes.size14,
        wordBreak: 'break-all',
    },
});

const useInputsStyles = makeStyles({
    root: {
        gap: '4px',
    },
});

/**
 * End Styles
 */

const onRenderHeader = (props?: IGroupHeaderProps): JSX.Element => (
    <AddDevBoxSummaryCustomizationSectionDetails groupHeaderProps={props} />
);

const onRenderTaskDetails = (content: string) => {
    // Style hooks
    const groupListStyles = useGroupListStyles();

    if (isWingetConfiguration(content)) {
        return <WingetConfigurationDetails style={groupListStyles.winget} content={content} />;
    }

    const taskList = getTaskListFromCustomizationFiles([content]);

    return (
        <>
            {taskList?.map((task, index) => (
                <CustomizationTaskDetails key={index} task={task} />
            ))}
        </>
    );
};

const CustomizationTaskInputs: React.FC<CustomizationTaskInputsProps> = (props: CustomizationTaskInputsProps) => {
    const { parameterName, parameterValue } = props;

    const parameterValues = React.useMemo(
        () => ({
            parameterName,
            parameterValue,
        }),
        [parameterName, parameterValue]
    );

    return (
        <div>
            <FormattedMessage
                id="AddDevBoxPanelSummaryCustomizationsSection_InputDetails_Text"
                defaultMessage="{parameterName}: {parameterValue}"
                description="Parameter name and parameter value for a customization task. {parameterName} and {parameterValue} should not be localized."
                values={parameterValues}
            />
        </div>
    );
};

export const CustomizationTaskDetails: React.FC<CustomizationTaskDetailsProps> = (
    props: CustomizationTaskDetailsProps
) => {
    const { task } = props;

    // Style hooks
    const groupListStyles = useGroupListStyles();
    const stackStyles = useStackStyles();
    const taskDetailsStyles = useTaskDetailsStyles();
    const inputsStyles = useInputsStyles();

    const taskNameValues = React.useMemo(
        () => ({
            taskName: task.name,
        }),
        [task]
    );

    const taskRunAsValues = React.useMemo(
        () => ({
            taskRunAs: task.runAs,
        }),
        [task]
    );

    return (
        <div className={mergeClasses(stackStyles.root, taskDetailsStyles.root)}>
            <div className={stackStyles.item}>
                <FormattedMessage
                    id="AddDevBoxPanelSummaryCustomizationsSection_TaskName_Text"
                    defaultMessage="- task: {taskName}"
                    description="Customization task name. {taskName} should not be localized."
                    values={taskNameValues}
                />
                {task.runAs && (
                    <div className={mergeClasses(stackStyles.item, groupListStyles.runAs)}>
                        <FormattedMessage
                            id="AddDevBoxPanelSummaryCustomizationsSection_RunAs_Text"
                            defaultMessage="runAs: {taskRunAs}"
                            description="Customization task execution account. {taskRunAs} should not be localized."
                            values={taskRunAsValues}
                        />
                    </div>
                )}
                {task.parameters && (
                    <>
                        <div className={mergeClasses(stackStyles.item, groupListStyles.input)}>
                            <FormattedMessage
                                id="AddDevBoxPanelSummaryCustomizationsSection_Inputs_Text"
                                defaultMessage="inputs:"
                                description="Text to preface inputs of customization tasks."
                            />
                        </div>
                        <div
                            className={mergeClasses(
                                stackStyles.root,
                                inputsStyles.root,
                                groupListStyles.expandedTaskDetails
                            )}
                        >
                            {entries(task.parameters).map((param, key) => (
                                <CustomizationTaskInputs key={key} parameterName={param[0]} parameterValue={param[1]} />
                            ))}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export const CustomizationTaskSection: React.FC<CustomizationTaskSectionProps> = (
    props: CustomizationTaskSectionProps
) => {
    const { task, onRenderTaskDetails } = props;

    // Style hooks
    const groupListStyles = useGroupListStyles();

    if (task.content) {
        return <div className={groupListStyles.expandedTaskDetails}>{onRenderTaskDetails(task.content)}</div>;
    }

    return <></>;
};

export const AddDevBoxSummaryCustomizationsSection: React.FC<AddDevBoxSummaryCustomizationsSectionProps> = (
    props: AddDevBoxSummaryCustomizationsSectionProps
) => {
    const { customizationFiles, repoFiles } = props;

    // Style hooks
    const containerStyles = useContainerStyles();
    const stackStyles = useStackStyles();

    const customizationFilesContents = React.useMemo(
        () => (customizationFiles ? customizationFiles.map((file) => file.fileContent) : []),
        [customizationFiles]
    );

    const fileContents = React.useMemo(
        () => [...(repoFiles ?? []), ...customizationFilesContents],
        [repoFiles, customizationFilesContents]
    );

    const files: IGroup[] = React.useMemo(() => {
        return fileContents.map((customizationFile, index) => ({
            count: 1,
            key: customizationFile.name,
            name: customizationFile.name,
            startIndex: index,
            isCollapsed: true,
        }));
    }, [fileContents]);

    const onRenderCell = React.useCallback(
        (_nestingDepth?: number, item?: RepoSourcedFileContent) => {
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
            // Justification: item won't be undefined, this is possibly undefined only from Fluent's callback typedefs
            return <CustomizationTaskSection task={item!} onRenderTaskDetails={onRenderTaskDetails} />;
            /* eslint-disable @typescript-eslint/no-non-null-assertion */
        },
        [onRenderTaskDetails]
    );

    const groupProps: IGroupRenderProps = React.useMemo(() => {
        return { onRenderHeader };
    }, [onRenderHeader]);

    return (
        <div className={mergeClasses(stackStyles.root, containerStyles.root)}>
            <GroupedList
                items={fileContents}
                onRenderCell={onRenderCell}
                groups={files}
                compact
                selectionMode={SelectionMode.none}
                groupProps={groupProps}
            />
        </div>
    );
};
