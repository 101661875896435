import {
    Checkbox,
    DefaultButton,
    Dialog,
    DialogFooter,
    FontWeights,
    IDialogContentProps,
    IModalProps,
    Icon,
    Link,
    PrimaryButton,
} from '@fluentui/react';
import { makeStyles, mergeClasses } from '@fluentui/react-components';
import * as React from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { SupportSectionLinks } from '../../../constants/support-section-links';
import { useRepairDialogContext } from '../../../hooks/context/dialogs';
import { useHorizontalStackStyles, useStackStyles } from '../../../themes/styles/flexbox-styles';

export interface RepairDialogProps {
    onDismiss: () => void;
    onRepairSubmitted: () => void;
}

const messages = defineMessages({
    yesCheckboxLabel: {
        id: 'RepairDialog_Yes_Label',
        defaultMessage: 'Yes, I want to troubleshoot this dev box',
        description:
            'Label for a checkbox on the repair dialog - when checked, the user will be able to submit and start the repair',
    },
    troubleshootButtonLabel: {
        id: 'RepairDialog_HibernateButton_Label',
        defaultMessage: 'Troubleshoot',
        description: 'Label for the submit button on the repair dialog.',
    },
    troubleshootButtonAriaLabel: {
        id: 'RepairDialog_TroubleshootButton_AriaLabel',
        defaultMessage: 'Troubleshoot',
        description: 'Aria label for the submit button on the repair dialog',
    },
    cancelButtonLabel: {
        id: 'RepairDialog_CancelButton_Label',
        defaultMessage: 'Cancel',
        description: 'Label for the cancel button on the repair dialog.',
    },
    cancelButtonAriaLabel: {
        id: 'RepairDialog_CancelButton_AriaLabel',
        defaultMessage: 'Cancel',
        description: 'Aria label for the cancel button on the repair dialog.',
    },
});

const RepairDialogTitle: React.FC = () => {
    const horizontalStackStyle = useHorizontalStackStyles();

    return (
        <div className={horizontalStackStyle.root}>
            <FormattedMessage
                id="RepairDialog_Title_Repair"
                defaultMessage="Troubleshoot & repair connectivity"
                description="The title for the dialog describing the troubleshoot & repair process."
            />
        </div>
    );
};

const getFormattedMessageValues = (href: string, target = '_blank') => {
    const values = {
        Link: (chunks: string) => (
            <Link href={href} target={target}>
                {chunks}
            </Link>
        ),
    };
    return values;
};

const troubleshootLinkValues = getFormattedMessageValues(SupportSectionLinks.TroubleshootAndRepair);

/**
 * Styles
 */

const dialogWidth = '490px';
const useModalStyles = makeStyles({
    main: {
        width: dialogWidth,
    },
});

const useDialogStyles = makeStyles({
    inner: {
        padding: '26px 24px 24px 24px',
    },
});

const useTextStyles = makeStyles({
    root: {
        fontWeight: FontWeights.semibold,
    },
});

const useCheckBoxStyles = makeStyles({
    root: {
        padding: '30px 0px 0px 0px',
    },
});

const useDialogStackStyles = makeStyles({
    root: {
        gap: '18px',
        padding: '0px 0px 30px 0px',
    },
});

const useContentStyles = makeStyles({
    root: {
        gap: '10px',
        alignItems: 'start',
    },
});

/**
 * End Styles
 */

const RepairDialogComponent: React.FC<RepairDialogProps> = (props: RepairDialogProps) => {
    const { onDismiss, onRepairSubmitted } = props;

    // State hooks
    const [yesChecked, setYesChecked] = React.useState(false);

    // Intl hooks
    const { formatMessage } = useIntl();

    // Style hooks
    const dialogStyles = useDialogStyles();
    const modalStyles = useModalStyles();
    const textStyles = useTextStyles();
    const checkboxStyles = useCheckBoxStyles();
    const stackStyles = useStackStyles();
    const dialogStackStyles = useDialogStackStyles();
    const horizontalStackStyles = useHorizontalStackStyles();
    const contentStyles = useContentStyles();

    // Callback hooks
    const onCheckboxChange = React.useCallback(() => setYesChecked(!yesChecked), [yesChecked]);

    const onSubmit = React.useCallback(() => {
        onRepairSubmitted();
        onDismiss();
    }, [onRepairSubmitted, onDismiss]);

    // Memoized data
    const dialogContentProps = React.useMemo(
        (): IDialogContentProps => ({
            title: <RepairDialogTitle />,
            styles: dialogStyles,
        }),
        [dialogStyles]
    );

    const modalProps: IModalProps = React.useMemo(
        () => ({
            isBlocking: true,
            styles: modalStyles,
        }),
        [modalStyles]
    );

    return (
        <Dialog
            onDismiss={onDismiss}
            dialogContentProps={dialogContentProps}
            hidden={false}
            modalProps={modalProps}
            maxWidth={dialogWidth}
        >
            <div className={mergeClasses(stackStyles.root, dialogStackStyles.root)}>
                <div className={stackStyles.item}>
                    <FormattedMessage
                        id="RepairDialog_Description_Text"
                        defaultMessage="This process will review your dev box's connectivity and attempt to resolve any issues. <Link>Learn more about troubleshooting</Link>"
                        description="Repair dialog description. <Link> and </Link> should not be localized, but text between them should."
                        values={troubleshootLinkValues}
                    />
                </div>
                <div className={mergeClasses(horizontalStackStyles.root, contentStyles.root)}>
                    <div className={horizontalStackStyles.item}>
                        <Icon iconName="Warning" />
                    </div>
                    <div className={mergeClasses(horizontalStackStyles.item, textStyles.root)}>
                        <FormattedMessage
                            id="RepairDialog_Warning_Text"
                            defaultMessage="Your dev box will be unavailable during this process, and may be automatically restarted."
                            description="The warning for the repair dialog"
                        />
                    </div>
                </div>
                <div className={mergeClasses(stackStyles.item, checkboxStyles.root)}>
                    <Checkbox
                        label={formatMessage(messages.yesCheckboxLabel)}
                        onChange={onCheckboxChange}
                        checked={yesChecked}
                    />
                </div>
            </div>
            <DialogFooter>
                <PrimaryButton
                    text={formatMessage(messages.troubleshootButtonLabel)}
                    ariaLabel={formatMessage(messages.troubleshootButtonAriaLabel)}
                    onClick={onSubmit}
                    disabled={!yesChecked}
                />
                <DefaultButton
                    text={formatMessage(messages.cancelButtonLabel)}
                    ariaLabel={formatMessage(messages.cancelButtonAriaLabel)}
                    onClick={onDismiss}
                />
            </DialogFooter>
        </Dialog>
    );
};

const RepairDialog: React.FC = () => {
    // Context hooks
    const { closeSurface: closeDialog, properties } = useRepairDialogContext();

    return <RepairDialogComponent {...properties} onDismiss={closeDialog} />;
};

export const RepairDialogContextWrapper: React.FC = () => {
    // Context hooks
    const { isOpen } = useRepairDialogContext();

    if (!isOpen) {
        return <></>;
    }

    return <RepairDialog />;
};
